import React, { createContext, useEffect, useMemo, useState } from 'react'
import { apiErrorHandler } from '~/api-error-handler'
import { fetchGetExchangeRate } from '~/apis/admin'
import CookieHelper from '~/lib/cookie-helper'
import EnumConverter from '~/lib/enum-converter'
import { ExchangeType } from '~/types/enums'

export const ExchangeContext = createContext<
  | {
      currency: ExchangeType
      exchangeRate: number
    }
  | undefined
>(undefined)

const ExchangeContextWrapper = (props: {
  children: React.ReactNode
}): JSX.Element => {
  const [context, setContext] = useState<
    | {
        currency: ExchangeType
        exchangeRate: number
      }
    | undefined
  >(undefined)

  useEffect(() => {
    const currencyCookie = CookieHelper.getCookie('currency')
    fetchGetExchangeRate({ currencyCode: currencyCookie || 'USD' })
      .then((x) => {
        if (x.exchangeRate) {
          setContext({
            currency: EnumConverter.toUnionType<ExchangeType>(
              x.currencyCode || '',
              'USD',
            ),
            exchangeRate: x.exchangeRate,
          })
        }
      })
      .catch(apiErrorHandler)
  }, [])

  const children = useMemo(() => props.children, [props.children])

  return (
    <ExchangeContext.Provider value={context}>
      {children}
    </ExchangeContext.Provider>
  )
}

export default ExchangeContextWrapper
