import React from 'react'
import FormatPrice from '~/cart/gmarket/gl/common/components/FormatPrice'
import { SellerGroupView } from '~/cart/modules/cart/types'
import { SellerGroupSummaryType } from '~/cart/modules/types'
import { __ } from '~/lib/i18n'

const getShippingFeeElements = (
  summaryInfo: SellerGroupSummaryType,
  isMobile: boolean,
): JSX.Element => {
  const children = []

  if (summaryInfo.isBundleShipping) {
    if (isMobile) {
      children.push(
        <span className="label">
          {__('ESCROW_BASKET_TEXT_61', '배송비')}
          <span className="text__shipping-group">
            {__('ESCROW_BASKET_TEXT_68', '묶음배송비 적용 중')}
          </span>
        </span>,
      )
    } else {
      children.push(
        <span className="label">
          {__('ESCROW_BASKET_TEXT_68', '묶음배송비 적용 중')}
        </span>,
      )
    }
  } else {
    children.push(
      <span className="label">{__('ESCROW_BASKET_TEXT_61', '배송비')}</span>,
    )
  }

  if (
    summaryInfo.totalShippingFee > 0 ||
    summaryInfo.cartUnitCount === 0 ||
    summaryInfo.isAllSpecialShipping ||
    summaryInfo.totalInternationalShippingFee > 0
  ) {
    children.push(
      <FormatPrice
        krwAmount={
          summaryInfo.totalShippingFee +
          summaryInfo.totalInternationalShippingFee
        }
      />,
    )
  } else {
    children.push(
      <strong className="price free">
        {__('ESCROW_BASKET_TEXT_21', '무료배송')}
      </strong>,
    )
  }

  return React.createElement(
    'div',
    { className: 'sub_sec delivery' },
    children.map((child, key) => {
      return {
        ...child,
        key,
      }
    }),
  )
}

const CartSellerGroupFooter = ({
  sellerGroup,
  sellerGroupSummary,
  isMobile,
}: {
  sellerGroup: SellerGroupView
  sellerGroupSummary: SellerGroupSummaryType
  isMobile: boolean
}): JSX.Element => {
  return (
    <div className="cart--basket_footer">
      <span className="for_a11y">{`${sellerGroup.shopName}에서 담은 장바구니 상품 소계`}</span>
      <div className="cart--basket--total">
        <div className="sub_sec">
          <span className="label">
            {__('ESCROW_BASKET_TEXT_69', '상품금액')}
          </span>
          <FormatPrice krwAmount={sellerGroupSummary.totalItemPrice} />
        </div>
        <div className="math">
          <i className="icon sprite__cart img_minus">
            <span className="for_a11y">
              {__('ESCROW_BASKET_TEXT_97', '빼기')}
            </span>
          </i>
        </div>
        <div className="sub_sec discount">
          <span className="label">
            {__('ESCROW_BASKET_TEXT_70', '할인금액')}
          </span>
          <FormatPrice krwAmount={-1 * sellerGroupSummary.totalNegativePrice} />
        </div>
        <div className="math">
          <i className="icon sprite__cart img_plus">
            <span className="for_a11y">
              {__('ESCROW_BASKET_TEXT_98', '더하기')}
            </span>
          </i>
        </div>
        {getShippingFeeElements(sellerGroupSummary, isMobile)}
        <div className="math">
          <i className="icon sprite__cart img_result">
            <span className="for_a11y">
              {__('ESCROW_BASKET_TEXT_99', '계산값은')}
            </span>
          </i>
        </div>
        <div className="sub_total">
          <span className="label">
            {__('ESCROW_BASKET_TEXT_4', '주문금액')}
          </span>
          <FormatPrice
            krwAmount={
              sellerGroupSummary.totalPrice +
              sellerGroupSummary.totalInternationalShippingFee
            }
          />
        </div>
      </div>
    </div>
  )
}

export default CartSellerGroupFooter
