import React from 'react'
import { ShippingCountry } from '~/cart/modules/shipping/types'
import areaCodes from '~/data/areaCodes'
import { ElementIds } from '~/data/consts'
import { __ } from '~/lib/i18n'

const OrderSummaryTitle = ({
  shippingCountry,
  onClickOpenCountrySelector,
}: {
  shippingCountry: ShippingCountry
  onClickOpenCountrySelector: React.MouseEventHandler<HTMLElement>
}): JSX.Element => {
  return (
    <div className="order_title order_oversea">
      <strong className="text">
        {__('ESCROW_BASKET_TEXT_71', '전체 합계')}
      </strong>
      <button
        id={ElementIds.ChangeCountryWhenOversea}
        className="order_delivery"
        onClick={onClickOpenCountrySelector}
        data-montelena-acode={areaCodes.OVERSEA_SHIPPING}
      >
        <em className="emphasis">{shippingCountry.countryName}</em>
        <i className="icon sprite__cart img_arrow_right">더보기</i>
      </button>
    </div>
  )
}

export default OrderSummaryTitle
