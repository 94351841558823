import { createStandardAction } from 'typesafe-actions'
import { CartTabType } from '~/types/enums'
import {
  BundleKeyMapping,
  CartNudgingType,
  CartState,
  SignalView,
  SmileCardNudgeCoupon,
  UpdateCartUnit,
  UpdateDiscountsPayloadType,
} from './types'

export const INIT_CART = 'cart/INIT_CART'
export const SET_CART_TAB = 'view/SET_CART_TAB'
export const UPDATE_CART_UNITS = 'cart/UPDATE_CART_UNITS'
export const UPDATE_DISCOUNTS = 'cart/UPDATE_DISCOUNTS'
export const UPDATE_SIGNAL = 'cart/UPDATE_SIGNAL'
export const UPDATE_BUNDLE_KEY = 'cart/UPDATE_BUNDLE_KEY'
export const SELECT_OR_DESELECT_CART_UNIT = 'cart/SELECT_OR_DESELECT_CART_UNIT'
export const REMOVE_CART_UNIT_LIST = 'cart/REMOVE_CART_UNIT_LIST'
export const SET_SMILE_CARD_NUDGE_COUPONS = 'cart/SET_SMILE_CARD_NUDGE_COUPONS'
export const UPDATE_ENCRYPTED_SELLER_KEYS = 'cart/UPDATE_ENCRYPTED_SELLER_KEYS'
export const UPDATE_CART_NUDGING_TYPES = 'cart/UPDATE_CART_NUDGING_TYPES'
export const SET_BSD_EVENTS = 'cart/SET_BSD_EVENTS'
export const SET_ITEM_PURCHASE_BENEFITS = 'cart/SET_ITEM_PURCHASE_BENEFITS'
export const SET_IS_APPLIED_COUPON_QUANTITY_CHANGED =
  'cart/SET_IS_APPLIED_COUPON_QUANTITY_CHANGED'

export const initCart = createStandardAction(INIT_CART)<Partial<CartState>>()
export const setCartTab = createStandardAction(SET_CART_TAB)<CartTabType>()
export const updateCartUnits =
  createStandardAction(UPDATE_CART_UNITS)<UpdateCartUnit>()

export const updateDiscounts =
  createStandardAction(UPDATE_DISCOUNTS)<UpdateDiscountsPayloadType[]>()
export const updateSignal = createStandardAction(UPDATE_SIGNAL)<SignalView[]>()
export const updateBundleKey =
  createStandardAction(UPDATE_BUNDLE_KEY)<BundleKeyMapping[]>()

export const selectOrDeselectCartUnit = createStandardAction(
  SELECT_OR_DESELECT_CART_UNIT,
)<{
  cartUnitIdList: number[]
  isSelect: boolean
}>()

export const removeCartUnitList = createStandardAction(REMOVE_CART_UNIT_LIST)<
  number[]
>()

export const setSmileCardNudgeCoupons = createStandardAction(
  SET_SMILE_CARD_NUDGE_COUPONS,
)<SmileCardNudgeCoupon[]>()

export const updateEncodedSellerKeys = createStandardAction(
  UPDATE_ENCRYPTED_SELLER_KEYS,
)<{ [sellerKey: string]: string | undefined }>()

export const updateCartNudgingTypes = createStandardAction(
  UPDATE_CART_NUDGING_TYPES,
)<CartNudgingType>()

export const setItemBsdEvents =
  createStandardAction(SET_BSD_EVENTS)<cartAPI.ItemBsdResponse[]>()

export const setItemPurchaseBenefits = createStandardAction(
  SET_ITEM_PURCHASE_BENEFITS,
)<cartAPI.ItemPurchaseBenefitResponse[]>()

export const setIsAppliedCouponQuantityChanged = createStandardAction(
  SET_IS_APPLIED_COUPON_QUANTITY_CHANGED,
)<boolean>()
