import moment from 'moment'
import React from 'react'
import isEqual from 'react-fast-compare'
import FormatPrice from '~/cart/gmarket/gl/common/components/FormatPrice'
import {
  CouponBoxTableItemViewData,
  CouponBoxTableType,
} from '~/cart/modules/types'
import { __ } from '~/lib/i18n'

type CouponBoxTableItemProps = {
  tableType: CouponBoxTableType
  id: string
  viewData: CouponBoxTableItemViewData
  isSelected: boolean
  onChangeCoupon: (
    key: CouponBoxTableType,
    couponIssueNo: number,
  ) => React.ChangeEventHandler<HTMLInputElement>
  onCancelCoupon: (
    key: CouponBoxTableType,
  ) => React.MouseEventHandler<HTMLButtonElement>
}

const CouponBoxTableItem = ({
  tableType,
  id,
  viewData,
  isSelected,
  onChangeCoupon,
  onCancelCoupon,
}: CouponBoxTableItemProps): JSX.Element => {
  if (viewData.isNotUseCoupon) return <></>

  if (!viewData.isFakeCoupon) {
    return (
      <li className="coupon-unit">
        <span className="input_custom">
          <input
            type="radio"
            id={id}
            checked={isSelected}
            onChange={onChangeCoupon(tableType, viewData.issueNo)}
          />
          <label htmlFor={id}>
            {viewData.isDeliveryCoupon ? (
              <span className="format-price">
                <span className="box__format-amount">
                  <strong className="text__value">
                    {__('ESCROW_BASKET_TEXT_61', '배송비')}
                  </strong>
                </span>
              </span>
            ) : (
              <FormatPrice krwAmount={viewData.price} withoutExchange={true} />
            )}
            {viewData.description ? (
              <span className="label">{viewData.description}</span>
            ) : (
              <span className="label">
                {__('REWARD_COUPON_TEXT_5', 'G마켓 추가 할인')}
              </span>
            )}
          </label>
        </span>
        {!viewData.isDiscount && viewData.expiredDate && (
          <span className="text__expire-day">{`~${moment(
            viewData.expiredDate,
          ).format('MM/DD')}`}</span>
        )}
        {viewData.isAppliedOnAnother && !isSelected && (
          <button className="button-used" disabled={true}>
            <i className="icon icon_used" />
            {__('REWARD_COUPON_TEXT_6', '사용중')}
          </button>
        )}
        {isSelected && viewData.issueNo !== 0 && (
          <button className="button-cancel" onClick={onCancelCoupon(tableType)}>
            <i className="icon icon_cancel" />
            {__('REWARD_COUPON_TEXT_13', '적용취소')}
          </button>
        )}
      </li>
    )
  } else {
    return (
      <li className="coupon-unit coupon-oneclick-smileclub">
        <span className="input_custom">
          <input
            type="radio"
            id={id}
            checked={false}
            onChange={onChangeCoupon(tableType, viewData.issueNo)}
            data-montelena-acode={viewData.areaCode}
          />
          <label htmlFor={id}>
            <span className="format-price">
              <span className="box__format-amount">
                <strong className="text__value">{viewData.text}</strong>
                <span className="text__unit">{viewData.unit}</span>
              </span>
            </span>
            <span className="label">
              {viewData.description}{' '}
              {viewData.subDescription && `(${viewData.subDescription})`}
            </span>
          </label>
        </span>
      </li>
    )
  }
}

export default React.memo(CouponBoxTableItem, isEqual)
