import _, {
  compact,
  flatMap,
  groupBy,
  intersection,
  isEqual,
  map,
  once,
  sortBy,
  uniq,
} from 'lodash'
import moment from 'moment/moment'
import { fetchGetLatestShippingAddress } from '~/apis/address'
import { fetchGetExchangeRate } from '~/apis/admin'
import { fetchGetHashedCguid, fetchGetUnifyMembershipInfo } from '~/apis/buyer'
import { fetchSignalItems } from '~/apis/buying-insight'
import {
  fetchApplyCoupons,
  fetchGetCart,
  fetchUnapplyCoupons,
} from '~/apis/cart'
import {
  fetchGetAppliedCoupons,
  fetchGetCouponBox,
  fetchGetSmileCardNudgeCoupon,
  fetchGetUnusedCoupon,
} from '~/apis/coupon'
import { fetchGetAvailableDiscount } from '~/apis/discount'
import { fetchGetEnv } from '~/apis/env'
import { fetchGetBsdGlobalEventTag } from '~/apis/event'
import { fetchGetBsdEvents, fetchGetPurchaseBenefits } from '~/apis/item'
import {
  fetchGetOverseaShippingCompanies,
  fetchGetOverseaShippingCountries,
  fetchGetOverseaShippingFees,
} from '~/apis/oversea'
import { fetchGetCartBt } from '~/apis/recommend'
import { fetchGetTotalCashback } from '~/apis/reward'
import {
  fetchGetShipping,
  fetchGetShippingUnavailableItems,
  fetchGetSmileDeliveryTransPolicy,
} from '~/apis/shipping'
import {
  initBuyer,
  updateHashedCguid,
  updateSmileClubOneClickTargetInfo,
  updateUnifyMembershipInfo,
} from '~/cart/modules/buyer/actions'
import {
  removeCartUnitList,
  selectOrDeselectCartUnit,
  setItemBsdEvents,
  setItemPurchaseBenefits,
  setSmileCardNudgeCoupons,
  updateCartUnits,
  updateDiscounts,
  updateEncodedSellerKeys,
  updateSignal,
} from '~/cart/modules/cart/actions'
import {
  getCartUnitById,
  getCartUnitListByIdList,
  getSameItemGroupCartUnitIds,
  hasPartnershipDiscount,
} from '~/cart/modules/cart/reducer'
import {
  CartUnit,
  DiscountPrice,
  SmileCardNudgeCoupon,
  UpdateDiscountsPayloadType,
} from '~/cart/modules/cart/types'
import { updateUnitCashbackList } from '~/cart/modules/cashback/actions'
import {
  UnitCashback,
  UnitCashbackApplication,
} from '~/cart/modules/cashback/types'
import {
  callBackCartBtImp,
  confirmAuthentication,
  openSimpleLayer,
  switchToNextBtItem,
} from '~/cart/modules/complex-actions/index'
import { updateExchangeInfoList } from '~/cart/modules/exchange/actions'
import { updateAvailableFundingDiscount } from '~/cart/modules/funding-discount/actions'
import { FundingDiscount } from '~/cart/modules/funding-discount/types'
import {
  applyGroupCoupons,
  clearAllGroupCoupons,
  setDownloadedGroupCouponIssueNo,
  setTotalGroupCouponCount,
  updateAvailableGroupCoupon,
} from '~/cart/modules/group-coupon/actions'
import {
  getAllAppliedGroupCouponNoList,
  getAppliedGroupCouponList,
  getAvailableGroupCouponList,
  getGroupCouponAppliedCartUnitIdList,
} from '~/cart/modules/group-coupon/reducer'
import { GroupCoupon } from '~/cart/modules/group-coupon/types'
import {
  setBtRecommendItemMap,
  setBtViewType,
} from '~/cart/modules/recommend/actions'
import { BtRecommendItem } from '~/cart/modules/recommend/types'
import {
  getBuyUnavailableInfo,
  getCartUnitPriceInfo,
  getCurrentCartUnitShippingWeightList,
  getIsOneClickTarget,
  getSelectedCartUnitList,
  RootState,
} from '~/cart/modules/reducers'
import {
  selectOverseaShippingCompany,
  setCurrentOverseaShippingCosts,
  setLatestShippingAddress,
  setOverseaShippingCosts,
  setOverseaShippingCountries,
  setSmileDeliveryTransPolicy,
  setTransPolicyMap,
  updateAvailableOverseaShippingCompanies,
  updateBundleShippingFeeList,
  updateDefaultItemShippingFeeIsFreeList,
  updateShippingPolicyList,
  updateShippingUnavailableCartUnits,
} from '~/cart/modules/shipping/actions'
import {
  getIsOverseaShipping,
  getLatestShippingAddress,
  getShippingCountry,
  getShippingPolicyBasicInfo,
} from '~/cart/modules/shipping/reducer'
import {
  BundleShippingFee,
  OverseaShippingCompany,
  ShippingPolicy,
  SmileDeliveryTransPolicy,
} from '~/cart/modules/shipping/types'
import { initSmileFresh } from '~/cart/modules/smile-fresh/actions'
import { CouponDownloadMode, CouponDownloadState } from '~/cart/modules/types'
import {
  applyUnitCoupons,
  clearAllUnitCoupons,
  setDownloadedUnitCouponIssueNo,
  setExtraDiscountCandidates,
  setTotalUnitCouponCount,
  updateAvailableUnitCoupon,
  updateAvailableUnitCouponMulti,
  updateDownloadableUnitCoupons,
} from '~/cart/modules/unit-coupon/actions'
import {
  getAllAppliedUnitCouponList,
  getAppliedUnitCouponList,
  getUnitCoupon,
  getUnitCouponAppliedCartUnitId,
} from '~/cart/modules/unit-coupon/reducer'
import {
  AvailableUnitCoupon,
  DownloadableUnitCoupon,
  ExtraDiscountCandidate,
  UnitCoupon,
} from '~/cart/modules/unit-coupon/types'
import {
  initView,
  setIsShippingFeeLoadedAfterChanged,
} from '~/cart/modules/view/actions'
import { EnumLayerType } from '~/cart/modules/view/types'
import areaCodes from '~/data/areaCodes'
import tenantConstants from '~/data/checkout-constants'
import {
  NotDownloadedCouponIssueNo,
  NotUseCouponDummyIssueNo,
} from '~/data/consts'
import domains from '~/data/domains'
import A11yHelper from '~/lib/a11y-helper'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import CookieHelper, { CookieKeys } from '~/lib/cookie-helper'
import { formatString } from '~/lib/formatter'
import { __ } from '~/lib/i18n'
import Montelena from '~/lib/montelena'
import simpleSwitch from '~/lib/simple-switch'
import siteEnv from '~/lib/site-env'
import { getCurrentUrl, sendGoogleScriptByTagManager } from '~/lib/utils'
import TypeMapper from '~/type-mapper'
import {
  BranchServiceType,
  NudgingType,
  OverseaShippingCompanyType,
  SmileClubMemberType,
} from '~/types/enums'

type GetState = () => RootState
type ComplexDispatch = ComplexThunkDispatch<RootState>

// region private methods

export default class ActionHelper {
  /**
   * 배송비 정보 Redux 세팅(최초 getCart API 및 이후 API에서 공통으로 사용위함)
   * @param dispatch
   * @param getState
   * @param shippingBundles
   * @param shippingPolicies
   */
  static setShippingFeeReduxInfo = (
    dispatch: ComplexDispatch,
    getState: GetState,
    shippingBundles?: cartAPI.ShippingBundleResponse[],
    shippingPolicies?: cartAPI.ShippingPolicyResponse[],
  ): void => {
    dispatch(
      updateBundleShippingFeeList(
        (shippingBundles || []).map<BundleShippingFee>((shippingFee) => ({
          bundleKey: shippingFee.bundleKey || '',
          shippingFee: shippingFee.shippingFee || 0,
          isMaxCharge: shippingFee.isMaxCharge || false,
        })),
      ),
    )

    dispatch(
      updateShippingPolicyList(
        (shippingPolicies || []).map<ShippingPolicy>((shippingPolicy) => ({
          shippingPolicyKey: shippingPolicy.shippingPolicyKey || '',
          shippingMethodType: shippingPolicy.shippingMethodType || 'General',
          shippingChargePayType:
            shippingPolicy.shippingChargePayType || 'PaymentInAdvance',
          shippingFee: shippingPolicy.shippingFee || 0,
          conditionalFreeBasisCost:
            shippingPolicy.conditionalFreeBasisCost || 0,
          isQuantityShippingFee: shippingPolicy.isQuantityShippingFee || false,
          isConditionalFree: shippingPolicy.isConditionalFree || false,
        })),
      ),
    )

    const state = getState()

    const defaultItemShippingFeeIsFreeList =
      shippingPolicies?.reduce<{ itemNo: string; isFreeShipping: boolean }[]>(
        (list, policy) => {
          const cartUnitList = state.cart.cartUnitList.filter(
            (x) => x.shippingPolicyKey === policy.shippingPolicyKey,
          )
          if (cartUnitList.length > 0) {
            const isFreeShipping =
              !policy.isConditionalFree && policy.shippingFee === 0
            const itemNoList = uniq(cartUnitList.map((x) => x.item.itemNo))

            return list.concat(
              itemNoList
                .filter((x) => !list.some((y) => y.itemNo === x))
                .map((itemNo) => ({
                  itemNo,
                  isFreeShipping,
                })),
            )
          }
          return list
        },
        [],
      ) || []

    dispatch(
      updateDefaultItemShippingFeeIsFreeList(defaultItemShippingFeeIsFreeList),
    )
  }

  /**
   * 배송비 조회
   * 변동사항이 있는 장바구니단위와 같은 묶음(bundleKey)에 있는 장바구니단위는 모두 재조회한다
   * @param dispatch
   * @param getState
   * @param cartUnitList
   */
  static updateShippingFee = async (
    dispatch: ComplexDispatch,
    getState: GetState,
    cartUnitList?: CartUnit[],
  ): Promise<void> => {
    const state = getState()

    const shippingCountry = getShippingCountry(state.shipping)

    const targetCartUnitList = cartUnitList || state.cart.cartUnitList

    if (targetCartUnitList.length === 0) {
      return
    }

    const bundleKeys = uniq(
      targetCartUnitList.flatMap((x) => (x.bundleKey ? [x.bundleKey] : [])),
    )

    const cartUnitListToUpdate = getSelectedCartUnitList(state, true).filter(
      (cartUnit) =>
        cartUnit.bundleKey && bundleKeys.includes(cartUnit.bundleKey),
    )

    const shippingFeeRequest: cartAPI.GetShippingFeeRequest = {
      countryCode: shippingCountry.countryCode,
      requests: cartUnitListToUpdate.map<cartAPI.ShippingFeeRequest>(
        (cartUnit) => ({
          cartUnitId: cartUnit.cartUnitId,
          shippingPolicyId: cartUnit.shippingPolicyId,
          sellerKey: cartUnit.seller.sellerKey,
          cartUnitTotalAmount: getCartUnitPriceInfo(state, cartUnit.cartUnitId)
            .cartUnitItemPriceByShipping,
        }),
      ),
      isSmileClub: state.buyer.isSmileClub,
    }

    if (state.view.isShippingFeeLoadedAfterChanged) {
      dispatch(setIsShippingFeeLoadedAfterChanged(false))
    }
    const { shippingBundles, shippingPolicies } = await fetchGetShipping(
      shippingFeeRequest,
    ).catch(
      (): cartAPI.GetShippingResponse => ({
        shippingBundles: [],
        shippingPolicies: [],
      }),
    )

    dispatch(setIsShippingFeeLoadedAfterChanged(true))
    ActionHelper.setShippingFeeReduxInfo(
      dispatch,
      getState,
      shippingBundles,
      shippingPolicies,
    )
  }

  /**
   * 국가별 배송불가 상품 조회
   * - 배송지 국가 변경시
   * @param dispatch
   * @param getState
   * @param cartUnitList
   */
  static updateShippingUnavailableItems = async (
    dispatch: ComplexDispatch,
    getState: GetState,
    cartUnitList?: CartUnit[],
  ): Promise<void> => {
    const state = getState()
    if (!tenantConstants.IsOverseaShippingAvailable) {
      return
    }

    const shippingCountry = getShippingCountry(state.shipping)

    const targetCartUnitList = cartUnitList || state.cart.cartUnitList

    if (targetCartUnitList.length === 0) {
      return
    }

    const request: cartAPI.GetShippingUnavailableItemsRequest = {
      requests: targetCartUnitList.map((cartUnit) => ({
        cartUnitId: cartUnit.cartUnitId,
        sellerKey: cartUnit.seller.sellerKey,
        itemNo: cartUnit.item.itemNo,
        largeCategoryCode: cartUnit.item.largeCategoryCode,
        mediumCategoryCode: cartUnit.item.mediumCategoryCode,
        smallCategoryCode: cartUnit.item.smallCategoryCode,
        isAdult: cartUnit.item.isAdult,
        shippingWeight: cartUnit.item.shippingWeight,
        shippingMethodType:
          getShippingPolicyBasicInfo(state.shipping, cartUnit.shippingPolicyKey)
            ?.shippingMethodType || 'General',
      })),
      countryType: shippingCountry.countryType,
      shippingCompanies: state.shipping.availableShippingCompanies.map(
        (x) => x.shippingCompanyType,
      ),
    }

    const shippingUnavailableItems = await fetchGetShippingUnavailableItems(
      request,
    ).catch(() => null)

    dispatch(
      updateShippingUnavailableCartUnits(
        shippingUnavailableItems?.map((x) => ({
          shippingCompany: x.shippingCompany,
          unavailableCartUnitIds: x.unavailableCartUnitIds || [],
        })) || [],
      ),
    )
  }

  /**
   * 해외배송비 조회
   * - 배송지 국가 변경시
   * @param dispatch
   * @param getState
   */
  static updateOverseaShippingCost = async (
    dispatch: ComplexDispatch,
    getState: GetState,
  ): Promise<void> => {
    const state = getState()

    const shippingCountry = getShippingCountry(state.shipping)
    if (shippingCountry.countryType === 'SouthKorea') {
      dispatch(setOverseaShippingCosts([]))
      return
    }

    const shippingFeesRequest: cartAPI.GetOverseaShippingFeesRequest = {
      countryType: shippingCountry.countryType,
      shippingCompanies: state.shipping.availableShippingCompanies.map(
        (x) => x.shippingCompanyType,
      ),
      requests: getCurrentCartUnitShippingWeightList(state),
    }

    // 현재 카트에 담긴 전체 상품의 oversea shipping cost
    const currentOverseaShippingCosts = await fetchGetOverseaShippingFees(
      shippingFeesRequest,
    ).catch(() => null)

    // 선택된 것들에대한 oversea shipping cost
    const overseaShippingCosts = currentOverseaShippingCosts?.map(
      (overseaShippingCost) => {
        const selectedOverseaShippingFees =
          overseaShippingCost.shippingFees.filter((shippingFee) =>
            state.cart.selectedCartUnitIdList.includes(shippingFee.cartUnitId),
          )

        return {
          shippingCompany: overseaShippingCost.shippingCompany,
          shippingFees: selectedOverseaShippingFees,
        }
      },
    )

    dispatch(setOverseaShippingCosts(overseaShippingCosts || []))
    dispatch(setCurrentOverseaShippingCosts(currentOverseaShippingCosts || []))
  }

  static selectOrDeselectCartUnitsComplex = async (
    dispatch: ComplexDispatch,
    getState: GetState,
    cartUnitIdList: number[],
    isSelect: boolean,
    withBuyUnavailable?: boolean,
    isReselect?: boolean,
  ): Promise<void> => {
    const state = getState()
    const cartUnitList = getCartUnitListByIdList(state.cart, cartUnitIdList)

    dispatch(setIsShippingFeeLoadedAfterChanged(false))
    if (isSelect) {
      if (isReselect) {
        dispatch(
          selectOrDeselectCartUnit({
            cartUnitIdList,
            isSelect: false,
          }),
        )
      }
      dispatch(
        selectOrDeselectCartUnit({
          cartUnitIdList: cartUnitList
            .filter(
              (x) =>
                withBuyUnavailable ||
                !getBuyUnavailableInfo(state, x.cartUnitId),
            )
            .map((x) => x.cartUnitId),
          isSelect,
        }),
      )
    } else {
      dispatch(
        selectOrDeselectCartUnit({
          cartUnitIdList,
          isSelect,
        }),
      )
    }

    const isAllBuyUnavailable = cartUnitList.every((_) =>
      getBuyUnavailableInfo(state, _.cartUnitId),
    )

    if (!isAllBuyUnavailable) {
      await Promise.all([
        ActionHelper.updateDiscountsComplex(dispatch, getState, cartUnitIdList),
        ActionHelper.updateShippingFee(dispatch, getState, cartUnitList),
        ActionHelper.updateOverseaShippingCost(dispatch, getState),
      ])
    }
  }

  static selectOverseaShippingCompanyComplex = async (
    dispatch: ComplexDispatch,
    getState: GetState,
    shippingCompanyType: OverseaShippingCompanyType,
  ): Promise<void> => {
    const state = getState()
    if (state.shipping.selectedOverseaShippingCompany === shippingCompanyType) {
      return
    }

    if (shippingCompanyType !== 'Unknown') {
      if (
        !state.shipping.availableShippingCompanies.some(
          (x) => x.shippingCompanyType === shippingCompanyType,
        )
      ) {
        return
      }

      CookieHelper.setCookie(
        CookieKeys.ShippingMethodType,
        shippingCompanyType,
        undefined,
        domains.DOMAIN_FOR_COOKIE,
      )
    }
    dispatch(selectOverseaShippingCompany(shippingCompanyType))
    await ActionHelper.updateOverseaShippingCost(dispatch, getState)
  }

  /**
   * 배송국가 변경시
   * - 배송가능 배송사 조회
   * - 대만 레이어 띄우기
   * @param dispatch
   * @param getState
   */
  static onChangeShippingCountry = async (
    dispatch: ComplexDispatch,
    getState: GetState,
  ): Promise<void> => {
    if (!tenantConstants.IsOverseaShippingAvailable) {
      return
    }
    const state = getState()
    const shippingCountry = getShippingCountry(state.shipping)

    // 국내배송인 경우는 배송사 조회 필요없다.
    if (shippingCountry.countryType === 'SouthKorea') {
      dispatch(updateAvailableOverseaShippingCompanies([]))
      ActionHelper.selectOverseaShippingCompanyComplex(
        dispatch,
        getState,
        'Unknown', // 한국은 배송사를 'Unknown' 으로 처리한다
      )
      return
    }

    const shippingCompanies = await fetchGetOverseaShippingCompanies({
      countryType: shippingCountry.countryType,
    }).catch(() => null)

    if (shippingCompanies && shippingCompanies.length > 0) {
      const typed: OverseaShippingCompany[] = shippingCompanies.map(
        TypeMapper.asOverseaShippingCompany,
      )

      const shippingCompanyCookie = CookieHelper.getCookie(
        CookieKeys.ShippingMethodType,
      )

      const selected =
        typed.find((x) => x.shippingCompanyType === shippingCompanyCookie) ||
        typed[0]

      dispatch(updateAvailableOverseaShippingCompanies(typed))
      ActionHelper.selectOverseaShippingCompanyComplex(
        dispatch,
        getState,
        selected.shippingCompanyType,
      )

      ActionHelper.updateShippingUnavailableItems(dispatch, getState).then(() =>
        ActionHelper.updateOverseaShippingCost(dispatch, getState),
      )
    } else {
      dispatch(updateAvailableOverseaShippingCompanies([]))
      ActionHelper.selectOverseaShippingCompanyComplex(
        dispatch,
        getState,
        'Unknown',
      )
    }
  }

  /**
   * 옥션용 쿠폰 조회
   * - 쿠폰함 열때
   * @param dispatch
   * @param getState
   * @param cartUnitIdList
   * @param recommendedGroupCouponKey
   */
  static loadGroupCoupon = async (
    dispatch: ComplexDispatch,
    getState: GetState,
    cartUnitIdList: number[],
    recommendedGroupCouponKey: string,
  ): Promise<void> => {
    const state = getState()
    // const isOverseaShipping = false

    const cartUnitList = getCartUnitListByIdList(state.cart, cartUnitIdList)

    if (cartUnitList.length === 0) {
      return
    }

    const details: cartAPI.AvailableCouponRequest[] =
      cartUnitList.map<cartAPI.AvailableCouponRequest>((cartUnit) => {
        const summary = getCartUnitPriceInfo(state, cartUnit.cartUnitId)
        return {
          additionTotalAmount: summary.cartUnitAdditionsTotalPrice,
          branchAdditionalPrice: summary.cartUnitBranchAdditionalPrice,
          itemDiscountPrice: summary.cartUnitEbayDiscountPrice,
          sellerDiscountPrice: summary.cartUnitSellerDiscountPrice,
          optionsPrice: summary.cartUnitOptionsAdditionalPrice,
          quantity: cartUnit.quantity || 0,
        }
      })

    const shippingCountry = getShippingCountry(state.shipping)

    /**
     * 대상 cartUnits 를 제외하고, 다른 cartUnits 에 적용한 쿠폰만 전달
     * + 대상 추천 쿠폰을 제외하고, 다른 추천 쿠폰 목록중 발급번호가 있는 쿠폰도 포함
     */
    const usedCoupons = uniq([
      ...getAllAppliedGroupCouponNoList(
        state.groupCoupon,
        (idList) => !isEqual(idList, cartUnitIdList),
      ),
      ...compact(
        flatMap(
          state.groupCoupon.recommendedCoupons,
          (coupon = [], groupKey) => {
            if (groupKey !== recommendedGroupCouponKey) {
              return coupon.map(({ couponIssueNo }) => couponIssueNo)
            }
          },
        ),
      ),
    ]).map((usedCoupon) => ({
      issueNo: usedCoupon,
    }))

    const recommendedCouponPolicyNos = compact(
      map(
        state.groupCoupon.recommendedCoupons[recommendedGroupCouponKey],
        'couponPolicyNo',
      ),
    )

    const { coupons: groupCouponList, totalCount } = await fetchGetCouponBox({
      cartUnitIds: cartUnitIdList,
      // partnershipCode: server에서 채워줄것
      itemNo: cartUnitList[0].item.itemNo,
      sellerKey: cartUnitList[0].seller.sellerKey,
      detailCategoryCode: cartUnitList[0].item.detailCategoryCode,
      isItemDiscountAgreement: cartUnitList[0].isItemDiscountAgreement,
      itemPrice: cartUnitList[0].item.itemSellPrice,
      details: details,
      usedCoupons: usedCoupons,
      appliedVipCouponBcGroupNos: recommendedCouponPolicyNos,
      isMobile: state.view.isMobile,
      isOversea:
        shippingCountry.countryType !== 'Unknown' &&
        shippingCountry.countryType !== 'SouthKorea',
      isUsed: cartUnitList[0].item.isC2C,
      isSmileDelivery: cartUnitList[0].cartUnitType === 'SmileDelivery',
      isBigSmile:
        (cartUnitList[0].item.bsd && cartUnitList[0].item.bsd.bigSmile) ||
        false,
    })

    dispatch(
      updateAvailableGroupCoupon({
        cartUnitIdList,
        groupCouponList: (groupCouponList || []).map<GroupCoupon>(
          (groupCoupon) => ({
            couponIssueNo:
              groupCoupon.couponIssueNo || NotDownloadedCouponIssueNo,
            couponPolicyNo: groupCoupon.bcGroupNo || 0,
            couponType: groupCoupon.couponType || 'Unknown',
            discountUnitType: groupCoupon.discountUnitType || 'Unknown',
            couponName: groupCoupon.couponName || '',
            couponPrice: groupCoupon.couponPrice || 0,
            expiredDate: groupCoupon.expiredDate,
            possiblePrice: groupCoupon.possiblePrice,
            limitPrice: groupCoupon.limitPrice,
            isSmileclubCoupon: groupCoupon.isSmileclubCoupon,
            isBizCoupon: groupCoupon.isBizCoupon,
            isMobileCoupon: groupCoupon.isMobileCoupon,
            isAppCoupon: groupCoupon.isAppCoupon,
            isDeliveryCoupon: groupCoupon.isDeliveryCoupon,
            paymentCouponName: groupCoupon.paymentCouponName,
            eventNo: groupCoupon.eventNo || 0,
            encStr: groupCoupon.encStr || '',
            isAutoDownloadedCoupon: false,
            issuable: groupCoupon.issuable || false,
          }),
        ),
      }),
    )
    dispatch(setTotalGroupCouponCount(totalCount || 0))
  }

  /**
   * G 시그널 조회
   * @param dispatch
   * @param getState
   * @param cartUnit
   */
  static updateSignalCartUnit = async (
    dispatch: ComplexDispatch,
    getState: GetState,
    cartUnit: CartUnit[],
  ): Promise<void> => {
    const { view } = getState()
    if (view.tenantType === 'Gmarket' && view.languageType === 'Korean') {
      const items: buyingAPI.ItemsRequest[] = []

      _.forEach(cartUnit, (cartUnit) => {
        if (items.length >= 10) {
          return false
        }
        const existingItem = _.find(items, { cartUnitId: cartUnit.cartUnitId })
        if (!existingItem && cartUnit.priceChangedSignalAvailable) {
          items.push({
            itemNo: cartUnit.item.itemNo,
            cartUnitId: cartUnit.cartUnitId,
            jaehuId: cartUnit.partnershipCode,
          })
        }
      })

      if (items && items.length > 0) {
        const signal = await fetchSignalItems({
          items,
        }).catch(() => null)
        if (signal && signal.items) {
          dispatch(updateSignal(signal.items))
        }
      }
    }
  }

  /**
   * G/G9용 쿠폰 조회
   * - 쿠폰함 열때
   * @param dispatch
   * @param getState
   * @param cartUnitId
   */
  static loadUnitCoupon = async (
    dispatch: ComplexDispatch,
    getState: GetState,
    cartUnitId: number,
  ): Promise<void> => {
    const state = getState()
    // const isOverseaShipping = false

    const cartUnit = getCartUnitById(state.cart, cartUnitId)

    if (!cartUnit) {
      return
    }

    const summary = getCartUnitPriceInfo(state, cartUnit.cartUnitId)

    const details: cartAPI.AvailableCouponRequest[] = [
      {
        additionTotalAmount: summary.cartUnitAdditionsTotalPrice,
        branchAdditionalPrice: summary.cartUnitBranchAdditionalPrice,
        itemDiscountPrice: summary.cartUnitEbayDiscountPrice,
        sellerDiscountPrice: summary.cartUnitSellerDiscountPrice,
        optionsPrice: summary.cartUnitOptionsAdditionalPrice,
        quantity: cartUnit.quantity || 0,
        cartPartnershipCode: cartUnit.partnershipCode,
      },
    ]

    const shippingCountry = getShippingCountry(state.shipping)

    const selectedCartUnitIds = getSelectedCartUnitList(state).map(
      (cartUnit) => cartUnit.cartUnitId,
    )

    /**
     * 선택된 장바구니 항목에 적용된 쿠폰 목록
     * - 자기 자신 장바구니 항목에 적용된 쿠폰은 제외
     */
    const usedCoupons: cartAPI.GetCouponBoxUsedCouponRequest[] =
      getAllAppliedUnitCouponList(
        state.unitCoupon,
        (id) => id !== cartUnitId && selectedCartUnitIds.includes(id),
      ).map(({ couponPolicyNo, couponIssueNo, couponPrice }) => ({
        policyNo: couponPolicyNo,
        issueNo: couponIssueNo,
        appliedPrice: couponPrice,
      }))

    const {
      coupons: unitCouponList,
      totalCount,
      extraDiscountCandidates,
    } = await fetchGetCouponBox({
      cartUnitIds: [cartUnit.cartUnitId],
      // partnershipCode: cartUnit.partnershipCode, backend에서 헤더의 partnershipCode로 채움
      itemNo: cartUnit.item.itemNo,
      sellerKey: cartUnit.seller.sellerKey,
      largeCategoryCode: cartUnit.item.largeCategoryCode,
      mediumCategoryCode: cartUnit.item.mediumCategoryCode,
      smallCategoryCode: cartUnit.item.smallCategoryCode,
      isItemDiscountAgreement: cartUnit.isItemDiscountAgreement,
      itemPrice: cartUnit.item.itemSellPrice,
      details: details,
      isMobile: state.view.isMobile,
      isOversea:
        shippingCountry.countryType !== 'Unknown' &&
        shippingCountry.countryType !== 'SouthKorea',
      isUsed: cartUnit.item.isC2C,
      isSmileDelivery: cartUnit.cartUnitType === 'SmileDelivery',
      usedCoupons: usedCoupons,
    })

    dispatch(
      updateAvailableUnitCoupon({
        cartUnitId: cartUnitId,
        unitCouponList: (unitCouponList || []).map<UnitCoupon>(
          (unitCoupon) => ({
            couponPolicyNo: unitCoupon.couponPolicyNo || 0,
            couponIssueNo:
              unitCoupon.couponIssueNo || NotDownloadedCouponIssueNo,
            couponType: unitCoupon.couponType || 'Unknown',
            discountUnitType: unitCoupon.discountUnitType || 'Unknown',
            couponName: unitCoupon.couponName || '',
            couponPrice: unitCoupon.couponPrice || 0,
            expiredDate: unitCoupon.expiredDate,
            possiblePrice: unitCoupon.possiblePrice,
            limitPrice: unitCoupon.limitPrice,
            isSmileclubCoupon: unitCoupon.isSmileclubCoupon,
            isBizCoupon: unitCoupon.isBizCoupon,
            isMobileCoupon: unitCoupon.isMobileCoupon,
            isAppCoupon: unitCoupon.isAppCoupon,
            isDeliveryCoupon: unitCoupon.isDeliveryCoupon,
            isOverseaCoupon: unitCoupon.isOverseaCoupon,
            paymentCouponName: unitCoupon.paymentCouponName,
            issuable: unitCoupon.issuable || false,
            selectedByRecommendation: unitCoupon.selected || false,
            selectable: unitCoupon.selectable || false,
            appliedOnAnother: unitCoupon.appliedOnAnother || false,
            appliedPriceOnAnother: unitCoupon.appliedPriceOnAnother || 0,
            eventNo: unitCoupon.eventNo || 0,
            encStr: unitCoupon.encStr || '',
            isAutoDownloadedCoupon: false,
            isDownloading: false,
            applied: unitCoupon.applied || false,
            isNotifiedPaymentCoupon: false,
          }),
        ),
      }),
    )
    dispatch(setTotalUnitCouponCount(totalCount || 0))

    if (unitCouponList) {
      dispatch(
        updateDownloadableUnitCoupons(
          unitCouponList
            .filter((unitCoupon) => unitCoupon.issuable)
            .map<DownloadableUnitCoupon>((unitCoupon) => ({
              couponPolicyNo: unitCoupon.couponPolicyNo || 0,
              couponName: unitCoupon.couponName || '',
              couponType: unitCoupon.couponType || 'Unknown',
              eventNo: unitCoupon.eventNo || 0,
              encStr: unitCoupon.encStr || '',
              downloadState: CouponDownloadState.Loading,
            })),
        ),
      )
    }

    if (extraDiscountCandidates) {
      dispatch(
        setExtraDiscountCandidates({
          cartUnitId,
          extraDiscountCandidates: extraDiscountCandidates
            .filter((discount) => !discount.forGift)
            .map<ExtraDiscountCandidate>((discount) => ({
              masterNo: discount.masterNo,
              unitType: discount.discountType === 'R' ? 'Rate' : 'Amount',
              name: discount.name,
              baseAmount: discount.baseAmount || 0,
              discountAmount: discount.discountAmount || 0,
              discountRate: discount.discountRate || 0,
              maxDiscountAmount: discount.maxDiscountAmount || 0,
              forGift: !!discount.forGift,
              endDate: discount.endDate,
              sortOrder: discount.sortOrder,
            })),
        }),
      )
    }
  }

  /**
   * 펀딩할인정보 Redux 세팅(최초 getCart API 및 이후 API에서 공통으로 사용위함)
   * 일반 할인은 cart랑 같이 cart용 store에 들어가지만 funding은 별도 store에서 관리하기때문
   * @param dispatch
   * @param discounts
   */
  static setFundingDiscountReduxInfo = (
    dispatch: ComplexDispatch,
    discounts: {
      cartUnitId?: number
      extraDiscounts?: cartAPI.ExtraDiscountResponse[]
    }[],
  ): void => {
    const availableFundingDiscounts = discounts.map<{
      cartUnitId: number
      fundingDiscounts: FundingDiscount[]
    }>((discount) => {
      if (discount.extraDiscounts) {
        return {
          cartUnitId: discount.cartUnitId || 0,
          fundingDiscounts: discount.extraDiscounts.map<FundingDiscount>(
            // FIXME deprecated 한 속성들 이관하기
            (x) => ({
              discountPolicyNo: x.discountPolicyNo || 0,
              discountPolicyName: x.discountPolicyName || '',
              discountPrice: x.discountPrice || 0,
              displayName: x.displayName || '',
            }),
          ),
        }
      } else {
        return {
          cartUnitId: discount.cartUnitId || 0,
          fundingDiscounts: [],
        }
      }
    })

    dispatch(updateAvailableFundingDiscount(availableFundingDiscounts))
    // dispatch(
    //   setAvailableFundingDiscount([
    //     {
    //       checkoutUnitId: checkoutUnitList[0].checkoutUnitId,
    //       fundingDiscounts: [
    //         {
    //           discountPrice: 900,
    //           discountPolicyNo: 111,
    //           displayName: '즉할 9%할인',
    //           discountPolicyName: '즉할테스트정책명',
    //         },
    //       ],
    //     },
    //   ]),
    // )
  }

  /**
   * 할인조회
   * - 상품정보 변경시
   * @param dispatch
   * @param getState
   * @param cartUnitIdList
   */
  static updateDiscountsComplex = async (
    dispatch: ComplexDispatch,
    getState: GetState,
    cartUnitIdList?: number[],
  ): Promise<void> => {
    const state = getState()

    const cartUnitList = cartUnitIdList
      ? getCartUnitListByIdList(state.cart, cartUnitIdList)
      : state.cart.cartUnitList

    if (cartUnitList.length === 0) {
      return
    }

    const request = cartUnitList.map<cartAPI.AvailableDiscountRequest>(
      (cartUnit) => {
        const summary = getCartUnitPriceInfo(state, cartUnit.cartUnitId)
        return {
          cartUnitId: cartUnit.cartUnitId,
          itemNo: cartUnit.item.itemNo,
          shopKindCode1: cartUnit.item.shopKindCode1,
          shopKindCode2: cartUnit.item.shopKindCode2,
          shopKindCode3: cartUnit.item.shopKindCode3,
          largeCategoryCode: cartUnit.item.largeCategoryCode,
          mediumCategoryCode: cartUnit.item.mediumCategoryCode,
          smallCategoryCode: cartUnit.item.smallCategoryCode,
          sellerKey: cartUnit.seller.sellerKey,
          itemPrice: cartUnit.item.itemSellPrice,
          quantity: cartUnit.quantity,
          branchAdditionalPrice: summary.cartUnitBranchAdditionalPrice,
          optionsAdditionalPrice: summary.cartUnitOptionsAdditionalPrice,
          optionsTotalAmount:
            summary.cartUnitOptionsAdditionalPrice * cartUnit.quantity,
          additionsTotalAmount: summary.cartUnitAdditionsTotalPrice,
          cartUnitTotalAmount: summary.cartUnitPrice,
          partnershipCode: cartUnit.partnershipCode,
          isUsed: cartUnit.item.isC2C,
        }
      },
    )

    const discounts = await fetchGetAvailableDiscount({
      buyerGrade:
        state.buyer.buyerGrade !== undefined
          ? state.buyer.buyerGrade.toString()
          : undefined,
      requests: request,
    })

    dispatch(
      updateDiscounts(
        discounts.map<UpdateDiscountsPayloadType>((discount) => ({
          cartUnitId: discount.cartUnitId || 0,
          isItemDiscountAgreement: discount.isItemDiscountAgreement,
          discounts: (discount.discounts || []).map<DiscountPrice>((d) => ({
            discountType: d.discountType || 'Unknown',
            discountPrice: d.discountPrice || 0,
            appliedQuantity: d.appliedQuantity || 0,
            bundleDiscountPrice: d.bundleDiscountPrice || 0,
            totalBundleDiscountPrice: d.totalBundleDiscountPrice || 0,
            discountPolicy: {
              basisKind: d.discountPolicy?.basisKind || '',
              calcType: d.discountPolicy?.calcType || '',
              calcTypeSub: d.discountPolicy?.calcTypeSub || '',
              discountPrice: d.discountPolicy?.discountPrice || 0,
              discountRate: d.discountPolicy?.discountRate || 0,
              minApplyCondition: d.discountPolicy?.minApplyCondition || 0,
            },
          })),
        })),
      ),
    )

    ActionHelper.setFundingDiscountReduxInfo(dispatch, discounts)
  }

  /**
   * 최초 로딩시 적용된 쿠폰정보 조회
   * 최초로딩시에만 실행!
   * @param dispatch
   * @param getState
   */
  static updateAppliedUnitCoupons = async (
    dispatch: ComplexDispatch,
    getState: GetState,
  ): Promise<void> => {
    const state = getState()

    const couponAppliedCartUnitList = state.cart.cartUnitList.filter(
      (x) => x.appliedCouponIssueNoes && x.appliedCouponIssueNoes.length > 0,
    )

    if (couponAppliedCartUnitList.length === 0) {
      return
    }

    //중복된 쿠폰 조회 후 unapply
    const appliedCouponIssueNoes: number[] = couponAppliedCartUnitList.flatMap(
      (cartUnit) => cartUnit.appliedCouponIssueNoes || [],
    )

    const duplicatedCouponList = appliedCouponIssueNoes.reduce<number[]>(
      (result, value) => {
        if (
          !result.includes(value) &&
          appliedCouponIssueNoes.filter((x) => x === value).length > 1
        ) {
          return result.concat(value)
        } else {
          return result
        }
      },
      [],
    )

    if (duplicatedCouponList.length > 0) {
      await fetchUnapplyCoupons({
        couponIssueNoes: duplicatedCouponList,
      })
    }

    // 적용된 쿠폰 조회
    const coupons: cartAPI.AppliedCouponRequest[] =
      couponAppliedCartUnitList.map<cartAPI.AppliedCouponRequest>(
        (cartUnit) => {
          const summary = getCartUnitPriceInfo(state, cartUnit.cartUnitId)

          const couponIssueNoList = cartUnit.appliedCouponIssueNoes.filter(
            (coupon) => !duplicatedCouponList.includes(coupon),
          )

          return {
            cartUnitId: cartUnit.cartUnitId,
            couponIssueNoList: couponIssueNoList,
            itemNo: cartUnit.item.itemNo,
            totalPrice: summary.cartUnitItemPrice,
            itemSellPrice: cartUnit.item.itemSellPrice,
            quantity: cartUnit.quantity,
            branchPrice: summary.cartUnitBranchAdditionalPrice,
            sellerNo: cartUnit.seller.sellerKey,
            cartPartnershipCode: cartUnit.partnershipCode,
          }
        },
      )

    const appliedCouponList = await fetchGetAppliedCoupons({
      isOversea: getIsOverseaShipping(state.shipping),
      coupons: coupons,
    })

    dispatch(
      updateAvailableUnitCouponMulti(
        couponAppliedCartUnitList.map<AvailableUnitCoupon>((cartUnit) => ({
          cartUnitId: cartUnit.cartUnitId,
          unitCouponList: appliedCouponList
            .filter((x) =>
              cartUnit.appliedCouponIssueNoes.includes(x.couponIssueNo || 0),
            )
            .map<UnitCoupon>((coupon) => ({
              couponPolicyNo: coupon.couponPolicyNo || 0,
              couponIssueNo: coupon.couponIssueNo || NotDownloadedCouponIssueNo,
              couponPrice: coupon.couponPrice || 0,
              couponName: coupon.couponName || '',
              couponType: coupon.couponType || 'Unknown',
              discountUnitType: coupon.discountUnitType || 'Unknown',
              expiredDate: coupon.expiredDate,
              isSmileclubCoupon: coupon.isSmileclubCoupon,
              isBizCoupon: coupon.isBizCoupon,
              isMobileCoupon: coupon.isMobileCoupon,
              isAppCoupon: coupon.isAppCoupon,
              isDeliveryCoupon: coupon.isDeliveryCoupon,
              isOverseaCoupon: coupon.isOverseaCoupon,
              possiblePrice: coupon.possiblePrice,
              limitPrice: coupon.limitPrice,
              paymentCouponName: coupon.paymentCouponName,
              issuable: false,
              selectedByRecommendation: true,
              selectable: true,
              appliedOnAnother: false,
              appliedPriceOnAnother: 0,
              eventNo: coupon.eventNo || 0,
              encStr: coupon.encStr || '',
              isAutoDownloadedCoupon: false,
              isDownloading: false,
              applied: true,
              isNotifiedPaymentCoupon: false,
            })),
        })),
      ),
    )

    dispatch(
      applyUnitCoupons(
        couponAppliedCartUnitList.flatMap((cartUnit) =>
          cartUnit.appliedCouponIssueNoes.flatMap((couponIssueNo) =>
            appliedCouponList.some((x) => x.couponIssueNo === couponIssueNo)
              ? [
                  {
                    couponIssueNo: couponIssueNo,
                    cartUnitId: cartUnit.cartUnitId,
                  },
                ]
              : [],
          ),
        ),
      ),
    )

    // 초기 적용되어있던 쿠폰 중 조회되지 않은 쿠폰은 unapply 해준다
    const unapplyRequests: number[] = couponAppliedCartUnitList
      .flatMap((cartUnit) => cartUnit.appliedCouponIssueNoes || [])
      .flatMap((couponIssueNo) =>
        !appliedCouponList.some((y) => y.couponIssueNo === couponIssueNo)
          ? [couponIssueNo]
          : [],
      )

    if (unapplyRequests.length > 0) {
      await fetchUnapplyCoupons({
        couponIssueNoes: unapplyRequests,
      })
    }
  }

  /**
   * 옥션 쿠폰 적용하기
   * @param dispatch
   * @param getState
   * @param cartUnitIdList
   * @param selectedCouponIssueNoes
   * @param triggerElement
   */
  static applyGroupCouponComplex = async (
    dispatch: ComplexDispatch,
    getState: GetState,
    cartUnitIdList: number[],
    selectedCouponIssueNoes: number[],
    triggerElement: HTMLElement,
  ): Promise<boolean> => {
    const state = getState()

    const hasPcsDiscount = cartUnitIdList.some((cartUnitId) =>
      hasPartnershipDiscount(state.cart, cartUnitId),
    )

    if (state.buyer.memberType === 'NonMember') {
      return false
    } else if (state.buyer.memberType === 'SimpleMember' && !hasPcsDiscount) {
      const isConfirmed = await dispatch<Promise<boolean | void>>(
        confirmAuthentication(
          __(
            'ESCROW_BASKET_TEXT_250',
            '쿠폰은 본인인증 후 사용할 수 있습니다.',
          ),
          triggerElement,
        ),
      )
      if (isConfirmed) {
        window.location.href = formatString(
          domains.SELF_AUTH,
          getCurrentUrl(true),
        )
      }
      return false
    }

    /**
     * 현재의 적용상태를 그대로 반영한다
     */
    const couponIssueNoListToApply = selectedCouponIssueNoes.filter(
      (x) => x !== NotUseCouponDummyIssueNo,
    )

    // 옥션은 DB 저장하지 않기 때문에 API호출 없다

    dispatch(
      clearAllGroupCoupons({
        cartUnitIdList: cartUnitIdList,
      }),
    )

    dispatch(
      applyGroupCoupons(
        couponIssueNoListToApply.map((couponIssueNo) => ({
          couponIssueNo,
          cartUnitIdList: cartUnitIdList,
        })),
      ),
    )

    return true
  }

  /**
   * 옥션 쿠폰 발급번호 조회 후 업데이트
   */
  static updateGroupCouponIssueNo = async (
    dispatch: ComplexDispatch,
    getState: GetState,
    cartUnitIdList: number[],
    policyNo: number,
  ): Promise<number | undefined> => {
    const state = getState()

    /**
     * 대상 cartUnits 를 제외하고, 다른 cartUnits 에 적용한 쿠폰만 전달
     * + 조회된 쿠폰 목록중 같은 정책번호의 발급번호가 있는 쿠폰도 포함
     */
    const usedCoupons = uniq([
      ...getAllAppliedGroupCouponNoList(
        state.groupCoupon,
        (idList) => !isEqual(idList, cartUnitIdList),
      ),
      ...compact(
        getAvailableGroupCouponList(state.groupCoupon, cartUnitIdList)
          .filter(
            ({ couponPolicyNo, couponIssueNo }) =>
              couponPolicyNo === policyNo &&
              couponIssueNo !== NotDownloadedCouponIssueNo,
          )
          .map(({ couponIssueNo }) => couponIssueNo),
      ),
    ])

    // 쿠폰 발급번호 조회
    const unusedCouponInfo = await fetchGetUnusedCoupon(
      usedCoupons,
      policyNo,
    ).catch(() => null)
    if (unusedCouponInfo) {
      const { couponIssueNo, couponPolicyNo } = unusedCouponInfo
      // 쿠폰 발급번호 업데이트
      if (couponIssueNo && couponPolicyNo) {
        dispatch(
          setDownloadedGroupCouponIssueNo({
            cartUnitIdList,
            couponIssueNo,
            couponPolicyNo,
          }),
        )
        return couponIssueNo
      }
    }

    return undefined
  }

  /**
   * G/G9 쿠폰 적용하기
   * @param dispatch
   * @param getState
   * @param cartUnitId
   * @param selectedCouponIssueNoes
   * @param triggerElement
   */
  static applyUnitCouponComplex = async (
    dispatch: ComplexDispatch,
    getState: GetState,
    cartUnitId: number,
    selectedCouponIssueNoes: number[],
    triggerElement: HTMLElement,
  ): Promise<boolean> => {
    const state = getState()

    const hasPcsDiscount = hasPartnershipDiscount(state.cart, cartUnitId)

    if (state.buyer.memberType === 'NonMember') {
      return false
    } else if (state.buyer.memberType === 'SimpleMember' && !hasPcsDiscount) {
      const isConfirmed = await dispatch<Promise<boolean | void>>(
        confirmAuthentication(
          __(
            'ESCROW_BASKET_TEXT_250',
            '쿠폰은 본인인증 후 사용할 수 있습니다.',
          ),
          triggerElement,
        ),
      )
      if (isConfirmed) {
        window.location.href = formatString(
          domains.SELF_AUTH,
          getCurrentUrl(true),
        )
      }
      return false
    }

    /**
     * 현재의 적용상태를 그대로 반영한다
     */
    const couponIssueNoListToApply = selectedCouponIssueNoes.filter(
      (x) => x !== NotUseCouponDummyIssueNo,
    )

    // 현재 적용된것 중 선택하지 않은것 unapply
    const couponIssueNoListToUnapply = getAppliedUnitCouponList(
      state.unitCoupon,
      cartUnitId,
    )
      .filter((x) => !couponIssueNoListToApply.includes(x.couponIssueNo))
      .map((x) => x.couponIssueNo)

    const couponApplyRequest: cartAPI.ApplyCouponsCommandRequest = {
      cartUnitIds: [cartUnitId],
      // 현재의 쿠폰함에 적용중인+적용해야하는 쿠폰(취소 시 빈 리스트)
      couponIssueNoes: couponIssueNoListToApply.flatMap<number>(
        (couponIssueNo) => {
          const coupon = getUnitCoupon(
            state.unitCoupon,
            cartUnitId,
            couponIssueNo,
          )
          if (!coupon) {
            return []
          }
          return [coupon.couponIssueNo]
        },
      ),
      branchServiceType: state.smileFresh.currentBranchServiceType,
    }

    if ((couponApplyRequest.couponIssueNoes?.length || 0) > 0) {
      await fetchApplyCoupons(couponApplyRequest)
    }
    if (couponIssueNoListToUnapply.length > 0) {
      await fetchUnapplyCoupons({ couponIssueNoes: couponIssueNoListToUnapply })
    }

    dispatch(
      clearAllUnitCoupons({
        cartUnitId,
      }),
    )

    dispatch(
      applyUnitCoupons(
        couponIssueNoListToApply.map((couponIssueNo) => ({
          couponIssueNo,
          cartUnitId: cartUnitId,
        })),
      ),
    )

    return true
  }

  /**
   * 지마켓 쿠폰 발급번호 조회 후 업데이트
   */
  static updateUnitCouponIssueNo = async (
    dispatch: ComplexDispatch,
    getState: GetState,
    cartUnitId: number,
    policyNo: number,
    couponDownloadMode: CouponDownloadMode,
  ): Promise<number | undefined> => {
    const state = getState()

    /**
     * 대상 cartUnits 를 제외하고, 다른 cartUnits 에 적용한 쿠폰만 전달
     */
    const usedCoupons = getAllAppliedUnitCouponList(
      state.unitCoupon,
      (id) => id !== cartUnitId,
    ).map(({ couponIssueNo }) => couponIssueNo)

    // 쿠폰 발급번호 조회
    const unusedCouponInfo = await fetchGetUnusedCoupon(
      usedCoupons,
      policyNo,
    ).catch(() => null)

    if (unusedCouponInfo) {
      const { couponIssueNo, couponPolicyNo } = unusedCouponInfo
      // 쿠폰 발급번호 업데이트
      if (couponIssueNo && couponPolicyNo) {
        dispatch(
          setDownloadedUnitCouponIssueNo({
            cartUnitId,
            couponIssueNo,
            couponPolicyNo,
            couponDownloadMode,
          }),
        )
        return couponIssueNo
      }
    }

    return undefined
  }

  /**
   * G9 쿠폰 해제하기(쿠폰번호기준)
   * @param dispatch
   * @param getState
   * @param couponIssueNoesToUnapply
   * @param triggerElement
   */
  static unapplyUnitCouponComplex = async (
    dispatch: ComplexDispatch,
    getState: GetState,
    couponIssueNoesToUnapply: number[],
    triggerElement: HTMLElement,
  ): Promise<boolean> => {
    const state = getState()

    if (state.buyer.memberType === 'NonMember') {
      return false
    } else if (state.buyer.memberType === 'SimpleMember') {
      const isConfirmed = await dispatch<Promise<boolean | void>>(
        confirmAuthentication(
          __(
            'ESCROW_BASKET_TEXT_250',
            '쿠폰은 본인인증 후 사용할 수 있습니다.',
          ),
          triggerElement,
        ),
      )
      if (isConfirmed) {
        window.location.href = formatString(
          domains.SELF_AUTH,
          getCurrentUrl(true),
        )
      }
      return false
    }

    // if (getAppliedExtraDiscount(state.extraDiscount)) {
    //   if (
    //     !window.confirm(
    //       '쿠폰 해제 시 선택하신 카드사 즉시할인이 초기화됩니다.\n\n쿠폰 해제 후 다시 적용하시겠습니까?',
    //     )
    //   ) {
    //     return false
    //   }
    // }

    const unapplyRequest = couponIssueNoesToUnapply
      .flatMap((couponIssueNo) => {
        const cartUnitIdToUnapply = getUnitCouponAppliedCartUnitId(
          state.unitCoupon,
          couponIssueNo,
        )

        if (!cartUnitIdToUnapply) {
          return []
        } else {
          const unitCoupon = getUnitCoupon(
            state.unitCoupon,
            cartUnitIdToUnapply,
            couponIssueNo,
          )
          if (unitCoupon) {
            return {
              couponIssueNo,
              cartUnitId: cartUnitIdToUnapply,
              couponType: unitCoupon.couponType,
            }
          } else {
            return []
          }
        }
      })
      .map<number>((x) => x.couponIssueNo)

    await fetchUnapplyCoupons({
      couponIssueNoes: unapplyRequest,
    })

    dispatch(
      applyUnitCoupons(
        couponIssueNoesToUnapply.map((issueNo) => ({
          couponIssueNo: issueNo,
        })),
      ),
    )

    return true
  }

  /**
   * 전체 캐시백
   * - 상품정보 변경시
   * - 결제금액 변경시
   *  - 스마일캐시 적용시
   *  - 전체금액 변경시
   *   - 배송비 변경시
   *    - 배송지 변경시
   *   - 쿠폰금액 변경시
   *   - 할인금액 변경시
   * - 스마일클럽 가입시(현재 사용필요없음. 필요시 순서 재배치 필요)
   * @param dispatch
   * @param getState
   * @param cartUnitIdList
   */
  static updateSmileCashback = async (
    dispatch: ComplexDispatch,
    getState: GetState,
    cartUnitIdList?: number[],
  ): Promise<void> => {
    const state = getState()

    const cartUnitList = cartUnitIdList
      ? getCartUnitListByIdList(state.cart, cartUnitIdList)
      : state.cart.cartUnitList

    if (cartUnitList.length === 0) {
      return
    }

    const appliedGroupCoupons = getAppliedGroupCouponList(
      state.groupCoupon,
      cartUnitList.map((_) => _.cartUnitId),
    )

    const appliedUnitCoupons = cartUnitList.flatMap((cartUnit) =>
      getAppliedUnitCouponList(state.unitCoupon, cartUnit.cartUnitId),
    )

    const coupons: {
      cartUnitIdList?: number[]
      couponType: cartAPI.CouponType
      couponPrice: number
    }[] = appliedGroupCoupons
      .map((groupCoupon) => ({
        couponType: groupCoupon.couponType,
        couponPrice: groupCoupon.couponPrice,
        cartUnitIdList: getGroupCouponAppliedCartUnitIdList(
          state.groupCoupon,
          groupCoupon.couponIssueNo,
        ),
      }))
      .concat(
        appliedUnitCoupons.map((unitCoupon) => ({
          couponType: unitCoupon.couponType,
          couponPrice: unitCoupon.couponPrice,
          cartUnitIdList: [
            getUnitCouponAppliedCartUnitId(
              state.unitCoupon,
              unitCoupon.couponIssueNo,
            ) || 0,
          ],
        })),
      )

    const couponRequests: cartAPI.CashbackCouponRequest[] = coupons.reduce(
      (result: cartAPI.CashbackCouponRequest[], coupon) => {
        const cartUnitIdList = coupon.cartUnitIdList
        const couponRequest = result.find((x) =>
          isEqual(x.cartUnitIds, cartUnitIdList),
        )
        if (couponRequest) {
          if (coupon.couponType === 'SuperCoupon') {
            couponRequest.duplicationCouponPrice =
              (couponRequest.duplicationCouponPrice || 0) + coupon.couponPrice
          } else if (coupon.couponType === 'BuyerCoupon') {
            couponRequest.buyerCouponPrice =
              (couponRequest.buyerCouponPrice || 0) + coupon.couponPrice
          }
        } else {
          result.push({
            cartUnitIds: cartUnitIdList,
            buyerCouponPrice:
              coupon.couponType === 'BuyerCoupon' ? coupon.couponPrice : 0,
            duplicationCouponPrice:
              coupon.couponType === 'SuperCoupon' ? coupon.couponPrice : 0,
          })
        }
        return result
      },
      [],
    )

    const cartRequests = cartUnitList.map<cartAPI.CashbackCartRequest>(
      (cartUnit) =>
        TypeMapper.asCashbackCartRequest(
          cartUnit,
          getCartUnitPriceInfo(state, cartUnit.cartUnitId),
        ),
    )

    const request: cartAPI.GetCashbackRequest = {
      totalPaymentAmount: 0,
      isSmileClub: state.buyer.isSmileClub,
      cartRequests,
      couponRequests,
    }

    const { cartUnitCashbacks, useEnableDate } = await fetchGetTotalCashback(
      request,
    )

    if (cartUnitCashbacks && useEnableDate) {
      const cashbackList = cartUnitCashbacks.map<UnitCashbackApplication>(
        (x) => ({
          cartUnitId: x.cartUnitId || 0,
          details: x.smileCashbacks
            ? x.smileCashbacks.map<UnitCashback>((y) => ({
                type: y.type || 'Unknown',
                amount: y.amount || 0,
                rate: y.rate || 0,
                date: y.date || '',
              }))
            : [],
        }),
      )
      dispatch(
        updateUnitCashbackList({
          unitCashbackList: cashbackList,
          useEnableDate: useEnableDate,
        }),
      )
    }
  }

  static updateEnv = async (dispatch: ComplexDispatch): Promise<void> => {
    const env = await fetchGetEnv().catch(() => null)
    if (env) {
      dispatch(
        initView({
          tenantType: env.tenantType,
          isApp: env.isApp,
          isMobile: env.isMobile,
          isAndroid: env.isAndroid,
          isIE: env.isIE,
          appInfo: env.appInfo,
          browserVersion: env.browserVersion,
          languageType: env.languageType,
        }),
      )

      dispatch(
        initBuyer({
          isLogin: env.isLogin,
        }),
      )
    }
  }

  static updateLatestAddress = async (
    dispatch: ComplexDispatch,
    getState: GetState,
  ): Promise<void> => {
    const state = getState()
    if (state.buyer.memberType === 'NonMember') {
      return
    }
    const latestShippingAddress = await fetchGetLatestShippingAddress()
    if (!latestShippingAddress) {
      return
    }

    const { addressNo, address, zipCode, countryType, alterType, alterText } =
      latestShippingAddress

    if (address && countryType && addressNo) {
      const isZipCodeRequired = countryType !== 'HongKong' // 홍콩만 zipCode가 없다
      dispatch(
        setLatestShippingAddress({
          addressNo: addressNo,
          address: address.trim(),
          zipCode: isZipCodeRequired ? zipCode?.trim() : '',
          countryType: countryType,
          alterType: alterType || 'Unknown',
          alterText,
        }),
      )
    } else {
      dispatch(setLatestShippingAddress(undefined))
    }
  }

  /**
   * 배송예정일 정보 조회
   * - 배송지 변경시(도서산간여부)
   * @param dispatch
   * @param getState
   * @param cartUnitList
   */
  static updateTransPolicyInfo = async (
    dispatch: ComplexDispatch,
    getState: GetState,
    cartUnitList?: CartUnit[],
  ): Promise<void> => {
    const state = getState()
    if (state.view.languageType !== 'Korean') {
      return
    }

    const shippingCountry = getShippingCountry(state.shipping)

    if (shippingCountry.countryType !== 'SouthKorea') {
      setTransPolicyMap({})
      setSmileDeliveryTransPolicy({
        map: {},
      })
      return
    }
    const targetCartUnitList = cartUnitList || state.cart.cartUnitList
    const latestShippingAddress = getLatestShippingAddress(state.shipping)
    const isSmileClub = state.buyer.isSmileClub

    const smileDeliveryRequest: cartAPI.GetSmileDeliveryTransPolicyRequest = {
      isSmileClub,
      zipCode: latestShippingAddress?.zipCode,
      requests: map(
        groupBy(
          targetCartUnitList.filter((x) => x.cartUnitType === 'SmileDelivery'),
          (x) => x.item.itemNo,
        ),
        (list, itemNo) => ({
          itemNo: itemNo,
          transPolicyNo: list[0].item.transPolicyNo,
          isSmileDelivery: true,
        }),
      ),
    }

    const smileDeliveryResponse =
      (smileDeliveryRequest.requests?.length || 0) > 0
        ? await fetchGetSmileDeliveryTransPolicy(smileDeliveryRequest)
        : undefined

    if (smileDeliveryResponse) {
      const transPolicies = smileDeliveryResponse.transPolicies
      dispatch(
        setSmileDeliveryTransPolicy({
          map: (transPolicies || []).reduce<{
            [itemNo: string]: SmileDeliveryTransPolicy
          }>((result, transPolicyInfo) => {
            return {
              ...result,
              [transPolicyInfo.itemNo]: transPolicyInfo,
              // [transPolicyInfo.itemNo]:
              //   transPolicyInfo?.transPolicyDisplay?.shippingType === 'Dawn'
              //     ? {
              //         ...transPolicyInfo,
              //         dawnDeliveryCutOffTime: moment()
              //           .add(10, 'seconds')
              //           .toISOString(),
              //       }
              //     : transPolicyInfo,
            }
          }, {}),
        }),
      )
    }

    const areaCode = ((): string | undefined => {
      const tenantType = state.view.tenantType
      const isMobile = state.view.isMobile

      if (tenantType === 'Auction') {
        if (isMobile) {
          return '100002855'
        } else {
          return '100003050'
        }
      }
    })()

    if (areaCode) {
      if (smileDeliveryResponse && smileDeliveryResponse.transPolicies) {
        smileDeliveryResponse.transPolicies.forEach((transPolicy) => {
          const areaValue = {
            itemno: transPolicy.itemNo,
            arrivalscheduledate: transPolicy.arrivalScheduledDate || '',
            transpoilcyno: transPolicy.transPolicyNo,
            displaytype: transPolicy.displayType || '',
          }

          Montelena.logImpression(
            'IMP_VI',
            areaCode,
            areaValue,
            'updateTransPolicyInfo',
          )
        })
      }
    }
  }

  /**
   * 해외배송국가 전체조회
   * @param dispatch
   */
  static updateAllOverseaShippingCountries = async (
    dispatch: ComplexDispatch,
  ): Promise<void> => {
    if (!tenantConstants.IsOverseaShippingAvailable) {
      return
    }
    const overseaShippingCountries = await fetchGetOverseaShippingCountries()

    dispatch(
      setOverseaShippingCountries(
        sortBy(overseaShippingCountries, (x) => x.countryName),
      ),
    )
  }

  static updateExchangeInfo = async (
    dispatch: ComplexDispatch,
    getState: GetState,
  ): Promise<void> => {
    const state = getState()
    const currencyCodeList: string[] = []

    const cookieCurrency = CookieHelper.getCookie(CookieKeys.Currency)
    if (
      state.view.languageType !== 'Korean' &&
      cookieCurrency &&
      !currencyCodeList.includes(cookieCurrency)
    ) {
      currencyCodeList.push(cookieCurrency)
    }

    if (currencyCodeList.length > 0) {
      const exchangeRateList = await Promise.all(
        currencyCodeList.map((code) =>
          fetchGetExchangeRate({ currencyCode: code }),
        ),
      )

      dispatch(
        updateExchangeInfoList(
          exchangeRateList.flatMap((x) =>
            x.exchangeRate && x.currencyCode
              ? [
                  {
                    currencyCode: x.currencyCode,
                    exchangeRate: x.exchangeRate,
                  },
                ]
              : [],
          ),
        ),
      )
    }
  }

  static updateCartUnitsComplex = async (
    dispatch: ComplexDispatch,
    getState: GetState,
    cartUnitIdList?: number[],
    withoutShippingFeeUpdate?: boolean,
    withReselect?: boolean,
    withoutUpdateSmileFreshBranch?: boolean,
  ): Promise<void> => {
    const isAllUpdate = !cartUnitIdList

    if (cartUnitIdList && cartUnitIdList.length < 1) {
      return
    }

    const state = getState()

    if (!withoutShippingFeeUpdate) {
      dispatch(setIsShippingFeeLoadedAfterChanged(false))
    }

    // 동일상품끼리 영향주기때문에 같은상품은 다 다시조회한다.
    const cartUnitIdListToUpdate = cartUnitIdList
      ? cartUnitIdList.reduce<number[]>((result, cartUnitId) => {
          const sameItemGroupCartUnitIds = getSameItemGroupCartUnitIds(
            state.cart,
            cartUnitId,
          )
          if (sameItemGroupCartUnitIds.length === 0) {
            return result.concat(cartUnitId)
          } else if (
            intersection(sameItemGroupCartUnitIds, result).length > 0
          ) {
            return result
          } else {
            return result.concat(sameItemGroupCartUnitIds)
          }
        }, [])
      : undefined

    const {
      carts,
      shippingBundles,
      shippingPolicies,
      encodedSellerKeys,
      latestShippingAddress,
      priorityBranchServiceType,
      smileFreshBranches,
      cartNudgingTypes,
    } = await fetchGetCart(isAllUpdate ? undefined : cartUnitIdListToUpdate)

    const updatedCartUnitIds = carts?.map((x) => x.cartUnitId) || []

    if (latestShippingAddress) {
      const { addressNo, address, zipCode, countryType, alterType, alterText } =
        latestShippingAddress

      if (address && countryType && addressNo) {
        const isZipCodeRequired = countryType !== 'HongKong' // 홍콩만 zipCode가 없다
        dispatch(
          setLatestShippingAddress({
            addressNo: addressNo,
            address: address.trim(),
            zipCode: isZipCodeRequired ? zipCode?.trim() : '',
            countryType: countryType,
            alterType: alterType || 'Unknown',
            alterText,
          }),
        )
      }
    }

    if (!withoutUpdateSmileFreshBranch) {
      if (smileFreshBranches && smileFreshBranches.length > 0) {
        const branchServiceType: BranchServiceType =
          priorityBranchServiceType && priorityBranchServiceType !== 'Unknown'
            ? priorityBranchServiceType
            : 'Reserve'

        dispatch(
          initSmileFresh({
            currentBranchServiceType: branchServiceType,
            availableBranches: smileFreshBranches.map(
              TypeMapper.asSmileFreshBranch,
            ),
          }),
        )
      } else {
        dispatch(
          initSmileFresh({
            currentBranchServiceType: 'Unknown',
            availableBranches: [],
          }),
        )
      }
    }

    if (carts) {
      const cartUnitList = carts.map(TypeMapper.asCartUnit)

      dispatch(updateCartUnits({ cartUnitList, cartNudgingTypes }))

      await ActionHelper.updateSignalCartUnit(dispatch, getState, cartUnitList)

      if (encodedSellerKeys) {
        dispatch(updateEncodedSellerKeys(encodedSellerKeys))
      }
      ActionHelper.setFundingDiscountReduxInfo(dispatch, carts)

      const newState = getState()
      const buyUnavailableCartUnitIds = updatedCartUnitIds.filter((x) =>
        getBuyUnavailableInfo(newState, x),
      ) // 구매 불가 된 상품은 해제해준다
      if (buyUnavailableCartUnitIds.length > 0) {
        dispatch(
          selectOrDeselectCartUnit({
            cartUnitIdList: buyUnavailableCartUnitIds,
            isSelect: false,
          }),
        )
      }
      // 업데이트 후 선택해줘야 하는 케이스
      if (withReselect) {
        dispatch(
          selectOrDeselectCartUnit({
            cartUnitIdList: updatedCartUnitIds.filter(
              (x) => !getBuyUnavailableInfo(newState, x),
            ),
            isSelect: true,
          }),
        )
      }

      // 현재 존재하지 않는 상품이 들어온 경우
      const isNewItemInserted = uniq(carts.map((x) => x.item?.itemNo)).some(
        (itemNo) =>
          !state.cart.cartUnitList.some(
            (cartUnit) => cartUnit.item.itemNo === itemNo,
          ),
      )

      await Promise.all([
        isNewItemInserted
          ? ActionHelper.updateShippingUnavailableItems(
              dispatch,
              getState,
              cartUnitList,
            )
          : undefined,
        isNewItemInserted
          ? ActionHelper.updateTransPolicyInfo(dispatch, getState, cartUnitList)
          : undefined,
        ActionHelper.updateSmileCashback(
          dispatch,
          getState,
          updatedCartUnitIds,
        ),
        // 새로 추가하는 경우(ex. 추천상품 담기)에는 업데이트 후 선택 처리로 인해 배송비를 다시 조회하기때문에 withoutShippingFeeUpdate 를 통해 배송비업데이트를 생략
        !withoutShippingFeeUpdate
          ? // 전체 업데이트시에는 받아온 배송비를 그대로 redux 세팅 / 일부만 업데이트시에는 관련 배송비(배송비그룹별)를 다시 조회.
            // (setShippingFeeReduxInfo 는 Promise 아니지만 여기에 넣어도 크게 관계없다)
            isAllUpdate
            ? ActionHelper.setShippingFeeReduxInfo(
                dispatch,
                getState,
                shippingBundles,
                shippingPolicies,
              )
            : // 어차피 bundleKey 단위로 다시 다 조회할거라서 cartUnitIdListToUpdate 를 쓰지 않는다
              ActionHelper.updateShippingFee(dispatch, getState, cartUnitList)
          : undefined,
        ActionHelper.updateOverseaShippingCost(dispatch, getState),
      ])

      if (!withoutShippingFeeUpdate) {
        dispatch(setIsShippingFeeLoadedAfterChanged(true))
      }
    }
  }

  static updateSmileClubOneClickInfo = async (
    dispatch: ComplexDispatch,
    getState: GetState,
  ): Promise<void> => {
    const state = getState()
    const { memberType, isDomesticBusinessman, isSmileClub, smileClubInfo } =
      state.buyer

    // 원클릭 대상자 체크:
    // 1. 개인회원
    // 2. 클럽 미가입(가입이력 없는 고객 -> 이력안봄)
    // 3. 주문이력 있는 회원 <- 삭제
    // 4. 스마일페이 유효결제수단 있는 개인 회원 <- 삭제
    if (
      memberType !== 'NonMember' &&
      !isDomesticBusinessman &&
      !isSmileClub &&
      smileClubInfo.memberType !== 'Restricted'
    ) {
      dispatch(updateSmileClubOneClickTargetInfo(true))
    }
  }

  /**
   * 넛징노출 우선순위: 스마일클럽 넛징 >> 스마일카드 넛징 >> 앱 넛징
   * @param dispatch
   * @param getState
   */
  private static getNudgingType = async (
    dispatch: ComplexDispatch,
    getState: GetState,
  ): Promise<NudgingType> => {
    const state = getState()
    const months = moment().format('MM')
    const withdrawDt = state.buyer.smileClubInfo.membershipWithdrawDate || ''

    if (
      tenantConstants.SupportedNudgingTypes.includes('CLUB_ONE_CLICK') &&
      getIsOneClickTarget(state) &&
      !CookieHelper.getCookie(CookieKeys.NudgingClubOneClickClosed) &&
      state.cart.cartUnitList.length > 0 &&
      months !== moment(withdrawDt).format('MM')
    ) {
      return 'CLUB_ONE_CLICK'
    }

    if (
      tenantConstants.SupportedNudgingTypes.includes('SMILE_CARD') &&
      !CookieHelper.getCookie(CookieKeys.NudgingSmileCardClosed) &&
      state.cart.cartUnitList.length > 0
    ) {
      const smileCardNudgeCoupons = await fetchGetSmileCardNudgeCoupon()
      if (smileCardNudgeCoupons && smileCardNudgeCoupons.length > 0) {
        dispatch(
          setSmileCardNudgeCoupons(
            smileCardNudgeCoupons
              .filter((x) => x.discountPrice && x.discountPrice > 0)
              .sort((l, r) => (r.discountPrice || 0) - (l.discountPrice || 0))
              .slice(0, 2)
              .map<SmileCardNudgeCoupon>((coupon) => ({
                ...coupon,
                discountPrice: coupon.discountPrice || 0,
              })),
          ),
        )

        return 'SMILE_CARD'
      }
    }

    if (
      tenantConstants.SupportedNudgingTypes.includes('APP_DOWN') &&
      !state.view.isApp &&
      !CookieHelper.getCookie(CookieKeys.NudgingAppDownClosed) &&
      state.cart.currentCartTab === 'All'
    ) {
      return 'APP_DOWN'
    }

    if (
      tenantConstants.SupportedNudgingTypes.includes('CART_COUNT_EXTENDED') &&
      !CookieHelper.getCookie(CookieKeys.NudgingCartCountExtended) &&
      state.buyer.isDomesticBusinessman &&
      (state.buyer.cartSize ?? 0) > 100 &&
      state.cart.cartUnitList.length > 0
    ) {
      return 'CART_COUNT_EXTENDED'
    }

    return 'Unknown'
  }

  /**
   * 넛징노출 우선순위: 스마일클럽 넛징 >> 스마일카드 넛징 >> 앱 넛징
   * @param dispatch
   * @param getState
   */
  static initLayers = async (
    dispatch: ComplexDispatch,
    getState: GetState,
  ): Promise<void> => {
    const nudgingType = await ActionHelper.getNudgingType(dispatch, getState)

    if (!tenantConstants.SupportedNudgingTypes.includes(nudgingType)) {
      return
    }

    switch (nudgingType) {
      case 'CLUB_ONE_CLICK':
        dispatch(
          openSimpleLayer(
            EnumLayerType.NudgingClubOneClick,
            A11yHelper.getActiveElement(),
          ),
        )
        break
      case 'SMILE_CARD':
        dispatch(
          openSimpleLayer(
            EnumLayerType.NudgingSmileCard,
            A11yHelper.getActiveElement(),
          ),
        )
        break
      case 'APP_DOWN':
        dispatch(
          openSimpleLayer(
            EnumLayerType.NudgingAppDown,
            A11yHelper.getActiveElement(),
          ),
        )
        break
      case 'CART_COUNT_EXTENDED':
        dispatch(
          openSimpleLayer(
            EnumLayerType.NudgingCartCountExtended,
            A11yHelper.getActiveElement(),
          ),
        )
        break
    }
  }

  /**
   * BT 추천상품 초기화
   * @param dispatch
   * @param getState
   */
  static initCartBtItems = async (
    dispatch: ComplexDispatch,
    getState: GetState,
  ): Promise<void> => {
    if (!tenantConstants.IsUsingCartBt) {
      return
    }
    const state = getState()

    const seedItemNoes = uniq(
      state.cart.cartUnitList
        .filter(
          (x) => x.isBuyAvailable && !x.item.isUsedItem && !x.item.isECoupon,
        )
        .map((x) => x.item.itemNo),
    ).slice(0, 5)
    if (seedItemNoes.length === 0) {
      return
    }

    // 쿼리에 고정하여 테스트용
    // if (/type=A/i.test(window.location.search)) {
    //   dispatch(setBtViewType('TypeA'))
    // } else if (/type=B/.test(window.location.search)) {
    //   dispatch(setBtViewType('TypeB'))
    // } else {
    //   // 테스트용 쿼리 없는경우 50%:50% 으로 A/B 구분
    //   dispatch(setBtViewType(Math.random() > 0.5 ? 'TypeA' : 'TypeB'))
    // }

    // CHKP-7334 TypeB로 고정
    dispatch(setBtViewType('TypeB'))

    const sourceInfo = await fetchGetCartBt({
      loginId: state.buyer.loginId,
      itemNos: seedItemNoes,
      isApp: state.view.isApp,
    })

    dispatch(
      setBtRecommendItemMap(
        sourceInfo
          .filter((x) => x.recommendItems && x.recommendItems?.length >= 4) // n개 이상인경우만 유효하도록 처리
          .map((source) => ({
            seedItemNo: source.itemNo,
            recommendItems:
              source.recommendItems?.map(
                (item): BtRecommendItem => ({
                  itemNo: item.itemNo,
                  source: {
                    clickUrl: item.clickUrl,
                    clickSecureUrl: item.clickSecureUrl,
                    impressionUrl: item.impressionUrl,
                    impressionSecureUrl: item.impressionSecureUrl,
                    viewableImpressionUrl: item.vimpUrl,
                    viewableImpressionSecureUrl: item.vimpSecureUrl,
                    isAdvertisement: item.isAdvertisement,
                  },
                }),
              ) || [],
            isExtInfoFilled: false,
          })),
      ),
    )

    dispatch(switchToNextBtItem())

    Montelena.logImpression(
      'IMP_VI',
      (getState().recommend.btViewType === 'TypeA'
        ? areaCodes.RECOMMEND_VIEW_TYPE_A
        : areaCodes.RECOMMEND_VIEW_TYPE_B) || '',
      {},
      '',
    )

    const impressionSecureUrlList = compact(
      sourceInfo.flatMap(({ recommendItems }) =>
        recommendItems?.map(({ impressionSecureUrl }) => impressionSecureUrl),
      ),
    )

    const onceCallBackCartBtImp = once(callBackCartBtImp)

    dispatch(onceCallBackCartBtImp(impressionSecureUrlList))
  }

  /**
   * BSD 상품 정보 초기화
   * @param dispatch
   * @param getState
   */
  static updateBsdInfo = async (
    dispatch: ComplexDispatch,
    getState: GetState,
  ): Promise<void> => {
    const state = getState()
    const itemNos = Array.from(
      uniq(state.cart.cartUnitList.map((x) => x.item.itemNo)),
    )
    if (
      state.view.languageType !== 'Korean' &&
      (await fetchGetBsdGlobalEventTag()) !== 'Y'
    ) {
      return
    }
    if (itemNos.length > 0) {
      const response = await fetchGetBsdEvents({ itemNos })
      dispatch(setItemBsdEvents(response))
    }
  }

  /**
   * 사은품/덤 정보 초기화
   * @param dispatch
   * @param getState
   */
  static updatePurchaseBenefitInfo = async (
    dispatch: ComplexDispatch,
    getState: GetState,
  ): Promise<void> => {
    const state = getState()
    const itemNos = Array.from(
      uniq(state.cart.cartUnitList.map((x) => x.item.itemNo)),
    )
    if (itemNos.length > 0) {
      const response = await fetchGetPurchaseBenefits({ itemNos })
      dispatch(setItemPurchaseBenefits(response))
    }
  }

  static updateHashedCguid = async (
    dispatch: ComplexDispatch,
    getState: GetState,
  ): Promise<void> => {
    const state = getState()
    if (
      state.buyer.memberType !== 'Member' &&
      state.buyer.memberType !== 'SimpleMember'
    ) {
      return
    }
    // 국문만 호출
    if (
      state.view.languageType !== 'Korean' ||
      siteEnv.languageType !== 'Korean'
    ) {
      return
    }
    const { isApp } = state.view

    const data = await fetchGetHashedCguid()
    if (data) {
      dispatch(updateHashedCguid(data))
    }
    sendGoogleScriptByTagManager('PAGE_VIEW', data, isApp)
  }

  static sendRemoveCartUnits = async (
    dispatch: ComplexDispatch,
    getState: GetState,
    selectedCartUnitList?: CartUnit[],
  ): Promise<void> => {
    const state = getState()

    if (!selectedCartUnitList) {
      return
    }
    const { isApp } = state.view

    const { hashedCguid } = state.buyer
    sendGoogleScriptByTagManager(
      'REMOVE_FROM_CART',
      hashedCguid,
      isApp,
      selectedCartUnitList,
    )
  }

  /**
   * 스마일클럽 정보 조회
   * - 스마일클럽 가입 이후
   * @param dispatch
   * @param getState
   */
  static updateSmileClubInfo = async (
    dispatch: ComplexDispatch,
    getState: GetState,
  ): Promise<void> => {
    const state = getState()

    if (state.buyer.memberType !== 'Member') {
      return
    }

    const cartData = await fetchGetCart()

    const { buyer, expressShopDeliveryAddress, smileClub, isBusinessBuyer } =
      cartData

    if (buyer) {
      dispatch(
        initBuyer({
          buyerName: buyer.buyerName,
          buyerGrade: buyer.buyerGrade,
          memberType: buyer.simpleMember ? 'SimpleMember' : 'Member',
          isSimpleJoinForeigner: buyer.simpleJoinForeigner,
          isDomesticBusinessman: isBusinessBuyer,
          isSmileClub: smileClub && smileClub.isSmileClubMember,
          smileClubInfo: smileClub && {
            memberType: simpleSwitch<SmileClubMemberType, string>(
              smileClub.smileClubMemberType,
            )
              .on('S1', 'Waiting')
              .on('SF', 'Free')
              .on('SP', 'Purchase')
              .on('S3', 'Restricted')
              .on('S4', 'Withdrawal')
              .otherwise('Unknown'),
            membershipWithdrawDate: smileClub.membershipWithdrawDate,
          },
          expressShopDeliveryAddress:
            expressShopDeliveryAddress &&
            expressShopDeliveryAddress.address &&
            expressShopDeliveryAddress.receiverName
              ? {
                  ...expressShopDeliveryAddress,
                  address: expressShopDeliveryAddress.address,
                  receiverName: expressShopDeliveryAddress.receiverName,
                }
              : undefined,
          cartSize: buyer.cartSize,
        }),
      )
    } else {
      dispatch(
        initBuyer({
          memberType: 'NonMember',
          isDomesticBusinessman: isBusinessBuyer,
        }),
      )
    }
  }

  /**
   * 통합멤버십 정보 조회
   * @param dispatch
   * @param getState
   */
  static updateUnifyMembershipInfo = async (
    dispatch: ComplexDispatch,
    getState: GetState,
  ): Promise<void> => {
    const state = getState()

    if (
      state.buyer.memberType !== 'Member' ||
      state.view.languageType !== 'Korean'
    ) {
      return
    }

    const membershipInfo = await fetchGetUnifyMembershipInfo()

    if (membershipInfo && membershipInfo?.smileClubMemberInformation) {
      dispatch(updateUnifyMembershipInfo(membershipInfo))
    }
  }

  /**
   * 장바구니 삭제 상태 업데이트
   * - 쿠폰 unapply
   * - cartUnit 삭제 업데이트
   * - 구글스크립트 이벤트 전달
   * @param dispatch
   * @param getState
   * @param removedCartUnitIds 삭제된 CartUnitIds
   */
  static removeCartUnits = (
    dispatch: ComplexDispatch,
    getState: GetState,
    removedCartUnitIds: number[],
  ): void => {
    const state = getState()

    // 삭제된 장바구니 쿠폰그룹 unapply (옥션)
    const appliedGroupCouponMap = getAppliedGroupCouponList(
      state.groupCoupon,
      removedCartUnitIds,
    ).map((coupon) => ({ couponIssueNo: coupon.couponIssueNo }))
    dispatch(applyGroupCoupons(appliedGroupCouponMap))
    // 삭제된 장바구니 쿠폰유닛 unapply (지마켓)
    const appliedUnitCouponMap = removedCartUnitIds
      .flatMap((cartUnitId) =>
        getAppliedUnitCouponList(state.unitCoupon, cartUnitId),
      )
      .map((coupon) => ({ couponIssueNo: coupon.couponIssueNo }))
    dispatch(applyUnitCoupons(appliedUnitCouponMap))

    const removedCartUnits = getCartUnitListByIdList(
      state.cart,
      removedCartUnitIds,
    )
    // 장바구니 삭제
    dispatch(removeCartUnitList(removedCartUnitIds))
    if (removedCartUnits) {
      ActionHelper.sendRemoveCartUnits(dispatch, getState, removedCartUnits)
    }
  }
}

// endregion
