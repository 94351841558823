import React from 'react'
import { useSelector } from 'react-redux'
import OverseaShippingSummary from '~/cart/gmarket/gl/common/components/OrderSummary/OverseaShippingSummary'
import {
  getIsAvailableShippingCompanyNotExists,
  getSelectedShippingUnavailableCartUnitCount,
  RootState,
} from '~/cart/modules/reducers'
import logRender from '~/lib/log-render'

const OverseaShippingSummaryContainer = (): JSX.Element => {
  logRender()

  const isMobile = useSelector((state: RootState) => state.view.isMobile)
  const selectedShippingUnavailableCartUnitCount = useSelector(
    (state: RootState) => getSelectedShippingUnavailableCartUnitCount(state),
  )
  const isShippingCompanyNotExists = useSelector((state: RootState) =>
    getIsAvailableShippingCompanyNotExists(state),
  )

  return (
    <OverseaShippingSummary
      selectedShippingUnavailableCartUnitCount={
        selectedShippingUnavailableCartUnitCount
      }
      isMobile={isMobile}
      isShippingCompanyNotExists={isShippingCompanyNotExists}
    />
  )
}

export default OverseaShippingSummaryContainer
