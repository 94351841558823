import React from 'react'

const HtmlParser = (
  props: {
    rootTag?: string
    children: React.ReactNode | string
  } & React.HTMLAttributes<HTMLElement>,
): JSX.Element => {
  const { rootTag, children, ...elseProps } = props

  if (typeof children === 'string') {
    return React.createElement(rootTag || 'div', {
      ...elseProps,
      dangerouslySetInnerHTML: { __html: children },
    })
  } else {
    return <></>
  }
}

export default HtmlParser
