import { find } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FoldingCouponBoxLoading from '~/cart/gmarket/gl/common/components/FoldingCouponBox/FoldingCouponBoxLoading'
import FoldingCouponBoxSummary from '~/cart/gmarket/gl/common/components/FoldingCouponBox/FoldingCouponBoxSummary'
import FoldingCouponBoxTable from '~/cart/gmarket/gl/common/components/FoldingCouponBox/FoldingCouponBoxTable'
import useUnitCouponBox from '~/cart/hooks/useUnitCouponBox'
import { RootState } from '~/cart/modules/reducers'
import {
  CouponBoxSelectedIssueNoMap,
  CouponBoxTableItemViewData,
  CouponBoxTableType,
  CouponBoxTableViewData,
} from '~/cart/modules/types'
import { setUnitCouponBoxOpened } from '~/cart/modules/unit-coupon/actions'
import { getIsCartUnitCouponBoxOpened } from '~/cart/modules/unit-coupon/reducer'
import { ElementIds, NotUseCouponDummyIssueNo } from '~/data/consts'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import { formatAsDom, formatString } from '~/lib/formatter'
import { __ } from '~/lib/i18n'
import logRender from '~/lib/log-render'
import UXEHelper from '~/lib/uxe-helper'

type CouponBoxProps = {
  cartUnitId: number
}

const getAppliedIssueNo = (
  couponBoxViewData: CouponBoxTableViewData[],
  couponBoxTableType: CouponBoxTableType,
): number => {
  const tableViewData = find(
    couponBoxViewData,
    (tableViewData) => tableViewData.type === couponBoxTableType,
  )
  return tableViewData
    ? tableViewData.selectedCouponIssueNo
    : NotUseCouponDummyIssueNo
}

const getSummary = (
  totalItemPrice: number,
  couponBoxViewData: CouponBoxTableViewData[],
  selectedCoupon: CouponBoxSelectedIssueNoMap,
): {
  itemPrice: number
  discountAppliedPrice: number
  totalDiscountPrice: number
} & Record<CouponBoxTableType, number> => {
  const discountPrices = couponBoxViewData.reduce<
    Record<CouponBoxTableType, number> & { totalDiscountPrice: number }
  >(
    (result, tableViewData) => {
      const appliedCoupon = tableViewData.coupons.find(
        (x) => x.issueNo === selectedCoupon[tableViewData.type],
      )
      if (appliedCoupon) {
        return {
          ...result,
          [tableViewData.type]: appliedCoupon.price,
          totalDiscountPrice: result.totalDiscountPrice + appliedCoupon.price,
        }
      }
      return result
    },
    {
      [CouponBoxTableType.Buyer]: 0,
      [CouponBoxTableType.Seller]: 0,
      [CouponBoxTableType.Super]: 0,
      [CouponBoxTableType.Bundle]: 0,
      totalDiscountPrice: 0,
    },
  )

  return {
    itemPrice: totalItemPrice,
    discountAppliedPrice: totalItemPrice - discountPrices.totalDiscountPrice,
    ...discountPrices,
  }
}

const FoldingCouponBoxContainer = ({
  cartUnitId,
}: CouponBoxProps): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const isCouponBoxOpened = useSelector((state: RootState) =>
    getIsCartUnitCouponBoxOpened(state.unitCoupon, cartUnitId),
  )

  useEffect(() => {
    if (isCouponBoxOpened) {
      UXEHelper.setFoldingCouponBoxOpen(true)
    }
  }, [isCouponBoxOpened])

  const isCouponLoaded = useSelector((state: RootState) =>
    state.unitCoupon.couponLoadedCartUnitIds.includes(cartUnitId),
  )

  const preOnChangeCoupon = useCallback(
    (coupon: CouponBoxTableItemViewData): boolean => {
      if (coupon.isAppliedOnAnother) {
        return window.confirm(
          __(
            'REWARD_COUPON_ALERT_12',
            '다른 상품에 이미 적용되어있는 쿠폰입니다. 취소 후 이 상품에 적용하시겠습니까?',
          ),
        )
      }
      if (coupon.paymentCouponName) {
        window.alert(
          formatString(
            __(
              'ESCROW_BASKET_TEXT_153',
              '{0} 결제수단만 사용가능한 쿠폰입니다. 주문결제시 결제제한이 적용됩니다.',
            ),
            coupon.paymentCouponName,
          ),
        )
      }

      return true
    },
    [],
  )

  const closeCouponBox = useCallback(() => {
    UXEHelper.setFoldingCouponBoxOpen(false)
    dispatch(setUnitCouponBoxOpened(undefined))
  }, [dispatch])

  const {
    summary,
    selectedCoupon,
    couponBoxViewData,
    onChangeCoupon,
    onCancelCoupon,
    onApplyCoupon,
  } = useUnitCouponBox(cartUnitId)

  const sellerCouponBoxViewData = couponBoxViewData.find(
    (x) =>
      x.coupons.filter((x) => !x.isNotUseCoupon).length > 0 &&
      x.type === 'Seller',
  )

  const bundleCouponBoxViewData = couponBoxViewData.find(
    (x) =>
      x.coupons.filter((x) => !x.isNotUseCoupon).length > 0 &&
      x.type === 'Bundle',
  )

  const totalCouponCount = useSelector((state: RootState) =>
    isCouponBoxOpened ? state.unitCoupon.totalCouponCount : 0,
  )

  const availableCouponCount = couponBoxViewData.reduce(
    (result, x) =>
      result +
      x.coupons.filter((x) => !x.isDiscount && !x.isNotUseCoupon).length,
    0,
  )

  return (
    <dd
      className={`unit--item_coupon ${
        isCouponBoxOpened ? 'unit--item_coupon--active' : ''
      }`}
    >
      {isCouponBoxOpened && !isCouponLoaded && <FoldingCouponBoxLoading />}

      {isCouponLoaded && (
        <div className="item__coupon">
          <button
            className="button-close sprite__cart--after"
            onClick={closeCouponBox}
          >
            <span className="for-a11y">내쿠폰함 닫기</span>
          </button>

          <div className="coupon__header">
            <strong className="coupon_title sp_coupon">
              {__('ESCROW_BASKET_TEXT_218', '내쿠폰함')}
            </strong>
            <span className="text__coupon">
              {formatAsDom(
                __('ESCROW_BASKET_TEXT_220', '사용가능{0}장'),
                <strong className="text__unit">{availableCouponCount}</strong>,
              )}
              /
              {formatAsDom(
                __('ESCROW_BASKET_TEXT_219', '보유쿠폰{0}장'),
                <strong className="text__unit">{totalCouponCount}</strong>,
              )}
            </span>
          </div>

          <div className="coupon__body">
            {couponBoxViewData.some(
              (x) =>
                x.coupons.filter((x) => !x.isNotUseCoupon).length > 0 &&
                (x.type === 'Buyer' || x.type === 'Super'),
            ) ? (
              couponBoxViewData.map((viewData) => {
                const key = viewData.type
                return (
                  <FoldingCouponBoxTable
                    key={key}
                    cartUnitId={cartUnitId}
                    viewData={viewData}
                    selectedCouponIssueNo={selectedCoupon[viewData.type]}
                    onChangeCoupon={onChangeCoupon(preOnChangeCoupon)}
                    onCancelCoupon={onCancelCoupon}
                  />
                )
              })
            ) : sellerCouponBoxViewData || bundleCouponBoxViewData ? (
              <>
                {sellerCouponBoxViewData && (
                  <FoldingCouponBoxTable
                    cartUnitId={cartUnitId}
                    viewData={sellerCouponBoxViewData}
                    selectedCouponIssueNo={selectedCoupon['Seller']}
                    onChangeCoupon={onChangeCoupon(preOnChangeCoupon)}
                    onCancelCoupon={onCancelCoupon}
                  />
                )}
                {bundleCouponBoxViewData && (
                  <FoldingCouponBoxTable
                    cartUnitId={cartUnitId}
                    viewData={bundleCouponBoxViewData}
                    selectedCouponIssueNo={selectedCoupon['Bundle']}
                    onChangeCoupon={onChangeCoupon(preOnChangeCoupon)}
                    onCancelCoupon={onCancelCoupon}
                  />
                )}
                <div className="coupon-group coupon-empty">
                  <p className="text">
                    {__(
                      'REWARD_COUPON_TEXT_4',
                      '보유하신 쿠폰 중 조건에 만족하는 쿠폰이 없습니다.',
                    )}
                  </p>
                </div>
              </>
            ) : (
              <div className="coupon-group coupon-empty">
                <p className="text">
                  {__(
                    'REWARD_COUPON_TEXT_4',
                    '현재 적용가능한 쿠폰이 없습니다.',
                  )}
                </p>
                <button className="button__close" onClick={closeCouponBox}>
                  {__('ESCROW_M_BASKET_TEXT_155', '창 닫기')}
                </button>
              </div>
            )}
          </div>

          <div className="coupon__footer">
            <FoldingCouponBoxSummary summary={summary} />
            <div className="coupon_button">
              <button className="button-cancel" onClick={closeCouponBox}>
                {__('REWARD_COUPON_TEXT_13', '취소')}
              </button>
              <button
                className="button-submit"
                id={ElementIds.CouponSubmitButton + cartUnitId}
                onClick={onApplyCoupon(closeCouponBox)}
              >
                {__('REWARD_COUPON_TEXT_14', '적용하기')}
              </button>
            </div>
          </div>
        </div>
      )}
    </dd>
  )
}

export default FoldingCouponBoxContainer
