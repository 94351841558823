import React from 'react'
import { useDispatch } from 'react-redux'
import CartToolbar from '~/cart/gmarket/gl/common/components/CartToolbar'
import { removeCartUnits } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import logRender from '~/lib/log-render'

const CartToolbarContainer = (): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const onClickDeleteSelectedCartUnit = (
    e: React.MouseEvent<HTMLElement>,
  ): void => {
    dispatch(removeCartUnits(e.currentTarget))
  }

  return (
    <CartToolbar
      onClickDeleteSelectedCartUnit={onClickDeleteSelectedCartUnit}
    />
  )
}

export default CartToolbarContainer
