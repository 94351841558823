import React from 'react'
import isEqual from 'react-fast-compare'
import { CouponBoxTableType } from '~/cart/modules/types'
import { currencyAmount } from '~/lib/formatter'
import { __ } from '~/lib/i18n'

type FoldingCouponBoxSummaryProps = {
  summary: {
    itemPrice: number
    discountAppliedPrice: number
    totalDiscountPrice: number
  } & Record<CouponBoxTableType, number>
}

const FoldingCouponBoxSummary = ({
  summary,
}: FoldingCouponBoxSummaryProps): JSX.Element => {
  return (
    <div className="coupon_total">
      <div className="price_origin sprite__cart--after">
        <span className="label">{__('REWARD_COUPON_TEXT_16', '상품가격')}</span>

        <span className="format-price">
          <span className="box__format-amount">
            <span className="text__unit">￦</span>
            <strong className="text__value">
              {currencyAmount(summary.itemPrice)}
            </strong>
          </span>
        </span>
      </div>

      <div className="math">
        <i className="icon icon_minus">
          <span className="for_a11y">빼기</span>
        </i>
      </div>

      <div className="price_discount sprite__cart--after">
        <span className="label">{__('REWARD_COUPON_TEXT_17', '할인금액')}</span>
        <span className="format-price">
          <span className="box__format-amount">
            <span className="text__unit">￦</span>
            <strong className="text__value">
              {currencyAmount(summary.totalDiscountPrice)}
            </strong>
          </span>
        </span>
      </div>

      <div className="math">
        <i className="icon icon_result">
          <span className="for_a11y">계산값은</span>
        </i>
      </div>

      <div className="price_total">
        <span className="label">
          {__('REWARD_COUPON_TEXT_18', '총 상품금액')}
        </span>
        <span className="format-price">
          <span className="box__format-amount">
            <span className="text__unit">￦</span>
            <strong className="text__value">
              {currencyAmount(summary.discountAppliedPrice)}
            </strong>
          </span>
        </span>
      </div>
    </div>
  )
}

export default React.memo(FoldingCouponBoxSummary, isEqual)
