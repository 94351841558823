import React from 'react'
import useLoadingDots from '~/cart/hooks/useLoadingDots'
import { __ } from '~/lib/i18n'

const FoldingCouponBoxLoading = (): JSX.Element => {
  const type = 'dot_mix_48'
  const [ref] = useLoadingDots(type)

  return (
    <div className={`item__coupon-loading ${type}`} ref={ref}>
      {__('REWARD_COUPON_ALERT_3', '쿠폰정보를 로딩중입니다.')}
    </div>
  )
}

export default FoldingCouponBoxLoading
