import React from 'react'
import areaCodes from '~/data/areaCodes'
import domains from '~/data/domains'
import CustomAnchor from '~/lib/components/CustomAnchor'
import { __ } from '~/lib/i18n'

const EmptyCart = ({}: {}): JSX.Element => {
  return (
    <div id="cart_null">
      <div className="inner_cont">
        <div className="none_item">
          <strong className="msg">
            {__('ESCROW_BASKET_TEXT_1', '장바구니에 담긴 상품이 없습니다.')}
          </strong>
          <CustomAnchor
            className="btn_action"
            href={domains.HOME}
            data-montelena-acode={areaCodes.GO_HOME}
          >
            {__('ESCROW_BASKET_TEXT_108', '홈으로 가기')}
          </CustomAnchor>
        </div>
      </div>
    </div>
  )
}

export default EmptyCart
