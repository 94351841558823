import React from 'react'
import { __ } from '~/lib/i18n'

const CartUnitQtyLoading = (): JSX.Element => {
  return (
    <div className="loading dot_mix_24">
      <span className="for_a11y">{__('ESCROW_BASKET_TEXT_92', '로딩중')}</span>
    </div>
  )
}

export default CartUnitQtyLoading
