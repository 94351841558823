import React from 'react'
import isEqual from 'react-fast-compare'
import { useSelector } from 'react-redux'
import CartUnitContainer from '~/cart/gmarket/gl/common/containers/CartItemList/CartUnitContainer'
import CartSelector from '~/cart/gmarket/gl/common/containers/CartSelector'
import { getCartUnitById } from '~/cart/modules/cart/reducer'
import { ItemGroupView } from '~/cart/modules/cart/types'
import { RootState } from '~/cart/modules/reducers'
import areaCodes from '~/data/areaCodes'
import domains from '~/data/domains'
import CustomAnchor from '~/lib/components/CustomAnchor'
import { formatString } from '~/lib/formatter'
import { __ } from '~/lib/i18n'
import logRender from '~/lib/log-render'

const CartItemGroupContainer = ({
  itemGroup,
}: {
  itemGroup: ItemGroupView
}): JSX.Element => {
  logRender()

  const firstCartUnitId = itemGroup.cartUnitIdList[0]

  const firstCartUnit = useSelector((state: RootState) => {
    const cartUnit = getCartUnitById(state.cart, firstCartUnitId)
    return cartUnit
      ? { cartUnitId: cartUnit.cartUnitId, item: cartUnit.item }
      : undefined
  }, isEqual)

  if (!firstCartUnit) {
    return <></>
  }

  const id = itemGroup.key + firstCartUnit.cartUnitId
  const isBigSmile = firstCartUnit.item.bsd && firstCartUnit.item.bsd.bigSmile

  return (
    <li className="order--idx">
      <div className="item">
        <div className="item_check">
          <CartSelector
            id={id}
            title={__('ESCROW_BASKET_TEXT_84', '상품선택')}
            cartUnitIdList={itemGroup.cartUnitIdList}
            data-montelena-acode={areaCodes.SELECT_ITEM}
          />
        </div>
        <div className="item_img">
          <CustomAnchor
            href={formatString(domains.VIP, itemGroup.key)}
            data-montelena-acode={areaCodes.ITEM_IMAGE}
            data-montelena-goodscode={itemGroup.key}
          >
            {isBigSmile && (
              <span className="box__bigs-tag">
                <img
                  src={`${domains.PICS}/mobile/single/en/cart/image__tag.png`}
                  className="image"
                  alt="Big Smile Day 상품"
                />
              </span>
            )}
            <img
              src={firstCartUnit.item.itemImageUrl}
              alt="상품이미지"
              onError={(e): void => {
                e.currentTarget.src = domains.ITEM_NO_IMAGE
              }}
            />
          </CustomAnchor>
        </div>
        <div className="item_info">
          {itemGroup.cartUnitIdList.map((cartUnitId, idx) => (
            <CartUnitContainer
              key={cartUnitId}
              cartUnitId={cartUnitId}
              firstCartUnitId={firstCartUnitId}
              idx={idx}
            />
          ))}
        </div>
      </div>
    </li>
  )
}

export default React.memo(CartItemGroupContainer, isEqual)
