import React from 'react'
import { ItemAddition } from '~/cart/modules/cart/types'
import { currencyAmount } from '~/lib/formatter'
import { __ } from '~/lib/i18n'

const CartUnitAddition = ({
  addition,
}: {
  addition: ItemAddition
}): JSX.Element => {
  return (
    <li>
      <span className="add_name">{addition.name}</span>
      <span className="add_value">
        {addition.value}{' '}
        {addition.price !== 0 &&
          `(${addition.price > 0 ? '+' : '-'}₩${currencyAmount(
            Math.abs(addition.price),
          )})`}
      </span>
      <span className="add_count">
        {addition.quantity + __('ESCROW_BASKET_TEXT_64', '개')}
      </span>
    </li>
  )
}

export default CartUnitAddition
