import React from 'react'
import CartSelector from '~/cart/gmarket/gl/common/containers/CartSelector'
import { SellerGroupView } from '~/cart/modules/cart/types'
import areaCodes from '~/data/areaCodes'
import domains from '~/data/domains'
import CustomAnchor from '~/lib/components/CustomAnchor'
import { formatString } from '~/lib/formatter'
import { __ } from '~/lib/i18n'

const getShopLink = (
  sellerGroup: SellerGroupView,
  shopLink: string,
): string => {
  if (sellerGroup.type === 'SmileDelivery' && domains.SMILE_DELIVERY_HOME) {
    return domains.SMILE_DELIVERY_HOME
  } else {
    return formatString(domains.MINI_SHOP, shopLink)
  }
}

const CartSellerGroupHeader = ({
  sellerGroup,
  shopLink,
}: {
  sellerGroup: SellerGroupView
  shopLink: string
}): JSX.Element => {
  return (
    <div className="cart--basket_header">
      <CartSelector
        id={`${sellerGroup.type}_${sellerGroup.key}`}
        title={__('ESCROW_BASKET_TEXT_82', '상품그룹 선택')}
        wrapperTitle={__('ESCROW_BASKET_TEXT_102', '샵 내 상품 전체선택')}
        cartUnitIdList={sellerGroup.cartUnitIdList}
        data-montelena-acode={areaCodes.SELECT_ALL_MINISHOP}
      />
      <CustomAnchor
        className="link_area"
        href={getShopLink(sellerGroup, shopLink)}
        data-montelena-acode="{getAreaCode(sellerGroup)}"
      >
        <span className="for_a11y">
          {__('ESCROW_BASKET_TEXT_83', '으로 이동')}
        </span>
        <strong className="shop_name">{sellerGroup.shopName}</strong>
      </CustomAnchor>
    </div>
  )
}

export default CartSellerGroupHeader
