import React from 'react'
import OverseaShippingCompanyContainer from '~/cart/gmarket/gl/common/containers/OrderSummary/OverseaShippingCompanyContainer'
import areaCodes from '~/data/areaCodes'
import domains from '~/data/domains'
import CustomAnchor from '~/lib/components/CustomAnchor'
import { formatAsDom } from '~/lib/formatter'
import { __ } from '~/lib/i18n'

const OverseaShippingSummary = ({
  selectedShippingUnavailableCartUnitCount,
  isMobile,
  isShippingCompanyNotExists,
}: {
  selectedShippingUnavailableCartUnitCount: number
  isMobile: boolean
  isShippingCompanyNotExists: boolean
}): JSX.Element => {
  return (
    <div className="order_oversea">
      {isShippingCompanyNotExists ? (
        <div className="oversea_notice">
          <strong>
            {__(
              'ESCROW_BASKET_TEXT_244',
              '선택하신 국가로 배송이 가능한 택배사가 없습니다.',
            )}
          </strong>
        </div>
      ) : (
        selectedShippingUnavailableCartUnitCount > 0 && (
          <div className="oversea_notice">
            <strong>
              {formatAsDom(
                __(
                  'ESCROW_BASKET_TEXT_29',
                  '선택하신 국가로 배송이 불가능한 상품이 ({0})개가 있습니다.',
                ),
                selectedShippingUnavailableCartUnitCount,
              )}
            </strong>
          </div>
        )
      )}
      {!isShippingCompanyNotExists && (
        <>
          <div className="oversea_rule">
            <div className="overflow_delivery_rule">
              <span className="label">
                {__('ESCROW_BASKET_TEXT_38', '배송방법')}
              </span>
            </div>
            <OverseaShippingCompanyContainer />

            {!isMobile && domains.OVERSEA_SHIPPING_GUIDE && (
              <CustomAnchor
                target="_blank"
                href={domains.OVERSEA_SHIPPING_GUIDE}
                title="해외배송안내"
                className="btn_more"
                data-montelena-acode={areaCodes.OVERSEA_SHIPPING_GUIDE}
              >
                <span className="text">
                  {__('ESCROW_BASKET_TEXT_24', '해외배송안내')}
                </span>
                <i className="icon sprite__cart img_arrow_right" />
              </CustomAnchor>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default OverseaShippingSummary
