import dataPicker from '~/data/data-picker'
import siteEnv from '~/lib/site-env'

export interface AreaCodeMap {
  [key: string]: string | undefined
}

type AreaCodes = {
  ALL_CART_TAB: string
  SMILE_FRESH_CART_TAB: string
  SMILE_DELIVERY_CART_TAB: string
  EXPRESS_SHOP_CART_TAB: string
  SELECT_ALL: string
  DELETE_SELECT: string
  PRINT_ESTIMATE: string
  SELECT_ALL_MINISHOP: string
  GO_MINISHOP: string
  OVERSEA_SHIPPING: string
  SELECT_ITEM: string
  ITEM_IMAGE: string
  ITEM_NAME: string
  SUBTRACT_QTY: string
  EDIT_QTY: string
  ADD_QTY: string
  DELETE_CARTUNIT: string
  COUPON_BOX: string
  OVERSEA_SHIPPING_GUIDE: string
  DELETE_UNAVAILABLE_CARTUNIT: string
  RESELECT_UNAVAILABLE_CARTUNIT: string
  OVERSEA_SHIPPING_SF_GUIDE: string
  GO_SMILE_DELIVERY_MINISHOP: string
  GO_EXPRESS_SHOP_MINISHOP: string
  SMILE_CASH_DETAIL: string
  GO_ORDER_CARTUNIT: string
  GO_ORDER: string
  GO_ORDER_FLOATING: string
  GO_TOP: string
  GO_HOME: string
  GO_FAVORITE: string
  SHIPPING_FEE_PROMOTION: string
  REMOVE_SHIPPING_FEE: string
  SMILE_FREE_SHIPPING_BANNER: string
  GO_SMILE_FRESH_HOME: string
  GO_SMILE_DELIVERY_HOME: string
  GO_EXPRESS_SHOP_HOME: string
  ONE_CLICK_BANNER: string
  JOIN_ONE_CLICK_ON_BANNER?: string
  CLOSE_ONE_CLICK_BANNER: string
  COMPLETE_JOIN_ONE_CLICK_ON_BANNER: string
  FAIL_JOINING_ONE_CLICK_ON_BANNER: string
  ONE_CLICK_TERMS_CLUB_ON_BANNER: string
  ONE_CLICK_TERMS_PERSONAL_ON_BANNER: string
  ONE_CLICK_TERMS_PERSONAL_INFO_ON_BANNER: string
  ONE_CLICK_COUPON: string
  JOIN_ONE_CLICK_ON_COUPON: string
  CLOSE_ONE_CLICK_COUPON: string
  COMPLETE_JOIN_ONE_CLICK_ON_COUPON: string
  FAIL_JOINING_ONE_CLICK_ON_COUPON: string
  ONE_CLICK_TERMS_CLUB_ON_COUPON: string
  ONE_CLICK_TERMS_PERSONAL_ON_COUPON: string
  ONE_CLICK_TERMS_PERSONAL_INFO_ON_COUPON: string
  SMILE_SHIPPING_ARRIVAL_GUARANTEE: string
  JOIN_ONE_CLICK_BIZ_ON_BANNER: string
  COMPLETE_JOIN_ONE_CLICK_BIZ_ON_BANNER: string
  JOIN_ONE_CLICK_BIZ_ON_COUPON: string
  COMPLETE_JOIN_ONE_CLICK_BIZ_ON_COUPON: string
  GO_APP: string
  CLOSE_APP_NUDGING_BANNER: string
  CHANGE_LATEST_ADDRESS: string
  CHANGE_SMILE_FRESH_LATEST_ADDRESS: string
  ADD_SMILE_FRESH_LATEST_ADDRESS: string
  NEED_TO_CHANGE_SMILE_FRESH_LATEST_ADDRESS: string
  CHANGE_UNABLE_SMILE_FRESH_LATEST_ADDRESS: string
  LATEST_ADDRESS_API_ERROR: string
  CHANGE_LATEST_ADDRESS_NON_MEMBER: string
  FAST_DELIVERY_CLUB_NUDGING: string
  FAST_DELIVERY_CLUB_NUDGING_CONFIRM: string
  CLUB_NUDGING_CLICK?: string
  CLUB_NUDGING_ACTIVED?: string
  ADD_LATEST_ADDRESS: string
  SMILE_CLUB_FREE_DELIVERY: string
  SMILE_FRESH_BRANCH_SERVICE_TYPE: string
  SMILE_FRESH_FREE_SHIPPING_GAUGE: string
  SHOW_LAYER_ORDER_EXCEPT_SOME_ITEMS: string
  CONFIRM_ORDER_EXCEPT_SOME_ITEMS: string
  CLOSE_ORDER_EXCEPT_SOME_ITEMS: string
  ONLY_INCOME_DUTY_ITEM_ORDER: string
  INCOME_DUTY_ITEM_ORDER_TOGETHER: string
  RECOMMEND_VIEW_TYPE_A?: string
  RECOMMEND_VIEW_TYPE_B?: string
  RECOMMEND_ITEM_INFO_TYPE_A?: string
  RECOMMEND_ITEM_INFO_TYPE_B?: string
  ADD_TO_CART_RECOMMEND_ITEM_TYPE_A?: string
  ADD_TO_CART_RECOMMEND_ITEM_TYPE_B?: string
  SELECT_RECOMMEND_ITEM_OPTION_TYPE_A?: string
  SELECT_RECOMMEND_ITEM_OPTION_TYPE_B?: string
  REFRESH_RECOMMEND_ITEMS_TYPE_A?: string
  REFRESH_RECOMMEND_ITEMS_TYPE_B?: string
  OVERSEA_SHIPPING_POLICY_KEEP_ORDER: string
  OVERSEA_SHIPPING_POLICY_RETURN_CART: string
  OVERSEA_SHIPPING_POLICY_CLOSE_LAYER: string
  BIZ_CART_EXTENDED_CONFIRM?: string
  BIZ_CART_EXTENDED_CONFIRMED?: string
  BUY_BOX_CARD: AreaCodeMap
  BUY_BOX_WITH_ADD_CART: AreaCodeMap
  BUY_BOX_WITH_SELECT_OPTION: AreaCodeMap
  TOAST_ADDED_BUY_BOX_TO_CART: AreaCodeMap
  TOOLTIP_RECOMMENDED_COUPON_FOR_BUY_BOX: AreaCodeMap
  PENDING_BUY_BOX: AreaCodeMap
}

const areaCodeSources: SiteEnvDataType<Partial<AreaCodes>>[] = [
  {
    env: {
      tenantType: 'Gmarket',
      deviceType: 'PC',
      languageType: 'Korean',
    },
    data: {
      ALL_CART_TAB: '200002842',
      SMILE_FRESH_CART_TAB: '200007821',
      SMILE_DELIVERY_CART_TAB: '200002843',
      EXPRESS_SHOP_CART_TAB: '200002844',
      SELECT_ALL: '200002845',
      DELETE_SELECT: '200002846',
      PRINT_ESTIMATE: '200002847',
      SELECT_ALL_MINISHOP: '200002848',
      GO_MINISHOP: '200002849',
      OVERSEA_SHIPPING: '200002850',
      SELECT_ITEM: '200002851',
      ITEM_IMAGE: '200002852',
      ITEM_NAME: '200002853',
      SUBTRACT_QTY: '200002854',
      EDIT_QTY: '200002855',
      ADD_QTY: '200002856',
      DELETE_CARTUNIT: '200002858',
      COUPON_BOX: '200002857',
      OVERSEA_SHIPPING_GUIDE: '200002859',
      DELETE_UNAVAILABLE_CARTUNIT: '200002860',
      OVERSEA_SHIPPING_SF_GUIDE: '200002863',
      GO_SMILE_DELIVERY_MINISHOP: '200002864',
      GO_EXPRESS_SHOP_MINISHOP: '200002865',
      SMILE_CASH_DETAIL: '200002866',
      GO_ORDER: '200002867',
      GO_ORDER_FLOATING: '',
      GO_TOP: '200002868',
      GO_HOME: '200002869',
      REMOVE_SHIPPING_FEE: '200002870',
      SMILE_FREE_SHIPPING_BANNER: '200002871',
      GO_SMILE_FRESH_HOME: '200007834',
      GO_SMILE_DELIVERY_HOME: '200002872',
      GO_EXPRESS_SHOP_HOME: '200002873',
      ONE_CLICK_COUPON: '200003689',
      JOIN_ONE_CLICK_ON_COUPON: '200003691',
      CLOSE_ONE_CLICK_COUPON: '200003692',
      COMPLETE_JOIN_ONE_CLICK_ON_COUPON: '200003693',
      FAIL_JOINING_ONE_CLICK_ON_COUPON: '200003694',
      ONE_CLICK_TERMS_CLUB_ON_COUPON: '200003695',
      ONE_CLICK_TERMS_PERSONAL_ON_COUPON: '200003696',
      ONE_CLICK_TERMS_PERSONAL_INFO_ON_COUPON: '200006929',
      SMILE_SHIPPING_ARRIVAL_GUARANTEE: '200003840',
      JOIN_ONE_CLICK_BIZ_ON_BANNER: '200005859',
      COMPLETE_JOIN_ONE_CLICK_BIZ_ON_BANNER: '200005860',
      JOIN_ONE_CLICK_BIZ_ON_COUPON: '200005864',
      COMPLETE_JOIN_ONE_CLICK_BIZ_ON_COUPON: '200005865',
      CHANGE_LATEST_ADDRESS: '200006819',
      CHANGE_SMILE_FRESH_LATEST_ADDRESS: '200007824',
      ADD_SMILE_FRESH_LATEST_ADDRESS: '200007825',
      NEED_TO_CHANGE_SMILE_FRESH_LATEST_ADDRESS: '200007826',
      CHANGE_UNABLE_SMILE_FRESH_LATEST_ADDRESS: '200007827',
      LATEST_ADDRESS_API_ERROR: '200007828',
      CHANGE_LATEST_ADDRESS_NON_MEMBER: '200006821',
      ADD_LATEST_ADDRESS: '200006823',
      FAST_DELIVERY_CLUB_NUDGING: '200009325',
      CLUB_NUDGING_CLICK: '200010244',
      CLUB_NUDGING_ACTIVED: '200010243',
      SMILE_CLUB_FREE_DELIVERY: '200006931',
      SMILE_FRESH_BRANCH_SERVICE_TYPE: '200007822',
      SMILE_FRESH_FREE_SHIPPING_GAUGE: '200007823',
      SHOW_LAYER_ORDER_EXCEPT_SOME_ITEMS: '200007829',
      CONFIRM_ORDER_EXCEPT_SOME_ITEMS: '200007830',
      CLOSE_ORDER_EXCEPT_SOME_ITEMS: '200007831',
      ONLY_INCOME_DUTY_ITEM_ORDER: '200007832',
      INCOME_DUTY_ITEM_ORDER_TOGETHER: '200007833',
      OVERSEA_SHIPPING_POLICY_KEEP_ORDER: '200008596',
      OVERSEA_SHIPPING_POLICY_RETURN_CART: '200008597',
      OVERSEA_SHIPPING_POLICY_CLOSE_LAYER: '200008598',
      BIZ_CART_EXTENDED_CONFIRM: '200011679',
      BIZ_CART_EXTENDED_CONFIRMED: '200011680',
      BUY_BOX_CARD: {
        All: '200011467',
        SmileDelivery: '200011468',
        ExpressShop: '200011469',
      },
      BUY_BOX_WITH_ADD_CART: {
        All: '200011470',
        SmileDelivery: '200011471',
        ExpressShop: '200011472',
      },
      BUY_BOX_WITH_SELECT_OPTION: {
        All: '200011473',
        SmileDelivery: '200011474',
        ExpressShop: '200011475',
      },
      TOAST_ADDED_BUY_BOX_TO_CART: {
        All: '200011476',
        SmileDelivery: '200011477',
        ExpressShop: '200011478',
      },
      TOOLTIP_RECOMMENDED_COUPON_FOR_BUY_BOX: {
        All: '200011479',
        SmileDelivery: '200011480',
        ExpressShop: '200011481',
      },
      PENDING_BUY_BOX: {
        All: '200011482',
        SmileDelivery: '200011483',
        ExpressShop: '200011484',
      },
    },
  },
  {
    env: {
      tenantType: 'Gmarket',
      deviceType: 'Mobile',
      languageType: 'Korean',
    },
    data: {
      ALL_CART_TAB: '200002874',
      SMILE_FRESH_CART_TAB: '200007807',
      SMILE_DELIVERY_CART_TAB: '200002875',
      EXPRESS_SHOP_CART_TAB: '200002876',
      SELECT_ALL: '200002877',
      DELETE_SELECT: '200002878',
      PRINT_ESTIMATE: '',
      SELECT_ALL_MINISHOP: '200002879',
      GO_MINISHOP: '200002880',
      OVERSEA_SHIPPING: '',
      SELECT_ITEM: '200002881',
      ITEM_IMAGE: '200002882',
      ITEM_NAME: '200002882',
      SUBTRACT_QTY: '200002883',
      EDIT_QTY: '200002884',
      ADD_QTY: '200002885',
      DELETE_CARTUNIT: '200002887',
      COUPON_BOX: '200002886',
      OVERSEA_SHIPPING_GUIDE: '',
      DELETE_UNAVAILABLE_CARTUNIT: '200002888',
      OVERSEA_SHIPPING_SF_GUIDE: '',
      GO_SMILE_DELIVERY_MINISHOP: '200002889',
      GO_EXPRESS_SHOP_MINISHOP: '200002890',
      SMILE_CASH_DETAIL: '200002891',
      GO_ORDER: '200002893',
      GO_ORDER_FLOATING: '',
      GO_TOP: '200002892',
      GO_HOME: '200002894',
      REMOVE_SHIPPING_FEE: '200002895',
      SMILE_FREE_SHIPPING_BANNER: '200002896',
      GO_SMILE_FRESH_HOME: '200007820',
      GO_SMILE_DELIVERY_HOME: '200002897',
      GO_EXPRESS_SHOP_HOME: '200002898',
      ONE_CLICK_BANNER: '200003655',
      JOIN_ONE_CLICK_ON_BANNER: '200003656',
      CLOSE_ONE_CLICK_BANNER: '200003657',
      COMPLETE_JOIN_ONE_CLICK_ON_BANNER: '200003658',
      FAIL_JOINING_ONE_CLICK_ON_BANNER: '200003659',
      ONE_CLICK_TERMS_CLUB_ON_BANNER: '200003661',
      ONE_CLICK_TERMS_PERSONAL_ON_BANNER: '200003662',
      ONE_CLICK_TERMS_PERSONAL_INFO_ON_BANNER: '200006928',
      ONE_CLICK_COUPON: '200003665',
      JOIN_ONE_CLICK_ON_COUPON: '200003666',
      CLOSE_ONE_CLICK_COUPON: '200003667',
      COMPLETE_JOIN_ONE_CLICK_ON_COUPON: '200003668',
      FAIL_JOINING_ONE_CLICK_ON_COUPON: '200003669',
      ONE_CLICK_TERMS_CLUB_ON_COUPON: '200003670',
      ONE_CLICK_TERMS_PERSONAL_ON_COUPON: '200003671',
      ONE_CLICK_TERMS_PERSONAL_INFO_ON_COUPON: '200006929',
      SMILE_SHIPPING_ARRIVAL_GUARANTEE: '200003841',
      JOIN_ONE_CLICK_BIZ_ON_BANNER: '200005855',
      COMPLETE_JOIN_ONE_CLICK_BIZ_ON_BANNER: '200005857',
      JOIN_ONE_CLICK_BIZ_ON_COUPON: '200005862',
      COMPLETE_JOIN_ONE_CLICK_BIZ_ON_COUPON: '200005863',
      GO_APP: '200006283',
      CLOSE_APP_NUDGING_BANNER: '200006284',
      CHANGE_LATEST_ADDRESS: '200006820',
      CHANGE_SMILE_FRESH_LATEST_ADDRESS: '200007810',
      ADD_SMILE_FRESH_LATEST_ADDRESS: '200007811',
      NEED_TO_CHANGE_SMILE_FRESH_LATEST_ADDRESS: '200007812',
      CHANGE_UNABLE_SMILE_FRESH_LATEST_ADDRESS: '200007813',
      LATEST_ADDRESS_API_ERROR: '200007814',
      CHANGE_LATEST_ADDRESS_NON_MEMBER: '200006822',
      ADD_LATEST_ADDRESS: '200006824',
      FAST_DELIVERY_CLUB_NUDGING: '200006825',
      FAST_DELIVERY_CLUB_NUDGING_CONFIRM: '200006826',
      CLUB_NUDGING_CLICK: '200010244',
      CLUB_NUDGING_ACTIVED: '200010243',
      SMILE_CLUB_FREE_DELIVERY: '200006930',
      SMILE_FRESH_BRANCH_SERVICE_TYPE: '200007808',
      SMILE_FRESH_FREE_SHIPPING_GAUGE: '200007809',
      SHOW_LAYER_ORDER_EXCEPT_SOME_ITEMS: '200007815',
      CONFIRM_ORDER_EXCEPT_SOME_ITEMS: '200007816',
      CLOSE_ORDER_EXCEPT_SOME_ITEMS: '200007817',
      ONLY_INCOME_DUTY_ITEM_ORDER: '200007818',
      INCOME_DUTY_ITEM_ORDER_TOGETHER: '200007819',
      RECOMMEND_VIEW_TYPE_A: '200007426',
      RECOMMEND_VIEW_TYPE_B: '200007431',
      RECOMMEND_ITEM_INFO_TYPE_A: '200007427',
      RECOMMEND_ITEM_INFO_TYPE_B: '200007432',
      ADD_TO_CART_RECOMMEND_ITEM_TYPE_A: '200007428',
      ADD_TO_CART_RECOMMEND_ITEM_TYPE_B: '200007433',
      SELECT_RECOMMEND_ITEM_OPTION_TYPE_A: '200007429',
      SELECT_RECOMMEND_ITEM_OPTION_TYPE_B: '200007434',
      REFRESH_RECOMMEND_ITEMS_TYPE_A: '200007430',
      REFRESH_RECOMMEND_ITEMS_TYPE_B: '200007435',
      OVERSEA_SHIPPING_POLICY_KEEP_ORDER: '200008593',
      OVERSEA_SHIPPING_POLICY_RETURN_CART: '200008594',
      OVERSEA_SHIPPING_POLICY_CLOSE_LAYER: '200008595',
      BIZ_CART_EXTENDED_CONFIRM: '200011681',
      BIZ_CART_EXTENDED_CONFIRMED: '200011682',
      BUY_BOX_CARD: {
        All: '200011467',
        SmileDelivery: '200011468',
        ExpressShop: '200011469',
      },
      BUY_BOX_WITH_ADD_CART: {
        All: '200011470',
        SmileDelivery: '200011471',
        ExpressShop: '200011472',
      },
      BUY_BOX_WITH_SELECT_OPTION: {
        All: '200011473',
        SmileDelivery: '200011474',
        ExpressShop: '200011475',
      },
      TOAST_ADDED_BUY_BOX_TO_CART: {
        All: '200011476',
        SmileDelivery: '200011477',
        ExpressShop: '200011478',
      },
      TOOLTIP_RECOMMENDED_COUPON_FOR_BUY_BOX: {
        All: '200011479',
        SmileDelivery: '200011480',
        ExpressShop: '200011481',
      },
      PENDING_BUY_BOX: {
        All: '200011482',
        SmileDelivery: '200011483',
        ExpressShop: '200011484',
      },
    },
  },
  {
    env: {
      tenantType: 'Gmarket',
      deviceType: 'PC',
      languageType: 'Global',
    },
    data: {
      ALL_CART_TAB: '',
      SMILE_DELIVERY_CART_TAB: '',
      EXPRESS_SHOP_CART_TAB: '',
      SELECT_ALL: '200002899',
      DELETE_SELECT: '200002900',
      PRINT_ESTIMATE: '',
      SELECT_ALL_MINISHOP: '200002901',
      GO_MINISHOP: '200002902',
      OVERSEA_SHIPPING: '200002903',
      SELECT_ITEM: '200002904',
      ITEM_IMAGE: '200002905',
      ITEM_NAME: '200002906',
      SUBTRACT_QTY: '200002907',
      EDIT_QTY: '200002908',
      ADD_QTY: '200002909',
      DELETE_CARTUNIT: '200002911',
      COUPON_BOX: '200002910',
      OVERSEA_SHIPPING_GUIDE: '200002912',
      DELETE_UNAVAILABLE_CARTUNIT: '200002916',
      OVERSEA_SHIPPING_SF_GUIDE: '200002915',
      GO_SMILE_DELIVERY_MINISHOP: '200002917',
      GO_EXPRESS_SHOP_MINISHOP: '',
      SMILE_CASH_DETAIL: '',
      GO_ORDER: '200002918',
      GO_ORDER_FLOATING: '',
      GO_TOP: '200002919',
      GO_HOME: '200002920',
      REMOVE_SHIPPING_FEE: '',
      SMILE_FREE_SHIPPING_BANNER: '',
      GO_SMILE_DELIVERY_HOME: '',
      GO_EXPRESS_SHOP_HOME: '',
      SMILE_SHIPPING_ARRIVAL_GUARANTEE: '',
      OVERSEA_SHIPPING_POLICY_KEEP_ORDER: '200008602',
      OVERSEA_SHIPPING_POLICY_RETURN_CART: '200008603',
      OVERSEA_SHIPPING_POLICY_CLOSE_LAYER: '200008604',
    },
  },
  {
    env: {
      tenantType: 'Gmarket',
      deviceType: 'Mobile',
      languageType: 'Global',
    },
    data: {
      ALL_CART_TAB: '',
      SMILE_DELIVERY_CART_TAB: '',
      EXPRESS_SHOP_CART_TAB: '',
      SELECT_ALL: '200002921',
      DELETE_SELECT: '200002922',
      PRINT_ESTIMATE: '',
      SELECT_ALL_MINISHOP: '200002923',
      GO_MINISHOP: '200002924',
      OVERSEA_SHIPPING: '200002934',
      SELECT_ITEM: '200002925',
      ITEM_IMAGE: '200002926',
      ITEM_NAME: '200002926',
      SUBTRACT_QTY: '200002927',
      EDIT_QTY: '200002928',
      ADD_QTY: '200002929',
      DELETE_CARTUNIT: '200002931',
      COUPON_BOX: '200002930',
      OVERSEA_SHIPPING_GUIDE: '',
      DELETE_UNAVAILABLE_CARTUNIT: '200002932',
      OVERSEA_SHIPPING_SF_GUIDE: '200002937',
      GO_SMILE_DELIVERY_MINISHOP: '200002933',
      GO_EXPRESS_SHOP_MINISHOP: '',
      SMILE_CASH_DETAIL: '',
      GO_ORDER: '200002939',
      GO_ORDER_FLOATING: '',
      GO_TOP: '200002938',
      GO_HOME: '200002940',
      REMOVE_SHIPPING_FEE: '',
      SMILE_FREE_SHIPPING_BANNER: '',
      GO_SMILE_DELIVERY_HOME: '',
      GO_EXPRESS_SHOP_HOME: '',
      SMILE_SHIPPING_ARRIVAL_GUARANTEE: '',
      OVERSEA_SHIPPING_POLICY_KEEP_ORDER: '200008599',
      OVERSEA_SHIPPING_POLICY_RETURN_CART: '200008600',
      OVERSEA_SHIPPING_POLICY_CLOSE_LAYER: '200008601',
    },
  },
  {
    env: {
      tenantType: 'Auction',
      deviceType: 'PC',
    },
    data: {
      ALL_CART_TAB: '100001723',
      SMILE_DELIVERY_CART_TAB: '100001724',
      EXPRESS_SHOP_CART_TAB: '100001725',
      SELECT_ALL: '100001726',
      DELETE_SELECT: '100001727',
      PRINT_ESTIMATE: '100001728',
      SELECT_ALL_MINISHOP: '100001729',
      GO_MINISHOP: '100001730',
      SELECT_ITEM: '100001731',
      ITEM_IMAGE: '100001732',
      ITEM_NAME: '100001733',
      SUBTRACT_QTY: '100001734',
      EDIT_QTY: '100001735',
      ADD_QTY: '100001736',
      DELETE_CARTUNIT: '100001737',
      COUPON_BOX: '100001738',
      DELETE_UNAVAILABLE_CARTUNIT: '100001739',
      SMILE_CASH_DETAIL: '100001740',
      GO_ORDER: '100001741',
      GO_ORDER_FLOATING: '100001742',
      GO_TOP: '100001743',
      GO_HOME: '100001744',
      REMOVE_SHIPPING_FEE: '100001756',
      SMILE_FREE_SHIPPING_BANNER: '100001758',
      ONE_CLICK_COUPON: '100002259',
      JOIN_ONE_CLICK_ON_COUPON: '100002261',
      CLOSE_ONE_CLICK_COUPON: '100002262',
      COMPLETE_JOIN_ONE_CLICK_ON_COUPON: '100002263',
      FAIL_JOINING_ONE_CLICK_ON_COUPON: '100002264',
      ONE_CLICK_TERMS_CLUB_ON_COUPON: '100002265',
      ONE_CLICK_TERMS_PERSONAL_ON_COUPON: '100002266',
      ONE_CLICK_TERMS_PERSONAL_INFO_ON_COUPON: '100003879',
      SMILE_SHIPPING_ARRIVAL_GUARANTEE: '100002372',
      JOIN_ONE_CLICK_BIZ_ON_BANNER: '100003024',
      COMPLETE_JOIN_ONE_CLICK_BIZ_ON_BANNER: '100003025',
      JOIN_ONE_CLICK_BIZ_ON_COUPON: '100003029',
      COMPLETE_JOIN_ONE_CLICK_BIZ_ON_COUPON: '100003030',
      CHANGE_LATEST_ADDRESS: '100003696',
      CHANGE_LATEST_ADDRESS_NON_MEMBER: '100003698',
      ADD_LATEST_ADDRESS: '100003700',
      FAST_DELIVERY_CLUB_NUDGING: '100003702',
      FAST_DELIVERY_CLUB_NUDGING_CONFIRM: '100003704',
      CLUB_NUDGING_CLICK: '100004590',
      CLUB_NUDGING_ACTIVED: '100004589',
      SMILE_CLUB_FREE_DELIVERY: '100003881',
      BIZ_CART_EXTENDED_CONFIRM: '100004752',
      BIZ_CART_EXTENDED_CONFIRMED: '100004753',
    },
  },
  {
    env: {
      tenantType: 'Auction',
      deviceType: 'Mobile',
    },
    data: {
      ALL_CART_TAB: '100001783',
      SMILE_DELIVERY_CART_TAB: '100001784',
      EXPRESS_SHOP_CART_TAB: '100001785',
      SELECT_ALL: '100001786',
      DELETE_SELECT: '100001787',
      PRINT_ESTIMATE: '',
      SELECT_ALL_MINISHOP: '100001788',
      GO_MINISHOP: '100001789',
      SELECT_ITEM: '100001790',
      ITEM_IMAGE: '100001791',
      ITEM_NAME: '100001792',
      SUBTRACT_QTY: '100001794',
      EDIT_QTY: '100001795',
      ADD_QTY: '100001796',
      DELETE_CARTUNIT: '100001793',
      COUPON_BOX: '100001797',
      DELETE_UNAVAILABLE_CARTUNIT: '100001798',
      SMILE_CASH_DETAIL: '100001799',
      GO_ORDER: '100001801',
      GO_ORDER_FLOATING: '100001801',
      GO_TOP: '100001800',
      GO_HOME: '100001802',
      REMOVE_SHIPPING_FEE: '100001814',
      SMILE_FREE_SHIPPING_BANNER: '100001816',
      ONE_CLICK_BANNER: '100002225',
      JOIN_ONE_CLICK_ON_BANNER: '100002226',
      CLOSE_ONE_CLICK_BANNER: '100002227',
      COMPLETE_JOIN_ONE_CLICK_ON_BANNER: '100002228',
      FAIL_JOINING_ONE_CLICK_ON_BANNER: '100002229',
      ONE_CLICK_TERMS_CLUB_ON_BANNER: '100002231',
      ONE_CLICK_TERMS_PERSONAL_ON_BANNER: '100002232',
      ONE_CLICK_TERMS_PERSONAL_INFO_ON_BANNER: '100003878',
      ONE_CLICK_COUPON: '100002235',
      JOIN_ONE_CLICK_ON_COUPON: '100002236',
      CLOSE_ONE_CLICK_COUPON: '100002237',
      COMPLETE_JOIN_ONE_CLICK_ON_COUPON: '100002238',
      FAIL_JOINING_ONE_CLICK_ON_COUPON: '100002239',
      ONE_CLICK_TERMS_CLUB_ON_COUPON: '100002240',
      ONE_CLICK_TERMS_PERSONAL_ON_COUPON: '100002241',
      ONE_CLICK_TERMS_PERSONAL_INFO_ON_COUPON: '100003879',
      SMILE_SHIPPING_ARRIVAL_GUARANTEE: '100002373',
      JOIN_ONE_CLICK_BIZ_ON_BANNER: '100003020',
      COMPLETE_JOIN_ONE_CLICK_BIZ_ON_BANNER: '100003022',
      JOIN_ONE_CLICK_BIZ_ON_COUPON: '100003027',
      COMPLETE_JOIN_ONE_CLICK_BIZ_ON_COUPON: '100003028',
      CHANGE_LATEST_ADDRESS: '100003697',
      CHANGE_LATEST_ADDRESS_NON_MEMBER: '100003699',
      ADD_LATEST_ADDRESS: '100003701',
      FAST_DELIVERY_CLUB_NUDGING: '100003703',
      FAST_DELIVERY_CLUB_NUDGING_CONFIRM: '100003705',
      CLUB_NUDGING_CLICK: '100004590',
      CLUB_NUDGING_ACTIVED: '100004589',
      SMILE_CLUB_FREE_DELIVERY: '100003880',
      BIZ_CART_EXTENDED_CONFIRM: '100004754',
      BIZ_CART_EXTENDED_CONFIRMED: '100004755',
    },
  },
  {
    env: {
      tenantType: 'G9',
    },
    data: {
      SELECT_ALL: '300001502',
      DELETE_SELECT: '300001503',
      SELECT_ITEM: '300001504',
      ITEM_IMAGE: '300001507',
      ITEM_NAME: '300001505',
      SUBTRACT_QTY: '300001508',
      EDIT_QTY: '300001509',
      ADD_QTY: '300001510',
      DELETE_CARTUNIT: '300001506',
      DELETE_UNAVAILABLE_CARTUNIT: '300001512',
      RESELECT_UNAVAILABLE_CARTUNIT: '300001513',
      SMILE_CASH_DETAIL: '300001514',
      GO_ORDER_CARTUNIT: '300001511',
      GO_ORDER: '300001515',
      GO_ORDER_FLOATING: '300001517',
      GO_HOME: '300001518',
      GO_FAVORITE: '300001519',
      SHIPPING_FEE_PROMOTION: '300001516',
      SMILE_SHIPPING_ARRIVAL_GUARANTEE: '',
    },
  },
]

const areaCodes: Partial<AreaCodes> =
  dataPicker<Partial<AreaCodes>>(areaCodeSources, siteEnv) || {}

export default areaCodes
