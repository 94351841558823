import React from 'react'
import CartToolbarContainer from '~/cart/gmarket/gl/common/containers/CartToolbarContainer'
import { __ } from '~/lib/i18n'

const CartHeader = ({
  isMobile,
  currentCartCount,
}: {
  isMobile: boolean
  currentCartCount: number
}): JSX.Element => {
  return (
    <div id="cart_header">
      <div className="cart_header_wrapper">
        <div className="cart_header">
          <div className="inner_cont">
            <h3 className="title">{__('ESCROW_BASKET_TEXT_45', '장바구니')}</h3>
          </div>
        </div>
        {!isMobile && currentCartCount > 0 && <CartToolbarContainer />}
      </div>
    </div>
  )
}

export default CartHeader
