import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CartBodyContainer from '~/cart/gmarket/gl/common/containers/CartBodyContainer'
import CartError from '~/cart/gmarket/gl/common/containers/CartError'
import CartHeaderContainer from '~/cart/gmarket/gl/common/containers/CartHeaderContainer'
import ProcessingContainer from '~/cart/gmarket/gl/common/containers/ProcessingContainer'
import LayerListContainer from '~/cart/gmarket/gl/pc/containers/Layer/LayerListContainer'
import { init } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import { isFirstLoaded } from '~/cart/modules/view/reducer'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import Footer from '~/lib/components/gmarket/gl/pc/Footer'
import Header from '~/lib/components/gmarket/gl/pc/Header'
import { ERROR_HANDLER_DO_NOTHING } from '~/lib/default-error-handlers'
import logRender from '~/lib/log-render'

const App = (): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()
  useEffect(() => {
    dispatch(init()).catch(ERROR_HANDLER_DO_NOTHING())
  }, [dispatch])

  const firstLoaded = useSelector((state: RootState) =>
    isFirstLoaded(state.view),
  )
  const firstLoadFailed = useSelector(
    (state: RootState) => state.view.firstLoadFailed,
  )

  return (
    <>
      <Header />
      <div id="container">
        <div id="wrap" className="wrap">
          <ProcessingContainer />
          <div id="content" className="cart__wrap">
            <CartHeaderContainer />
            {firstLoaded && !firstLoadFailed && <CartBodyContainer />}
            {firstLoadFailed && <CartError />}
          </div>
        </div>
      </div>
      <LayerListContainer />
      <Footer />
    </>
  )
}

export default App
