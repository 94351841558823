import React from 'react'
import { ItemOption } from '~/cart/modules/cart/types'
import { currencyAmount } from '~/lib/formatter'

const getOptionText = (option: ItemOption): string => {
  if (option.optionType === 'Calculation') {
    return option.optionEntries.reduce((result, entry) => {
      if (result) {
        return `${result} x ${entry.name} (${entry.value}${option.calculationUnit})`
      } else {
        return `${entry.name} (${entry.value}${option.calculationUnit})`
      }
    }, '')
  } else {
    return option.optionEntries.reduce((result, entry) => {
      if (result) {
        return result + ` + ${entry.value}`
      } else {
        return entry.value
      }
    }, '')
  }
}

const CartUnitOption = ({ option }: { option: ItemOption }): JSX.Element => {
  if (option.optionEntries.length === 1) {
    const firstOption = option.optionEntries[0]
    return (
      <li>
        <span className="option_name">{firstOption.name}</span>
        <span className="option_value">
          {firstOption.value}{' '}
          {option.optionPrice !== 0 &&
            `(${option.optionPrice > 0 ? '+' : '-'}₩${currencyAmount(
              Math.abs(option.optionPrice),
            )})`}
        </span>
      </li>
    )
  } else if (option.optionEntries.length > 1) {
    return (
      <li>
        <span className="option_value">
          {getOptionText(option)}{' '}
          {option.optionPrice !== 0 &&
            `(${option.optionPrice > 0 ? '+' : '-'}₩${currencyAmount(
              Math.abs(option.optionPrice),
            )})`}
        </span>
      </li>
    )
  } else {
    return <></>
  }
}

export default CartUnitOption
