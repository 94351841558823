// These must be the first lines in src/index.js
// organize-imports-ignore
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'allsettled-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import configureStore from '~/cart/modules/configure-store'
import domains from '~/data/domains'
import PageInitializer from '~/lib/page-initializer'
import App from './App'
import siteEnv from '~/lib/site-env'
import ExchangeContextWrapper from '~/lib/components/ExchangeContextWrapper'
import { __ } from '~/lib/i18n'

const { store, history } = configureStore()
const cssList = [
  `${domains.SCRIPT}/pc/css/application/${
    siteEnv.languageType === 'English' ? 'en' : 'cn'
  }/cart/app.css?timestamp=${new Date().getTime()}`,
]

const scriptList: string[] = [
  `${domains.SCRIPT}/pc/js/application/${
    siteEnv.languageType === 'English' ? 'en' : 'cn'
  }/cart/app.js`,
]

PageInitializer.init(
  `Gmarket - ${__('ESCROW_BASKET_TEXT_45', '장바구니')}`,
  'service__cart',
  cssList,
  scriptList,
).then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Router history={history}>
          <ExchangeContextWrapper>
            <App />
          </ExchangeContextWrapper>
        </Router>
      </Provider>
    </React.StrictMode>,
    document.getElementById('wrapper'),
  )
})
