import React, { useContext } from 'react'
import { ExchangeSymbols } from '~/data/consts'
import { ExchangeContext } from '~/lib/components/ExchangeContextWrapper'
import { currencyAmount } from '~/lib/formatter'
import { ExchangeType } from '~/types/enums'

const ExchangeAmount = (props: {
  krwAmount: number
  format?: string
  currency?: ExchangeType
}): JSX.Element => {
  const context = useContext(ExchangeContext)
  if (!context) {
    return <>{currencyAmount(props.krwAmount)}</>
  }
  const format = props.format || '{0}{1}'
  const price =
    Math.ceil(Math.abs((props.krwAmount * 100) / context.exchangeRate)) /
    (context.currency === 'JPY' ? 1 : 100)

  const symbol = ExchangeSymbols[props.currency || context.currency] || '₩'
  return (
    <>{format.replace('{0}', symbol).replace('{1}', currencyAmount(price))}</>
  )
}

export default ExchangeAmount
