import React from 'react'
import { useSelector } from 'react-redux'
import CartHeader from '~/cart/gmarket/gl/common/components/CartHeader/CartHeader'
import { getCurrentCartUnitList } from '~/cart/modules/cart/reducer'
import { RootState } from '~/cart/modules/reducers'
import logRender from '~/lib/log-render'

const CartHeaderContainer = (): JSX.Element => {
  logRender()

  const isMobile = useSelector((state: RootState) => state.view.isMobile)

  const currentCartCount = useSelector(
    (state: RootState) => getCurrentCartUnitList(state.cart).length,
  )

  return <CartHeader isMobile={isMobile} currentCartCount={currentCartCount} />
}

export default CartHeaderContainer
