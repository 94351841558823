import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsAppliedCouponQuantityChanged } from '~/cart/modules/cart/actions'
import { getIsAppliedCouponQuantityChanged } from '~/cart/modules/cart/reducer'
import { RootState } from '~/cart/modules/reducers'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import { __ } from '~/lib/i18n'
import logRender from '~/lib/log-render'

const SHOWING_SECONDS = 3

const LayerCouponReApplyNoti = (): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const [isShowing, setShowing] = useState(false)

  const isAppliedCouponQuantityChanged = useSelector((state: RootState) =>
    getIsAppliedCouponQuantityChanged(state.cart),
  )

  // 수량변경하려고 할때, 적용된 쿠폰이 있는 경우
  // 닫을때 timeout clear 해줘야함
  useEffect(() => {
    if (isAppliedCouponQuantityChanged) {
      const key1 = window.setTimeout(() => {
        setShowing(true)
      })

      const key2 = window.setTimeout(() => {
        setShowing(false)
        dispatch(setIsAppliedCouponQuantityChanged(false))
      }, SHOWING_SECONDS * 1000)

      return (): void => {
        window.clearTimeout(key1)
        window.clearTimeout(key2)
      }
    }
  }, [isAppliedCouponQuantityChanged])

  return (
    <div
      className={`box__toast-popup ${
        isShowing ? 'box__toast-popup--active' : ''
      }`}
      role="alert"
      aria-live="polite"
      style={
        isShowing
          ? { visibility: 'visible', opacity: 1 }
          : { visibility: 'hidden', opacity: 0 }
      }
    >
      <p className="text__message">
        {__('ESCROW_BASKET_TEXT_259', '쿠폰을 다시 적용해 주세요.')}
      </p>
    </div>
  )
}

export default React.memo(LayerCouponReApplyNoti)
