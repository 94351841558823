import { parseInt } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CartUnitQtyLoading from '~/cart/gmarket/gl/common/components/CartItemList/CartUnitQtyLoading'
import { CartUnit } from '~/cart/modules/cart/types'
import { setCartUnitQuantityComplex } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import areaCodes from '~/data/areaCodes'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import CustomInput from '~/lib/components/CustomInput'
import { __ } from '~/lib/i18n'
import logRender from '~/lib/log-render'

const CartUnitQtyControlContainer = ({
  cartUnit,
}: {
  cartUnit: CartUnit
}): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const [quantity, setQuantity] = useState(cartUnit.quantity.toString())
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setQuantity(cartUnit.quantity.toString())
  }, [cartUnit.quantity])

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setQuantity(e.currentTarget.value)
  }

  const setCartUnitQuanity = useCallback(
    async (triggerElement: HTMLElement, qty: number): Promise<void> => {
      setIsLoading(true)
      const result = await dispatch(
        setCartUnitQuantityComplex(triggerElement, cartUnit.cartUnitId, qty),
      )
      if (!result) {
        setQuantity(cartUnit.quantity.toString())
      }
      setIsLoading(false)
    },
    [
      cartUnit.cartUnitId,
      cartUnit.item.itemNo,
      cartUnit.partnershipCode,
      cartUnit.quantity,
      dispatch,
    ],
  )

  const onBlurInput = (e: React.FocusEvent<HTMLInputElement>): Promise<void> =>
    setCartUnitQuanity(e.currentTarget, parseInt(quantity))

  const onClickChangeButton =
    (qty: number) =>
    (e: React.MouseEvent<HTMLButtonElement>): Promise<void> =>
      setCartUnitQuanity(e.currentTarget, qty)

  return (
    <div className={`section item_qty ${isLoading ? 'case_loading' : ''}`}>
      <b className="for_a11y">{__('ESCROW_BASKET_TEXT_89', '상품수량')}</b>
      <div className="item_qty_wrap">
        <CustomInput
          className="item_qty_count"
          id={`qty-${cartUnit.cartUnitId}`}
          type="number"
          min="1"
          max="100"
          pattern="\d*"
          title={__('ESCROW_BASKET_TEXT_89', '상품수량')}
          value={quantity.toString()}
          onChange={onChangeInput}
          onBlur={onBlurInput}
          onlyNumber={true}
          data-montelena-acode={areaCodes.EDIT_QTY}
        />
        <button
          className="btn_plus sprite__cart"
          onClick={onClickChangeButton(cartUnit.quantity + 1)}
          data-montelena-acode={areaCodes.ADD_QTY}
        >
          <i className="icon sp_cart btn_qty_plus">
            <span className="for_a11y">
              {__('ESCROW_BASKET_TEXT_90', '상품 수 1 증가')}
            </span>
          </i>
        </button>
        <button
          className="btn_minus sprite__cart"
          onClick={onClickChangeButton(cartUnit.quantity - 1)}
          data-montelena-acode={areaCodes.SUBTRACT_QTY}
        >
          <i className="icon sp_cart btn_qty_minus">
            <span className="for_a11y">
              {__('ESCROW_BASKET_TEXT_91', '상품 수 1 감소')}
            </span>
          </i>
        </button>
        <CartUnitQtyLoading />
      </div>
    </div>
  )
}

export default CartUnitQtyControlContainer
