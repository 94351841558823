import React from 'react'
import { useSelector } from 'react-redux'
import LayerCouponReApplyNoti from '~/cart/gmarket/gl/common/containers/Layer/LayerCouponReApplyNoti'
import LayerContainer from '~/cart/gmarket/gl/pc/containers/Layer/LayerContainer'
import { RootState } from '~/cart/modules/reducers'
import { getLayers } from '~/cart/modules/view/reducer'
import logRender from '~/lib/log-render'

const LayerListContainer = (): JSX.Element => {
  logRender()

  const layers = useSelector(
    (state: RootState) => getLayers(state.view),
    (left, right) => {
      return (
        left.length === right.length &&
        left.every((layer) => right.some((x) => x.key === layer.key))
      )
    },
  )

  return (
    <>
      {layers.map((layer) => {
        return <LayerContainer key={layer.key} layer={layer} />
      })}
      <LayerCouponReApplyNoti />
    </>
  )
}

export default LayerListContainer
