/* eslint-disable @typescript-eslint/no-unused-vars */

// HINT 외부와 연동하여 사용하는 경우만 enum 사용 그 외에는 모두 Union type 으로 사용한다

// region enums
export enum DeliveryNameType {
  Unknown = -1,
  Prev = 0,
  Custom = 1,
  Home = 2,
  School = 3,
  Work = 4,
}

export enum ShippingRequestType {
  // 미설정
  Empty = 0,
  // 직접 수령하겠습니다.
  DirectReceive = 1,
  // 배송 전 연락바랍니다.
  ContactMe = 2,
  // 부재 시 경비실에 맡겨주세요.
  SecurityOffice = 3,
  // 부재 시 문 앞에 놓아주세요.
  Door = 4,
  // 부재 시 택배함에 넣어주세요.
  DeliveryBox = 5,
  // 직접 입력
  Etc = 6,
}
export enum FastDeliveryLocationType {
  // 미설정
  Unknown = 'Unknown',
  // 문 앞
  Door = 'Door',
  // 경비실
  SecurityOffice = 'SecurityOffice',
  // 택배함
  DeliveryBox = 'DeliveryBox',
  // 기타 수령장소
  Etc = 'Etc',
}
/**
 * https://wiki.ebaykorea.com/display/SPG/DoSmilePageCheckOut 참조
 */
export enum EnumSmilePayPageType {
  PaymentAuth = '3', // 결제 인증
  AccountBalanceSettings = '7', // 대기잔고 설정,
  AccountSettings = '12', // 나의 정보 설정
  AccountSelfAuth = '18', // 본인 인증,
  AccountRegister = '19', // 회원 가입
  AccountLogin = '20', // 회원 로그인
  MemberAddressSettings = '21', // 회원 배송지 관리
  PaymentMethodRegistration = '22', // 결제수단 등록
  PaymentMethodSettings = '23', // 결제 수단 관리
  PaymentMethodSettings2 = '24', // 결제 인증 수단 관리
  SmilePayHistory = '25', // 스마일페이 내역
  SmilePayHome = '26', // 스마일페이 홈
  SmileCashExchange = '29', // 스마일캐시 전환
  SmilePayCardPointLinkageAgree = '33', // 스마일페이 카드 포인트 연동 동의
}

export enum SmilePayUserType {
  Member = 1, //New SmilePay 가입자
  NonMemberMigrationNeeded = 2, //New SmilePay 미가입자 (마이그레이션 대상)
  NonMember = 3, //New SmilePay 미가입자 (마이그레이션 비대상)
}

export enum SmilePayCashReceiptType {
  Unknown = 0,
  Personal = 1, // 개인소득공제
  Corp = 2, // 사업자지출증빙
}

export enum SmilePayCashReceiptIssueType {
  Unknown = 0,
  CellPhoneNumber = 1, // 휴대폰번호
  CardNumber = 2, // 현금영수증 카드 번호
  CorpNumber = 3, // 사업자번호
}

export enum EnumSmilePayStatus {
  OK = 0, // 정상
  Expired = 1, // 유효기간 만료(카드)
  Impossible = 2, // 사용 불가
  Expired2 = 3, // 유효기간 만료(휴대폰)
  Inspection = 4, //정기점검
}

export enum SmilePayInstallmentExposeType {
  CompanyCard = 'C', // 법인카드
  CheckCard = 'M', // 체크카드
}

export enum EnumSmilePayPaymentMethod {
  Default = 0, // 결제수단 등록 UI 모두 노출
  Card = 1, // 결제수단 등록 UI 카드만 노출
  Cash = 2, // 결제수단 등록 UI 현금만 노출
  Mobile = 3, // 결제수단 등록 UI 휴대폰만 노출
  EPrepaid = 4,
}

export enum EnumSmileCashTxnType {
  Unknown = 0,
  // 이벤트 우선
  EventFirst = 1,
  // 현금성 우선
  CashFirst = 2,
  // 현금성 만
  OnlyCash = 3,
  // 이벤트성 만
  OnlyEvent = 4,
  // 현금성 우선 마이너스 가능
  CashFirstMinusEnable = 5,
  // 이벤트성 우선 마이너스 가능
  EventFirstMinusEnable = 6,
  // 출금 가능한 현금성 만
  OnlyPayoutEnableCash = 7,
}

export enum EnumSmilePointTxnType {
  // Unknown
  Unknown = 0,
  // 이벤트성만 ("B004")
  EventOnly = 1,
  // 이벤트성 우선 마이너스 가능 ("B006")
  EventFirst = 2,
  // CS차감 ("B010")
  CSEventOnly = 3,
  // 이벤트성 우선 마이너스 가능 ("B012")
  CSEventFirst = 4,
}

// 요건 예외적으로 주소가 간략하게 처리되도록 enum 사용
export enum EnumGatewayType {
  NormalStaticPage,
  SmallStaticPage,
  SmileCardIntro,
  SmilePayNotice,
  ExpressShop,
  MPIGateway,
  ISPGateway,
  EKPGMobileGateway,
  KBAppCardGateway,
  EcouponSelfAuthGateway,
  SmilePayGateway,
  SmileClubGateway,
  SmileServiceGateway,
  FindAddress,
  AddressBook,
  JoinSmileCard,
  ForeignCardGateway,
  UnionPayGateway,
  PaypalGateway,
  AlipayGateway,
  AmorePacific,
}
// endregion enums

// region union types

export type CartTabType = 'All' | 'SmileFresh' | 'SmileDelivery' | 'ExpressShop'

export type CouponUnusableType =
  | 'UNKNOWN'
  | 'USABLE'
  | 'ADMIN_SETTING'
  | 'PARTNERSHIP'
  | 'NONMEMBER'
  | 'USED_ITEM'
  | 'ECOUPON_ITEM'
  | 'MONEY_CATEGORY'
  | 'SELLER_LIMITED'
  | 'ITEM_LIMITED'
  | 'WON1_ITEM'

export type TenantType = 'Unknown' | 'Gmarket' | 'Auction' | 'G9'
export type LanguageType = 'Korean' | 'English' | 'Chinese'

export type MemberType = 'Unknown' | 'SimpleMember' | 'NonMember' | 'Member'

export type CheckoutPageType =
  // 일반
  | 'General'
  // 이쿠폰
  | 'Ecoupon'
  // 이쿠폰 본인인증
  | 'EcouponAuth'
  // 배달
  | 'FoodDelivery'
  // 방문수령
  | 'SelfReceive'
  // 추가해외배송비
  | 'AdditionalOverseaShippingFee'
  // 타이어 장착
  | 'Mount'
  | 'Contents'
  | 'Event'
  // 당일배송
  | 'ExpressShop'
  | 'Reservation'
  //가입상품
  | 'JoinItem'
  //렌탈상품
  | 'Rental'
  //판매자 예치금 상품 - G마켓에서만
  | 'SellerDeposit'

export type OrderCompletePageType =
  // 일반
  | 'General'
  // 이쿠폰
  | 'Ecoupon'
  // 배달
  | 'FoodDelivery'
  // 방문수령
  | 'SelfReceive'
  // 추가해외배송비
  | 'AdditionalOverseaShippingFee'
  // 타이어 장착
  | 'Mount'
  | 'Contents'
  | 'Event'
  // 당일배송
  | 'ExpressShop'
  | 'Reservation'
  //가입상품
  | 'JoinMobile'
  | 'JoinEtc'
  //판매자 예치금 상품 - G마켓에서만
  | 'SellerDeposit'
  // 가짜 주문완료 - 알리페이에서 사용
  | 'FakeComplete'

export type CustomsClearanceType = 'Unknown' | 'CustomsIdNumber'

export type PersonalAgreementsType =
  | 'CollectingPersonalInfo' // 개인정보 수집 및 이용동의
  | 'ProvidingPersonalInfo' // 개인정보 제3자 제공동의
  | 'EbayBuyerAgreement' // G마켓/옥션/G9 구매회원 약관 동의
  | 'ETradeAgreement' // 전자금융거래 약관 동의
  | 'CustomsClearance' // 해외직구상품 통관정보 수집 동의
  | 'ProvidingCustomsClearance' // 해외직구상품 통관정보 판매자 제공 동의
  | 'SafeNumberAgreement' // 안심번호 사용약관 동의(사용하는곳 있나..?)
  | 'TaxId' // 해외배송 통관정보(Tax ID)
  | 'TaiwanEntryAgreement' // 대만 세관신고정보 수집이용 및 국외이전 동의
  | 'TaiwanSeparateDeclarationAgreement' // 대만 세관신고정보 별도신고동의
  | 'PaypalOneClickPay'

export type PaymentMethodType =
  | 'Base' // 주결제수단
  | 'Sub' // 부결제수단(스마일캐시, 포인트)

export type PaymentLargeMethodType =
  | 'Unknown'
  | 'SmilePay' // 스마일페이
  | 'Normal' // 일반결제
  | 'SmileCash'
  | 'Etc' // 그 외 보조결제수단
// | 'SmilePayAutoCharge' // 스마일페이 충전결제 -> 는 스마일페이와 함께 취급한다

export type PaymentMediumMethodType =
  | 'Unknown'
  | 'SmilePayCreditCard'
  | 'SmilePayBank'
  | 'SmilePayMobile'
  // | 'SmilePayEPrepaid' // 선불전자결제수단 (Toss 같은것) (주문서에서는 중분류를 은행과 동일하게 취급)
  // | 'SmilePayAutoCharge' // 스마일페이 충전결제 (주문서에서는 중분류를 은행과 동일하게 취급)
  | 'CreditCard'
  | 'VirtualAccount' // 옥션 서비스결제(1원결제)에도 사용.
  | 'Mobile'
  | 'ForeignCreditCard'
  | 'Paypal'
  | 'AliPay'
  | 'SmileCash'
  | 'SmilePoint'
  | 'BenepiaPoint'
  | 'G9Cash'
  | 'SellerDirect'
  | 'PayOnDelivery'

export type PaymentSmallMethodType =
  | 'Unknown'
  // region 스마일페이 카드
  // |'SmilePayCardGangwon' // 강원은행카드(신한)
  | 'SmilePayCardGwangju' // 광주은행카드
  | 'SmilePayCardHana' // 하나카드
  | 'SmilePayCardKEB' // 하나(외환)카드
  | 'SmilePayCardJeonbuk' // 전북은행카드
  | 'SmilePayCardJeju' // 제주은행카드
  | 'SmilePayCardNH' // NH농협카드
  | 'SmilePayCardKB' // KB국민카드
  | 'SmilePayCardSuhyup' // 수협은행카드
  | 'SmilePayCardWoori' // 우리카드
  | 'SmilePayCardCiti' // 씨티카드
  | 'SmilePayCardBC' // 비씨카드
  | 'SmilePayCardHyundai' // 현대카드
  | 'SmilePayCardShinhan' // 신한카드
  | 'SmilePayCardSamsung' // 삼성카드
  // |'SmilePayCardSB' // 저축은행체크카드
  | 'SmilePayCardLotte' // 롯데카드
  | 'SmilePayCardChai' // 차이카드
  | 'SmilePayCardSmileT0' // 스마일카드T1
  | 'SmilePayCardSmileT1' // 스마일카드T2
  | 'SmilePayCardSmileT2' // 스마일카드T3
  | 'SmilePayCardSmileT3' // 스마일카드T4
  | 'SmilePayCardKakao' // 카카오뱅크카드
  | 'SmilePayCardDamoaWoori' // 다모아우리카드
  | 'SmilePayCardShinhyup' // 신협카드
  // endregion

  // region 스마일페이 은행
  | 'SmilePayBankKDB' // KDB산업은행
  | 'SmilePayBankIBK' // 기업은행
  | 'SmilePayBankKB' // 국민은행
  | 'SmilePayBankKEB' // 외환은행
  | 'SmilePayBankSuhyup' // 수협은행
  | 'SmilePayBankNH' // 농협은행
  | 'SmilePayBankWoori' // 우리은행
  | 'SmilePayBankSC' // SC은행
  | 'SmilePayBankCiti' // 씨티은행
  | 'SmilePayBankDaegu' // 대구은행
  | 'SmilePayBankBusan' // 부산은행
  | 'SmilePayBankGwangju' // 광주은행
  | 'SmilePayBankJeju' // 제주은행
  | 'SmilePayBankJeonbuk' // 전북은행
  | 'SmilePayBankGyeongnam' // 경남은행
  | 'SmilePayBankMG' // 새마을금고
  | 'SmilePayBankShinhyup' // 신협
  | 'SmilePayBankPost' // 우체국은행
  | 'SmilePayBankHana' // 하나은행
  | 'SmilePayBankShinhan' // 신한은행
  | 'SmilePayBankKakao' // 카카오뱅크
  | 'SmilePayBankNHIS' // 농협투자증권
  // endregion
  | 'SmilePayEPrepaidToss' // 토스
  | 'SmilePayAutoCharge' // 스마일페이 충전결제

  // region 스마일페이 휴대폰
  | 'SmilePayMobileSKT' // SKT
  | 'SmilePayMobileKT' // KT
  | 'SmilePayMobileLGU' // LG U+
  | 'SmilePayMobileMVNO' // 알뜰폰
  // endregion

  // region 일반결제 카드
  | 'CardGangwon' // 강원은행카드(신한)
  | 'CardGwangju' // 광주은행카드
  | 'CardHana' // 하나카드
  | 'CardHanaCardMall' // 하나카드(카드몰)
  | 'CardHanaBC' // 하나BC카드
  | 'CardKEB' // 하나(외환)카드
  | 'CardJeonbuk' // 전북은행카드
  | 'CardJeju' // 제주은행카드
  | 'CardNH' // NH농협카드
  | 'CardNHBC' // NH농협BC카드
  | 'CardKB' // KB국민카드
  | 'CardKBCardMall' // KB국민카드(카드몰)
  | 'CardSuhyup' // 수협은행카드
  | 'CardWoori' // 우리카드
  | 'CardCiti' // 씨티카드
  | 'CardBC' // 비씨카드
  | 'CardHyundai' // 현대카드
  | 'CardShinhan' // 신한카드
  | 'CardShinhanShopping' // 신한카드(신한쇼핑)
  | 'CardSamsung' // 삼성카드
  | 'CardSamsungCardMall' // 삼성카드(카드몰)
  | 'CardSB' // 저축은행체크카드
  | 'CardLotte' // 롯데카드
  | 'CardKakao' // 카카오뱅크카드
  | 'CardGPlusHana' // G플러스 하나카드
  | 'CardGPlusWoori' // G플러스 우리카드
  // endregion

  // region 일반결제 은행
  | 'BankKDB' // KDB산업은행
  | 'BankIBK' // 기업은행
  | 'BankKB' // 국민은행
  | 'BankKEB' // 외환은행
  | 'BankSuhyup' // 수협은행
  | 'BankNH' // 농협은행
  | 'BankWoori' // 우리은행
  | 'BankSC' // SC은행
  | 'BankCiti' // 씨티은행
  | 'BankDaegu' // 대구은행
  | 'BankBusan' // 부산은행
  | 'BankGwangju' // 광주은행
  | 'BankJeju' // 제주은행
  | 'BankJeonbuk' // 전북은행
  | 'BankGyeongnam' // 경남은행
  | 'BankMG' // 새마을금고
  | 'BankShinhyup' // 신협
  | 'BankPost' // 우체국은행
  | 'BankHana' // 하나은행
  | 'BankShinhan' // 신한은행
  | 'BankKakao' // 카카오뱅크
  | 'BankNHIS' // 농협투자증권
  // endregion

  // region 일반결제 휴대폰
  | 'MobileSKT' // SKT
  | 'MobileKT' // KT
  | 'MobileLGU' // LG U+
  | 'MobileMVNO' // 알뜰폰
  // endregion

  // region 일반결제 해외카드
  | 'ForeignCardUnionPayKB' // 은련카드(모바일)
  | 'ForeignCardUnionPay' // 은련카드(PC)
  | 'ForeignCardVisa' // VISA카드
  | 'ForeignCardMaster' // 마스터카드
  | 'ForeignCardJCB' // JCB
  | 'ForeignCardAmex' // 아메리칸익스프레스
  // endregion

  // region etc
  | 'Paypal' // 페이팔
  | 'AliPay' // 알리페이
  | 'SmileCash' // 스마일캐시
  | 'SmilePoint' // 스마일포인트
  | 'BenepiaPoint' // 베네피아 포인트
  | 'G9Cash' // G9캐시
  | 'SellerDirect'
  | 'PayOnDelivery'
// endregion

/**
 * 결제수단 세분류
 */
export type PaymentDetailMethodType =
  | 'MPI'
  | 'ISP'
  | 'KBAppCard'
  | 'KakaoBankCard'
  | 'Voucher'
  | 'YoungVoucher'
  | 'OnnuriGiftCard'

export type AuthType = 'MPI' | 'ISP' | 'KBAppCard'

export type SmileClubMemberType =
  | 'Unknown'
  | 'Waiting'
  | 'Free'
  | 'Purchase'
  | 'Restricted'
  | 'Withdrawal'

export type StaticPageType =
  | 'Unknown'
  | 'Test'
  | 'SFExpressNotice'
  | 'SmileClub'

export type CashReceiptType =
  | 'Personal' // 개인소득공제
  | 'Corp' // 사업자지출증빙

export type CashReceiptIssueType =
  | 'CellPhoneNumber' // 휴대폰번호
  | 'CardNumber' // 현금영수증 카드 번호
  | 'CorpNumber' // 사업자번호

export type EPrepayReceiptType =
  | 'Personal' // 개인소득공제
  | 'Corp' // 사업자지출증빙

// 옥션용 쿠폰 타입
export type GroupCouponType = 'Unknown' | 'BuyerCoupon' | 'SuperCoupon'

// 지마켓용 쿠폰 타입
export type UnitCouponType = 'Unknown' | 'BuyerCoupon' | 'SuperCoupon'

// 할인 계산 유형 : 정률, 정액
export type DiscountUnitType = 'Unknown' | 'Rate' | 'Amount'

export type ReservationType =
  | 'Unknown'
  | 'DomesticHotel'
  | 'OverseaHotel'
  | 'DomesticTour'
  | 'OverseaTour'
  | 'RentCar'

export type GenderType = 'Unknown' | 'Male' | 'Female'

export type SmileCashbackType =
  | 'Unknown'
  | 'Item'
  | 'Seller'
  | 'Cart'
  | 'SmilePay'
  | 'SmileClub'
  | 'SmileCard'
  | 'NewSmileCard'
  | 'SmileCardPremium'
  | 'AutoCharge'
  | 'AutoChargeClub'
  | 'ClubDay'
  | 'SsgPoint'
  | 'MixedSsgPoint'

export type UnitCashbackType = 'Unknown' | 'Item' | 'Pay' | 'Seller' | 'Cart'

export type CartCashbackType = 'Unknown' | 'SmileDelivery' | 'Cart'

export type ExpressShopType =
  | 'Unknown'
  | 'Homeplus'
  | 'GSFresh'
  | 'LotteSuper'
  | 'LotteMart'

export type SmileDeliveryShippingType =
  | 'Unknown'
  | 'Normal'
  | 'MidNightCutOff'
  | 'MidNightCutOffNonLoggedIn'
  | 'Holiday'
  | 'HolidayNonLoggedIn'
  | 'Dawn'
  | 'DawnNudge'

export type ShippingCountryType =
  | 'Unknown'
  | 'Afghanistan'
  | 'Albania'
  | 'Algeria'
  | 'Andorra'
  | 'Angola'
  | 'Anguilla'
  | 'AntiguaAndBarbuda'
  | 'ArabEmirates'
  | 'Argentina'
  | 'Armenia'
  | 'Aruba'
  | 'Australia'
  | 'Austria'
  | 'Azerbaijan'
  | 'Bahrain'
  | 'Bangladesh'
  | 'Barbados'
  | 'Belarus'
  | 'Belgium'
  | 'Belize'
  | 'Benin'
  | 'Bermuda'
  | 'Bhutan'
  | 'Bolivia'
  | 'BosniaAndHerzegovina'
  | 'Botswana'
  | 'Brazil'
  | 'BritishVirginIslands'
  | 'BruneiDarussalam'
  | 'Bulgaria'
  | 'BurkinaFaso'
  | 'Burundi'
  | 'Cambodia'
  | 'Cameroon'
  | 'Canada'
  | 'CapeVerde'
  | 'CaymanIslands'
  | 'CentralAfricanRepublic'
  | 'Chad'
  | 'Chile'
  | 'China'
  | 'Colombia'
  | 'Comoros'
  | 'Congo'
  | 'CookIslands'
  | 'CostaRica'
  | 'Croatia'
  | 'Cuba'
  | 'Cyprus'
  | 'CzechRepublic'
  | 'DemocraticRepublicOfTheCongo'
  | 'Denmark'
  | 'Djibouti'
  | 'Dominica'
  | 'DominicanRepublic'
  | 'Ecuador'
  | 'Egypt'
  | 'ElSalvador'
  | 'Eritrea'
  | 'Estonia'
  | 'ETC'
  | 'Ethiopia'
  | 'FalklandIslands'
  | 'FaroeIslands'
  | 'Fiji'
  | 'Finland'
  | 'France'
  | 'FrenchGuiana'
  | 'FrenchPolynesia'
  | 'Gabon'
  | 'Georgia'
  | 'Germany'
  | 'Ghana'
  | 'Gibraltar'
  | 'Greece'
  | 'Greenland'
  | 'Grenada'
  | 'Guadeloupe'
  | 'GuamIsland'
  | 'Guatemala'
  | 'Guinea'
  | 'Guyana'
  | 'Haiti'
  | 'Honduras'
  | 'HongKong'
  | 'Hungary'
  | 'Iceland'
  | 'India'
  | 'Indonesia'
  | 'Iran'
  | 'Iraq'
  | 'Ireland'
  | 'Israel'
  | 'Italy'
  | 'Jamaica'
  | 'Japan'
  | 'Jordan'
  | 'Kazakhstan'
  | 'Kenya'
  | 'Kiribati'
  | 'Kuwait'
  | 'Kyrgyzstan'
  | 'LaoPeoplesDemocraticRepublic'
  | 'Latvia'
  | 'Lebanon'
  | 'Lesotho'
  | 'Liberia'
  | 'Libya'
  | 'Liechtenstein'
  | 'Lithuania'
  | 'Luxembourg'
  | 'Macau'
  | 'Macedonia'
  | 'Madagascar'
  | 'Malawi'
  | 'MalaysiaWest'
  | 'Maldives'
  | 'Mali'
  | 'Malta'
  | 'Manicronesia'
  | 'MarshallIslands'
  | 'Martinique'
  | 'Mauritania'
  | 'Mauritius'
  | 'Mayotte'
  | 'Mexico'
  | 'Moldova'
  | 'Monaco'
  | 'Mongolia'
  | 'Montenegro'
  | 'Montserrat'
  | 'Morocco'
  | 'Mozambique'
  | 'Myanma'
  | 'Namibia'
  | 'Nauru'
  | 'Nepal'
  | 'Netherlands'
  | 'NetherlandsAntilles'
  | 'NewCaledonia'
  | 'NewZealand'
  | 'Nicaragua'
  | 'Niger'
  | 'Nigeria'
  | 'Niue'
  | 'NorfolkIsland'
  | 'Norway'
  | 'Oman'
  | 'Pakistan'
  | 'Palau'
  | 'Panama'
  | 'PapuaNewGuinea'
  | 'Paraguay'
  | 'Peru'
  | 'Philippines'
  | 'PitcairnIslands'
  | 'Poland'
  | 'Portugal'
  | 'PuertoRico'
  | 'Qatar'
  | 'Reunion'
  | 'Romania'
  | 'RussianFederation'
  | 'Rwanda'
  | 'SaintHelena'
  | 'SaintKittsAndNevis'
  | 'SaintLucia'
  | 'SaintPierreAndMiquelon'
  | 'SaintVincentAndTheGrenadines'
  | 'Saipan'
  | 'Samoa'
  | 'SanMarino'
  | 'SaoTomeAndPrincipe'
  | 'SaudiArabia'
  | 'Senegal'
  | 'Serbia'
  | 'SerbiaAndMontenegro'
  | 'Seychelles'
  | 'SierraLeone'
  | 'Singapore'
  | 'Slovakia'
  | 'Slovenia'
  | 'SolomonIslands'
  | 'Somalia'
  | 'SouthAfrica'
  | 'SouthGeorgiaAndTheSouthSandwichIslands'
  | 'SouthKorea'
  | 'Spain'
  | 'SriLanka'
  | 'Sudan'
  | 'Suriname'
  | 'Svalbard'
  | 'Swaziland'
  | 'Sweden'
  | 'Switzerland'
  | 'Syria'
  | 'Taiwan'
  | 'Tajikistan'
  | 'Tanzania'
  | 'Thailand'
  | 'TheBahamas'
  | 'TheGambia'
  | 'Togo'
  | 'Tokelau'
  | 'Tonga'
  | 'TrinidadAndTobago'
  | 'Tunisia'
  | 'Turkiye'
  | 'Turkmenistan'
  | 'TurksAndCaicosIslands'
  | 'Tuvalu'
  | 'Uganda'
  | 'Ukraine'
  | 'UnitedKingdom'
  | 'UnitedStates'
  | 'Uruguay'
  | 'Uzbekistan'
  | 'Vanuatu'
  | 'Vatican'
  | 'Venezuela'
  | 'Vietnam'
  | 'WallisAndFutuna'
  | 'WesternSahara'
  | 'Yemen'
  | 'Zambia'
  | 'Zimbabwe'
  | 'MalaysiaEast'

// 다른 도메인과 함께 쓰는 값이라 함부로 변경 불가
export type OverseaShippingCompanyType =
  | 'Unknown'
  | 'EMS'
  | 'SMP'
  | 'DHL'
  | 'DPD'
  | 'GEP_EMS'
  | 'GEP_SP'
  | 'SFExpress'
  | 'CJ'
  | 'Aggregator'
  | 'Aggregator2'
  | 'Aggregator3'
  | 'Aggregator4'
  | 'Aggregator5'

export type TaxIdType = 'TaxId' | 'PassportNumber'

export type TaiwanNameCertType = 'PhoneNumber' | 'SeparateDeclaration'

export type ExchangeType =
  | 'Unknown'
  | 'AED'
  | 'AUD'
  | 'BRL'
  | 'CAD'
  | 'CNY'
  | 'EUR'
  | 'GBP'
  | 'HKD'
  | 'JPY'
  | 'KZT'
  | 'MNT'
  | 'MOP'
  | 'MYR'
  | 'NOK'
  | 'NZD'
  | 'PHP'
  | 'RUB'
  | 'SAR'
  | 'SGD'
  | 'THB'
  | 'TRY'
  | 'TWD'
  | 'USD'
  | 'VND'
  | 'KRW'

export type EcouponReceiverType = 'You' | 'Me'
export type EcouponReceiveWayType = 'None' | 'MMS' | 'Kakao'

export type TaiwanRealNameCertifyStatusType =
  | 'Unknown'
  | 'MATCH'
  | 'MISMATCH'
  | 'ERROR'

export type ItemPurchaseBenefitType = 'Unknown' | 'Gift' | 'Extra'

export type PartnershipType =
  | 'Dotmap'
  | 'ILikeClick'
  | 'LinkPrice'
  | 'InterRich'
  | 'IComas'
  | 'Emforce'
  | 'AsianaMileage'
  | 'DreamX'

// 앱 타입
// 코어 - 지마켓, 옥션, G9 등
// Used - 옥션 중고장터
// SFC - G마켓 SFC
export type AppType = 'Core' | 'Used' | 'SFC'

export type FakeCouponType = 'Unknown' | 'SmileClubOneClick' | 'PCS'

export type NudgingType =
  | 'Unknown'
  | 'APP_DOWN'
  | 'CLUB_ONE_CLICK'
  | 'SMILE_CARD'
  | 'CART_COUNT_EXTENDED'

export type DeliveryCouponErrorType =
  | 'FREE_SHIPPING'
  | 'SPECIAL_SHIPPING'
  | 'DUPLICATED'

export type IncomeDutyItemOrderType = 'OnlyIncomeDutyItem' | 'OrderTogether'

export type OverseaShippingNotiOrderType = 'ReturnCart' | 'KeepOrder'

export type CartBtViewType = 'TypeA' | 'TypeB'

export type BranchServiceType = 'Unknown' | 'Dawn' | 'Reserve'

export type ExceptionItemType =
  | 'SmileFresh'
  | 'Install'
  | 'ExpressShop'
  | 'SmileDelivery'
  | 'Unknown'
  | 'ECoupon'

// endregion union types
