import { apiErrorHandler } from '~/api-error-handler'
import ActionHelper from '~/cart/modules/complex-actions/action-helper'
import { goInitialPath } from '~/cart/modules/complex-actions/index'
import { RootState } from '~/cart/modules/reducers'
import ActionWrapper, {
  AsyncComplexAction,
  ComplexAction,
  ComplexThunkDispatch,
} from '~/lib/action-wrapper'
import ComplexDataStore from '~/lib/complex-data-store'
import { ERROR_HANDLER_DO_NOTHING } from '~/lib/default-error-handlers'

const actionWrapper = new ActionWrapper<RootState>(apiErrorHandler)

type GetState = () => RootState
type ComplexDispatch = ComplexThunkDispatch<RootState>

export const handleClose = (gatewayKey?: string): ComplexAction<RootState> =>
  actionWrapper.wrapper((dispatch: ComplexDispatch) => {
    dispatch(goInitialPath()).catch(ERROR_HANDLER_DO_NOTHING())
    if (gatewayKey) {
      const gatewayData = ComplexDataStore.get('GatewayData', gatewayKey)
      if (gatewayData && gatewayData.onResolve) {
        gatewayData.onResolve()
      }
    }
  })

export const handleGatewayError = (
  message: string,
  gatewayKey?: string,
): AsyncComplexAction<RootState> =>
  actionWrapper.asyncWrapper(async (dispatch: ComplexDispatch) => {
    if (message) {
      window.alert(message)
    }
    dispatch(goInitialPath())
    if (gatewayKey) {
      const gatewayData = ComplexDataStore.get('GatewayData', gatewayKey)
      if (gatewayData && gatewayData.onResolve) {
        gatewayData.onResolve()
      }
    }
  })

export const handleGoInitialPath = (): ComplexAction<RootState> =>
  actionWrapper.wrapper((dispatch: ComplexDispatch) => {
    dispatch(goInitialPath()).catch(ERROR_HANDLER_DO_NOTHING())
  })

export const handleAddressBookResults = (): AsyncComplexAction<RootState> =>
  actionWrapper.asyncWrapper(
    async (dispatch: ComplexDispatch, getState: GetState) => {
      const state = getState()
      const smileFreshCartUnits = state.cart.cartUnitList.filter(
        (x) => x.cartUnitType === 'SmileFresh',
      )
      if (smileFreshCartUnits.length > 0) {
        // 스마일프레시 상품은 주소가 변하면 아예 재조회 해야한다.
        await ActionHelper.updateCartUnitsComplex(
          dispatch,
          getState,
          smileFreshCartUnits.map((x) => x.cartUnitId),
          false,
          state.cart.currentCartTab === 'SmileFresh', // 스마일프레시 탭인 경우 재선택까지 해준다
        )
      } else {
        await ActionHelper.updateLatestAddress(dispatch, getState)
      }
      await ActionHelper.updateTransPolicyInfo(dispatch, getState)
      dispatch(goInitialPath())
    },
  )

/**
 * 스마일클럽 가입 후 처리
 */
export const handleSmileClubJoinResults = (): AsyncComplexAction<RootState> =>
  actionWrapper.asyncWrapper(
    async (dispatch: ComplexDispatch, getState: GetState) => {
      await Promise.all([
        ActionHelper.updateSmileClubInfo(dispatch, getState),
      ]).finally(() => {
        dispatch(goInitialPath())
      })
    },
  )
