import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import OrderSummary from '~/cart/gmarket/gl/common/components/OrderSummary/OrderSummary'
import { goCheckout } from '~/cart/modules/complex-actions/checkout'
import { getTotalSummary, RootState } from '~/cart/modules/reducers'
import { getIsOverseaShipping } from '~/cart/modules/shipping/reducer'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import logRender from '~/lib/log-render'

const OrderSummaryContainer = (): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const isOverseaShipping = useSelector((state: RootState) =>
    getIsOverseaShipping(state.shipping),
  )

  const summaryInfo = useSelector(
    (state: RootState) => getTotalSummary(state),
    shallowEqual,
  )

  const onClickGoCheckout = (e: React.MouseEvent<HTMLElement>): void => {
    dispatch(goCheckout(e.currentTarget))
  }

  return (
    <OrderSummary
      isOverseaShipping={isOverseaShipping}
      summaryInfo={summaryInfo}
      onClickGoCheckout={onClickGoCheckout}
    />
  )
}

export default OrderSummaryContainer
