import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '~/cart/modules/reducers'
import { isFirstLoaded } from '~/cart/modules/view/reducer'
import Processing from '~/lib/components/gmarket/Processing'
import logRender from '~/lib/log-render'

const ProcessingContainer = (): JSX.Element => {
  logRender()

  const firstLoaded = useSelector((state: RootState) =>
    isFirstLoaded(state.view),
  )
  const firstLoadFailed = useSelector(
    (state: RootState) => state.view.firstLoadFailed,
  )
  const isPrepareOrderProcessing = useSelector(
    (state: RootState) => state.view.isPrepareOrderProcessing,
  )

  return (
    <>
      {!firstLoaded && !firstLoadFailed && (
        <Processing text={'장바구니를 로딩중입니다.'} type={'Load'} />
      )}
      {isPrepareOrderProcessing && (
        <Processing text={'진행중입니다.'} type={'PrepareOrder'} />
      )}
    </>
  )
}

export default ProcessingContainer
