import { map } from 'lodash'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShippingCountryComplex } from '~/cart/modules/complex-actions'
import { RootState } from '~/cart/modules/reducers'
import { getShippingCountry } from '~/cart/modules/shipping/reducer'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import CustomAnchor from '~/lib/components/CustomAnchor'
import { __ } from '~/lib/i18n'
import logRender from '~/lib/log-render'
import { LayerProps } from '~/types/common'
import { ShippingCountryType } from '~/types/enums'

const LayerCountrySelector = ({
  layerId,
  onCloseLayer,
}: LayerProps): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const shippingCountry = useSelector((state: RootState) =>
    getShippingCountry(state.shipping),
  )

  const countryList = useSelector(
    (state: RootState) => state.shipping.overseaShippingCountries,
  )

  const defaultShippingCountry: ShippingCountryType = 'SouthKorea'

  const changeCountryAndClose = useCallback(
    (countryType: ShippingCountryType): void => {
      dispatch(setShippingCountryComplex(countryType)).then((isSuccess) => {
        if (isSuccess) {
          onCloseLayer()()
        }
      })
    },
    [dispatch, onCloseLayer],
  )

  const onClickCountry = useCallback(
    (countryType: ShippingCountryType) => (): void => {
      changeCountryAndClose(countryType)
    },
    [changeCountryAndClose],
  )

  return (
    <div id={layerId} className="nv-layer" style={{ display: 'block' }}>
      <div className="nv-layer-bg" onClick={onCloseLayer()} />
      <div className="nv-layer-cont">
        <div className="ly_wrap">
          <div className="layer_type2">
            <div className="title">
              <strong className="type2_title4">
                {__('ESCROW_BASKETLAYER_TEXT_23', '국가선택')}
              </strong>
            </div>
            <div className="pop_cont">
              <div>
                {shippingCountry.countryType !== defaultShippingCountry && (
                  <dl className="main">
                    <dt>{__('ESCROW_BASKET_TEXT_236', '현재선택국가')}</dt>
                    <dd>
                      <strong>{shippingCountry.countryName}</strong>
                      <CustomAnchor
                        className="tx_btn"
                        onClick={onClickCountry(defaultShippingCountry)}
                      >
                        {__(
                          'ESCROW_BASKET_TEXT_237',
                          '국내배송(한국)으로 변경',
                        )}
                      </CustomAnchor>
                    </dd>
                  </dl>
                )}
                <div className="main_cont scroll_fixed">
                  <ul>
                    {map(countryList, (country, idx) => (
                      <li key={idx}>
                        <CustomAnchor
                          onClick={onClickCountry(country.countryType)}
                        >
                          <span>{country.countryName}</span>
                        </CustomAnchor>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <CustomAnchor className="l_icon ly_close" onClick={onCloseLayer()}>
              국가선택 닫기
            </CustomAnchor>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LayerCountrySelector
