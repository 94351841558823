/**
 * 불필요한 렌더 방지하기위해 로컬에서 로그
 * Container 에서는 꼭 호출할것
 * @welldone-software/why-did-you-render
 * 위 패키지 이용하면 좋겠으나 craco 로 바꿔야해서 일단..
 */
const logRender = (depth = 2): void => {
  if (
    process.env.REACT_APP_ENV === 'local' &&
    process.env.NODE_ENV !== 'production'
  ) {
    try {
      const re = /([^(]+)@|at ([^(]+) \(/gm
      const aRegexResult = re.exec(
        (new Error().stack as string).split(/\n/)[depth],
      )
      if (aRegexResult) {
        const callerName = aRegexResult[1] || aRegexResult[2]
        console.log(Date.now(), 'render >>', callerName)
      }
    } catch (e) {
      // ignore
    }
  }
}

export default logRender
