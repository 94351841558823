import {
  ItemGroupView,
  SmileFreshUnableOrderType,
  UnableOrderType,
} from '~/cart/modules/cart/types'
import { DiscountUnitType, ExchangeType, FakeCouponType } from '~/types/enums'

export type SummaryType = {
  cartUnitCount: number
  totalItemPrice: number
  totalDiscountPrice: number
  totalBundleDiscountPrice: number
  totalCouponPrice: number
  totalBuyerCouponPrice: number
  totalBuyerCouponOrEbayDiscountPrice: number // G마켓 부담 할인 or 쿠폰
  totalFundingDiscountPrice: number
  totalSellerDiscountPrice: number
  totalPartnershipDiscountPrice: number
  totalNegativePrice: number
  totalShippingFee: number
  totalInternationalShippingFee: number
  totalPrice: number
  totalDiscountPercent: number
}

export type SellerGroupSummaryType = {
  cartUnitCount: number
  totalItemPrice: number
  totalDiscountPrice: number
  totalBundleDiscountPrice: number
  totalCouponPrice: number
  totalBuyerCouponPrice: number
  totalBuyerCouponOrEbayDiscountPrice: number // G마켓 부담 할인 or 쿠폰
  totalFundingDiscountPrice: number
  totalSellerDiscountPrice: number
  totalPartnershipDiscountPrice: number
  totalNegativePrice: number
  // 주의! 배송비쿠폰을 빼지 않은 금액
  totalShippingFee: number
  totalInternationalShippingFee: number
  isBundleShipping: boolean
  isAllSpecialShipping: boolean
  totalPrice: number
  sellerGroupDiscountPercent: number
}

export type ShippingGroupSummaryType = {
  cartUnitCount: number
  totalItemPrice: number
  totalDiscountPrice: number
  totalNegativePrice: number
  totalCouponPrice: number
  totalBuyerCouponPrice: number
  totalBuyerCouponOrEbayDiscountPrice: number
  totalFundingDiscountPrice: number
  totalBundleDiscountPrice: number
  totalSellerDiscountPrice: number
  totalPartnershipDiscountPrice: number
  totalPrice: number
}

export type ItemGroupSummaryType = {
  cartUnitCount: number
  totalItemPrice: number
  totalDiscountPrice: number
  totalNegativePrice: number
  totalCouponPrice: number
  totalBuyerCouponPrice: number
  totalBuyerCouponOrEbayDiscountPrice: number
  totalFundingDiscountPrice: number
  totalBundleDiscountPrice: number
  totalSellerDiscountPrice: number
  totalPartnershipDiscountPrice: number
  totalPrice: number
}

export type TotalCashbackSummaryType = {
  isMaxBenefit: boolean
  isOnlySellerCashback: boolean
  maxEventCashback: number
  sellerCashback: number
  maxPayCashback: number
  maxClubDayCashback: number
  appliedEventCashback: number
  appliedClubDayCashback: number
  appliedSsgPointCashback: number
  clubDayCashbackRate: number
  totalCashbackAmount: number
  maxCashbackAmount: number
  useEnableDateUnix: number
  ssgCashbackDate: string
}

export type SmilePayAuthedCashReceipt = {
  cashReceiptType:
    | 'Unknown'
    | 'Personal' // 개인소득공제
    | 'Corp' // 사업자지출증빙
  cashReceiptIssueType:
    | 'Unknown'
    | 'CellPhoneNumber' // 휴대폰번호
    | 'CardNumber' // 현금영수증 카드 번호
    | 'CorpNumber' // 사업자번호
  cashReceiptNo: string
}

export type SmileCashPointAuthorizationClassType =
  | 'Unknown'
  | 'Shop'
  | 'Card'
  | 'Phone'
  | 'Smile'

export type SmileCashPointAuthorizationCodeType =
  | 'Unknown'
  | 'Password'
  | 'FingerPrint'
  | 'OTP'
  | 'ARS'
  | 'SMS'
  | 'Nothing'

export type SmileCashPointAuthData = {
  smileCashPointAuthType: 'SmileCashAuth' | 'SmilePointAuth'
  authorizationClass: SmileCashPointAuthorizationClassType
  authorizationCode: SmileCashPointAuthorizationCodeType
  authorizationId: string
  smileToken: string
}

export type McpInfo = {
  exchangeRateId: string
  homeCurrency: ExchangeType
  homeCurrencyNumber: string
  homeAmount: string
  exchangeRate: string
  invertedRate: string
  tradeStatus: string
}

export type PaypalAuthData = {
  paypalNo?: number
  token?: string
  amt?: number
  exchangeNo?: number
  email?: string
  payerId?: string
  payerStatus?: string
  salutation?: string
  firstName?: string
  middleName?: string
  lastName?: string
  suffix?: string
  countryCode?: string
  business?: string
}

export type AuthData = {
  smilePay?: {
    iacTxid: string
    smilePayTxid: string
    identificationToken: string
    amount: number
    cardAmount: number
    cashAmount: number
    mobileAmount: number
    ePrepaidAmount: number
    etcAmount: number
    cashReceipt?: SmilePayAuthedCashReceipt
  }
  mpi?: {
    cardNumber: string
    xid: string
    eci: string
    cavv: string
    kvpCardCode: string
  }
  ispTid?: string
  isp?: {
    ispCardCorpCode: string
    ispEncryptedData: string
    ispSessionKey: string
  }
  kbAppCard?: {
    tkey: string
    kvpCardCode: string
  }
  ekpgMobile?: {
    shopTransactionID: string
    ekpgTransactionID: string
    pgCompCode: string
  }
  smileCashPoint?: SmileCashPointAuthData
  selfAuth?: {
    selfAuthReqSeq: string
  }
  foreignCreditCard?: {
    cardNo: string
    decision: string
    expireMonthYear: string
    approvedNo: string
    cardPoint: string
    resultCode: string
    resultContent: string
    englishResultContent: string
    paymentAuthSeq: number
  }
  mcpInfo?: McpInfo
  unionPay?: {
    unionPayId: string
  }
  paypal?: PaypalAuthData
}

export enum CouponDownloadMode {
  Auto = 'Auto',
  Manual = 'Manual',
}

export enum CouponDownloadState {
  AutoSuccess = 'AutoSuccess',
  ManualSuccess = 'ManualSuccess',
  Fail = 'Fail',
  Loading = 'Loading',
  Terminated = 'Terminated',
}

export enum CouponBoxTableType {
  Buyer = 'Buyer',
  Seller = 'Seller',
  Super = 'Super',
  Bundle = 'Bundle',
}

/**
 * key: CouponBoxTableType
 * value: IssueNo
 */
export type CouponBoxSelectedIssueNoMap = Record<CouponBoxTableType, number>

export type CouponBoxTableViewData = {
  selectedCouponIssueNo: number
  type: CouponBoxTableType
  isIncludeCoupon?: boolean
  title?: string
  coupons: Array<CouponBoxTableItemViewData>
}

export type CouponBoxTableItemViewData = {
  issueNo: number
  price: number
  text?: string
  unit?: string
  possiblePrice?: number
  limitPrice?: number
  expiredDate?: string
  description?: string
  subDescription?: string
  isApplied?: boolean
  isAppliedOnAnother?: boolean
  appliedOnAnotherPrice?: number
  isDiscount?: boolean
  isSmileclubCoupon?: boolean
  isBizCoupon?: boolean
  isMobileCoupon?: boolean
  isAppCoupon?: boolean
  isDeliveryCoupon?: boolean
  isNotUseCoupon?: boolean
  isFakeCoupon?: boolean
  fakeCouponType?: FakeCouponType
  discountUnitType?: DiscountUnitType
  areaCode?: string
  impressionCode?: string
  paymentCouponName?: string
  policyNo?: number
  eventNo?: number
  encStr?: string
  isAutoDownloadedCoupon?: boolean
  selectedByRecommendation?: boolean
  issuable?: boolean
  isDownloading?: boolean
  isNotifiedPaymentCoupon?: boolean
}

export type DeliveryCouponBoxItemViewData = {
  issueNo: number
  price: number
  possiblePrice?: number
  expiredDate?: string
  description?: string
  isApplied?: boolean
  isAppliedOnAnother?: boolean
  isAppliedOnAnotherPrice?: number
  isSmileclubCoupon?: boolean
  isMobileCoupon?: boolean
  isAppCoupon?: boolean
}

export type SmileCashPointAuthOrgData = {
  ReturnCode: string
  AuthorizationClass: string
  AuthorizationCode: string
  AuthorizationId: string
  SmileToken: string
  Amount: string
  SmileCash: string
  CardAmount: string
  CashAmount: string
  MobileAmount: string
  E_PrepaidAmount: string
  ETCAmount: string
  CashReceiptType1: string
  CashReceiptType2: string
  CashReceiptNo: string
  ShopOrderId: string
  ShopManageCode: string
  ShopComment: string
  OTC: string
  SmilePayImageUrl: string
  SmilePayCompany: string
  PayMethodLevel3: string
  Installment: string
  IsUsedPoint: string
}

export type BuyUnavailableInfo = {
  unableOrderType: UnableOrderType | SmileFreshUnableOrderType
  defaultMessage: string
}

export type OverseaShippingFeeRequest = {
  cartUnitId?: number
  shippingWeight?: number
}

/**
 * key : '{shippingGroupKey}-{itemGroupKey}'
 */
export type ItemGroupMap = {
  [key: string]: ItemGroupView
}
