import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { fetchHideNudging } from '~/apis/cart'
import { updateCartNudgingTypes } from '~/cart/modules/cart/actions'
import { RootState } from '~/cart/modules/reducers'
import { closeLayer } from '~/cart/modules/view/actions'
import areaCodes from '~/data/areaCodes'
import { ComplexThunkDispatch } from '~/lib/action-wrapper'
import { __ } from '~/lib/i18n'
import logRender from '~/lib/log-render'
import { LayerProps } from '~/types/common'
import { OverseaShippingNotiOrderType } from '~/types/enums'

type LayerOverseaShippingNotiProp = LayerProps & {
  onCloseLayerAsync: (closeImmediately?: boolean) => Promise<void>
  detailData?: {
    onConfirm: (orderType?: OverseaShippingNotiOrderType) => void
  }
}

const LayerOverseaShippingNoti = ({
  layerId,
  layerKey,
  onCloseLayerAsync,
  detailData,
}: LayerOverseaShippingNotiProp): JSX.Element => {
  logRender()
  const dispatch = useDispatch<ComplexThunkDispatch<RootState>>()

  const onClickConfirmButton = useCallback(
    (
      overseaShippingNotiOrderType?: OverseaShippingNotiOrderType,
    ) => async (): Promise<void> => {
      dispatch(updateCartNudgingTypes('OVERSEA_PACKAGE_NOTI'))
      await fetchHideNudging({
        cartNudgingType: 'OVERSEA_PACKAGE_NOTI',
      }) // 어차피 금방 없어질 Noti Layer라 컴포넌트지만 여기에 배치해놓는다...
      await onCloseLayerAsync(true)
      if (detailData) {
        detailData.onConfirm(overseaShippingNotiOrderType)
      }
    },
    [detailData, onCloseLayerAsync],
  )

  if (!detailData) {
    dispatch(closeLayer(layerKey))
    return <></>
  }

  return (
    <div className="nv-layer" id={layerId} style={{ display: 'block' }}>
      <div className="nv-layer-bg"></div>
      <div className="nv-layer-cont">
        <div className="box__layer-oversea-delivery">
          <p className="text__info">
            {__('ESCROW_BASKET_TEXT_253', '해외배송비 정책이')}
            <br />
            {__('ESCROW_BASKET_TEXT_254', '변경되었습니다.')}
          </p>
          <p className="text__info-desc">
            {__(
              'ESCROW_BASKET_TEXT_255',
              '여러 종류의 상품을 함께 주문하실 경우',
            )}
            <br />
            {__('ESCROW_BASKET_TEXT_256', '배송비가 별도 계산됩니다.')}
          </p>
          <div className="box__button">
            <button
              type="button"
              className="button button__cancel"
              onClick={onClickConfirmButton('ReturnCart')}
              data-montelena-acode={
                areaCodes.OVERSEA_SHIPPING_POLICY_RETURN_CART
              }
            >
              {__('ESCROW_BASKET_TEXT_258', '장바구니로 돌아가기')}
            </button>
            <button
              type="button"
              className="button button__confirm"
              onClick={onClickConfirmButton('KeepOrder')}
              data-montelena-acode={
                areaCodes.OVERSEA_SHIPPING_POLICY_KEEP_ORDER
              }
            >
              {__('ESCROW_BASKET_TEXT_257', '이어서 주문하기')}
            </button>
          </div>
          <button
            className="button__close sprite__cart--after"
            onClick={onClickConfirmButton()}
            data-montelena-acode={areaCodes.OVERSEA_SHIPPING_POLICY_CLOSE_LAYER}
          >
            <span className="for-a11y">팝업 닫기</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default LayerOverseaShippingNoti
