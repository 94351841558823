import React, { useState } from 'react'
import FormatPrice from '~/cart/gmarket/gl/common/components/FormatPrice'
import { SummaryType } from '~/cart/modules/types'
import { __ } from '~/lib/i18n'

const OrderSummaryCore = ({
  summaryInfo,
}: {
  summaryInfo: SummaryType
}): JSX.Element => {
  const [isDiscountDetailOpened, setDiscountDetailOpened] = useState(false)
  const [isShippingFeeDetailOpened, setShippingFeeDetailOpened] = useState(
    false,
  )
  return (
    <div className="order_info">
      <ul className="receipt_list_box">
        <li className="list">
          <span className="label">{__('ESCROW_BASKET_TEXT_73', '상품수')}</span>
          <span className="format-amount">
            <strong className="text">{summaryInfo.cartUnitCount}</strong>
            <span className="unit">{__('ESCROW_BASKET_TEXT_64', '개')}</span>
          </span>
        </li>
        <li className="list">
          <span className="label">
            {__('ESCROW_BASKET_TEXT_4', '상품금액')}
          </span>
          <FormatPrice
            withoutExchange={true}
            krwAmount={summaryInfo.totalItemPrice}
          />
        </li>
        <li
          className={`list ${
            summaryInfo.totalNegativePrice > 0 ? 'has_child' : ''
          }`}
        >
          {summaryInfo.totalNegativePrice > 0 ? (
            <button
              type="button"
              className={`label ${
                isDiscountDetailOpened ? 'label--active' : ''
              }`}
              onClick={(): void =>
                setDiscountDetailOpened(!isDiscountDetailOpened)
              }
            >
              {__('ESCROW_BASKET_TEXT_31', '할인금액')}
            </button>
          ) : (
            <span className="label">
              {__('ESCROW_BASKET_TEXT_31', '할인금액')}
            </span>
          )}
          <FormatPrice
            rootClass="format-price discount"
            withoutExchange={true}
            krwAmount={-1 * summaryInfo.totalNegativePrice}
          />{' '}
          {summaryInfo.totalNegativePrice > 0 && isDiscountDetailOpened && (
            <ul className="list_child">
              {summaryInfo.totalBuyerCouponOrEbayDiscountPrice > 0 && (
                <li className="list_child__item">
                  <span className="label">
                    {__('ESCROW_BASKET_TEXT_245', 'G마켓할인')}
                  </span>
                  <FormatPrice
                    withoutExchange={true}
                    krwAmount={
                      -1 * summaryInfo.totalBuyerCouponOrEbayDiscountPrice
                    }
                  />
                </li>
              )}
              {summaryInfo.totalSellerDiscountPrice > 0 && (
                <li className="list_child__item">
                  <span className="label">
                    {__('ESCROW_BASKET_TEXT_246', '판매자할인')}
                  </span>
                  <FormatPrice
                    withoutExchange={true}
                    krwAmount={-1 * summaryInfo.totalSellerDiscountPrice}
                  />
                </li>
              )}
              {summaryInfo.totalCouponPrice -
                summaryInfo.totalBuyerCouponPrice >
                0 && (
                <li className="list_child__item">
                  <span className="label">
                    {__('ESCROW_BASKET_TEXT_247', '중복할인')}
                  </span>
                  <FormatPrice
                    withoutExchange={true}
                    krwAmount={
                      -1 *
                      (summaryInfo.totalCouponPrice -
                        summaryInfo.totalBuyerCouponPrice)
                    }
                  />
                </li>
              )}
              {summaryInfo.totalBundleDiscountPrice > 0 && (
                <li className="list_child__item">
                  <span className="label">
                    {__('ESCROW_BASKET_TEXT_248', '더 사면 할인')}
                  </span>
                  <FormatPrice
                    withoutExchange={true}
                    krwAmount={-1 * summaryInfo.totalBundleDiscountPrice}
                  />
                </li>
              )}
              {summaryInfo.totalPartnershipDiscountPrice > 0 && (
                <li className="list_child__item">
                  <span className="label">제휴할인</span>
                  <FormatPrice
                    withoutExchange={true}
                    krwAmount={-1 * summaryInfo.totalPartnershipDiscountPrice}
                  />
                </li>
              )}
            </ul>
          )}
        </li>
        <li
          className={`list ${
            summaryInfo.totalInternationalShippingFee > 0 ? 'has_child' : ''
          }`}
        >
          {summaryInfo.totalInternationalShippingFee > 0 ? (
            <button
              type="button"
              className={`label ${
                isShippingFeeDetailOpened ? 'label--active' : ''
              }`}
              onClick={(): void =>
                setShippingFeeDetailOpened(!isShippingFeeDetailOpened)
              }
            >
              {__('ESCROW_BASKET_TEXT_32', '배송비')}
            </button>
          ) : (
            <span className="label">
              {__('ESCROW_BASKET_TEXT_32', '배송비')}
            </span>
          )}
          <FormatPrice
            withoutExchange={true}
            krwAmount={summaryInfo.totalShippingFee}
          />
          {summaryInfo.totalInternationalShippingFee > 0 &&
            isShippingFeeDetailOpened && (
              <ul className="list_child">
                {summaryInfo.totalShippingFee -
                  summaryInfo.totalInternationalShippingFee >
                  0 && (
                  <li className="list_child__item">
                    <span className="label">
                      {__('ESCROW_BASKET_TEXT_33', '기본배송비')}
                    </span>
                    <FormatPrice
                      withoutExchange={true}
                      krwAmount={
                        summaryInfo.totalShippingFee -
                        summaryInfo.totalInternationalShippingFee
                      }
                    />
                  </li>
                )}
                <li className="list_child__item">
                  <span className="label">
                    {__('ESCROW_BASKET_TEXT_34', '해외배송비')}
                  </span>
                  <FormatPrice
                    withoutExchange={true}
                    krwAmount={summaryInfo.totalInternationalShippingFee}
                  />
                </li>
              </ul>
            )}
        </li>
      </ul>
    </div>
  )
}

export default OrderSummaryCore
