import React from 'react'
import FormatPrice from '~/cart/gmarket/gl/common/components/FormatPrice'
import OrderSummaryCore from '~/cart/gmarket/gl/common/components/OrderSummary/OrderSummaryCore'
import OrderSummaryTitleContainer from '~/cart/gmarket/gl/common/containers/OrderSummary/OrderSummaryTitleContainer'
import OverseaShippingSummaryContainer from '~/cart/gmarket/gl/common/containers/OrderSummary/OverseaShippingSummaryContainer'
import { SummaryType } from '~/cart/modules/types'
import areaCodes from '~/data/areaCodes'
import { formatAsDom } from '~/lib/formatter'
import { __ } from '~/lib/i18n'

const OrderSummary = ({
  isOverseaShipping,
  summaryInfo,
  onClickGoCheckout,
}: {
  isOverseaShipping: boolean
  summaryInfo: SummaryType
  onClickGoCheckout: React.MouseEventHandler<HTMLElement>
}): JSX.Element => {
  return (
    <div id="cart_order" className="cart_total">
      <h3 className="for_a11y">최종 결제정보</h3>
      <div className="order">
        <OrderSummaryTitleContainer />
        {isOverseaShipping && <OverseaShippingSummaryContainer />}
        <OrderSummaryCore summaryInfo={summaryInfo} />
        <div className="order_summary">
          <strong className="label">
            {__('ESCROW_BASKET_TEXT_35', '총 결제금액')}
          </strong>
          <FormatPrice krwAmount={summaryInfo.totalPrice} />
        </div>
        <div className="order_action fixed">
          <div className="flex_wrap">
            <div className="flex add_line">
              <div className="summary">
                <span className="item_qty">
                  {formatAsDom(
                    __('ESCROW_BASKET_TEXT_221', '전체 {0}개'),
                    <em className="text__value">
                      {summaryInfo.cartUnitCount}
                    </em>,
                  )}
                </span>
                <FormatPrice krwAmount={summaryInfo.totalPrice} />
              </div>
            </div>
            <div className="flex">
              <button
                className="btn_submit"
                onClick={onClickGoCheckout}
                data-montelena-acode={areaCodes.GO_ORDER}
              >
                <span className="text">
                  {__('ESCROW_BASKET_TEXT_36', '구매하기')}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderSummary
