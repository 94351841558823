/* tslint:disable */
/* eslint-disable */

/**
 * UXE에서 제공하는 함수들은 다 이쪽으로 몬다
 * type체크없이 실행한다
 */
class UXEHelper {
  static openDimmedLayer = (query, focus) => {
    if (window.DimmedLayer) {
      try {
        window.DimmedLayer.open(query, focus)
      } catch (e) {
        console.error(e)
      }
      if ($(query).find('.dimmed').length > 1) {
        $(query).find('.dimmed').slice(1).remove()
      }
    }
  }
  static closeDimmedLayer = (query, focus) => {
    // 중복호출되면 문제생김
    window.closedLayer = window.closedLayer || {}
    if (window.DimmedLayer && !window.closedLayer[query]) {
      try {
        window.closedLayer[query] = true
        window.DimmedLayer.close(query, focus)
        window.setTimeout(() => {
          delete window.closedLayer[query]
        }, 1000) // 동일 아이디 사용하는 경우 대비하여 1초 후 리셋해준다
      } catch (e) {
        console.error(e)
      }
    }
  }
  static initSwiper = (swiperElement, options) => {
    try {
      return new window.Swiper(swiperElement, options)
    } catch (e) {
      console.error(e)
      return undefined
    }
  }

  /**
   * carouFredSel wrapping 해주는..
   * @param element {HTMLElement}
   * @param options {CarouFredSelOption}
   * @param needsWrap {boolean} 첫번째, 마지막거 추가해야하는지?(default:true)
   * @return {Promise<CarouFredSelData|undefined>}
   */
  static initCarouFredSel = async (element, options, needsWrap = true) => {
    return new Promise((resolve) => {
      const wrapper = $(element)
      const children = wrapper.children()
      if (options.activeClass) {
        children.first().addClass(options.activeClass)
      }
      const refreshTabIndex = () => {
        if (!options.wrapperQuery) {
          return
        }

        const queryPrefix = `${options.wrapperQuery} ${options.slideQuery}`
        $(`${queryPrefix} button, ${queryPrefix} input, ${queryPrefix} a`).attr(
          'tabindex',
          '-1',
        )
        $(
          `${queryPrefix}.active button, ${queryPrefix}.active input, ${queryPrefix}.active a`,
        ).removeAttr('tabindex')
      }
      for (let i = 0; i < children.length; i++) {
        $(children[i]).attr('idx', i)
      }
      if (needsWrap) {
        wrapper.prepend('<li></li>').append('<li></li>')
      }
      wrapper.carouFredSel({
        circular: options.circular,
        infinite: options.infinite,
        align: options.align,
        auto: options.auto,
        width: options.width,
        height: options.height,
        items: {
          height: options.items.height,
          visible: options.items.visible,
          start: options.items.start,
        },
        prev: {
          button: options.prevButton,
          key: 'left',
          onBefore: function (data) {
            if (options.activeClass) {
              data.items.visible.next().addClass(options.activeClass)
              data.items.old.next().removeClass(options.activeClass)
            }
            refreshTabIndex()
            if (options.onSlideChangeStart) {
              options.onSlideChangeStart(data)
            }
          },
          onAfter: function (data) {
            if (options.onSlideChangeEnd) {
              options.onSlideChangeEnd(data.items.visible.get(1))
            }
          },
        },
        next: {
          button: options.nextButton,
          key: 'right',
          onBefore: function (data) {
            if (options.activeClass) {
              data.items.visible.prev().addClass(options.activeClass)
              data.items.old.prev().removeClass(options.activeClass)
            }
            refreshTabIndex()
            if (options.onSlideChangeStart) {
              options.onSlideChangeStart(data)
            }
          },
          onAfter: function (data) {
            if (options.onSlideChangeEnd) {
              options.onSlideChangeEnd(data.items.visible.get(1))
            }
          },
        },
        scroll: options.scroll || {
          items: 1,
          duration: 200,
        },
        swipe: options.swipe,
        pagination:
          typeof options.pagination === 'object'
            ? options.pagination
            : options.pagination
            ? $(options.pagination)
            : undefined,
        onCreate: function (data) {
          refreshTabIndex()
          resolve({
            ...data,
            slideTo: (idx) => {
              const fixedIdx =
                idx < data.items.context.children.length
                  ? idx
                  : data.items.context.children.length - 1
              data.items.trigger('slideTo', fixedIdx)
            },
          })
        },
      })
    }).catch((e) => {
      console.error(e)
      return undefined
    })
  }

  static setTotalPaymentAmount = (totalPaymentAmount) => {
    if (window.totalPriceCounter) {
      try {
        window.totalPriceCounter(totalPaymentAmount)
      } catch (e) {
        console.error(e)
      }
    }
  }

  static onLoad = () => {
    // PC용 결제금액 고정
    if (
      window.EBAY &&
      window.EBAY.UI &&
      window.EBAY.UI.CART &&
      window.EBAY.UI.CART.scrollingInteraction
    ) {
      try {
        window.EBAY.UI.CART.scrollingInteraction()
      } catch (e) {
        console.error(e)
      }
    }
  }

  static onOrderCompleteLoad = () => {}

  static checkHeight = () => {
    if (window.checkHeight) {
      try {
        window.checkHeight()
      } catch (e) {
        console.error(e)
      }
    }
  }

  static onClickG9CompanyInfo = () => {
    const $companyInfo = $('#footer').find('.company_info')
    if ($companyInfo.hasClass('active')) {
      $companyInfo.removeClass('active')
      $companyInfo.find('address').slideUp()
    } else {
      $companyInfo.addClass('active')
      $companyInfo.find('address').slideDown()
    }
  }

  static onG9SmilePayNoticeLoad = () => {
    if (typeof window.layerSmilepay === 'function') {
      window.layerSmilepay()
    }
  }

  static initLottie = (htmlElement, { path, loop }) => {
    if (window.bodymovin) {
      const motionPlayData = {
        container: htmlElement,
        renderer: 'svg',
        loop: loop,
        rendererSettings: {
          progressiveLoad: false,
        },
        path,
      }

      return bodymovin.loadAnimation(motionPlayData)
    }
  }

  static makeKakaoGiftButton = (elementId, content, buttons) => {
    try {
      // https://wiki.ebaykorea.com/display/LBS/LBS+Home 카카오 계정정보 여기서 확인(비공개라 담당자에게 확인요청해야함)
      // 계정명 ebaykorea_gmkt@daum.net
      window.Kakao.init('bf2b9835da96c283a47e3716d2ca7a33')
      window.Kakao.Link.createDefaultButton({
        container: `#${elementId}`,
        objectType: 'feed',
        content,
        buttons,
      })
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * @param id {string}
   * @param count {number}
   * @param callback {()=>void}
   * @return {(function(): void)|*}
   */
  static onMultipleClick = (id, count, callback) => () => {
    if (!window.multipleClickCounter) {
      window.multipleClickCounter = {}
    }
    if (!window.multipleClickCounter[id]) {
      window.multipleClickCounter[id] = {}
    }
    if (window.multipleClickCounter[id].count >= count) {
      return
    }

    if (window.multipleClickCounter[id].timeoutKey) {
      clearTimeout(window.multipleClickCounter[id].timeoutKey)
    }

    const timeoutKey = window.setTimeout(() => {
      delete window.multipleClickCounter[id]
      delete window.multipleClickCounter
    }, 500)

    window.multipleClickCounter[id] = {
      timeoutKey,
      count: (window.multipleClickCounter[id].count || 0) + 1,
    }

    console.log(window.multipleClickCounter[id])

    if (window.multipleClickCounter[id].count === count) {
      callback()
    }
  }

  /**
   * 글로벌 모바일 푸터에서 사용되는 updateQueryString 함수가 해시 라우터 사용하는경우 동작하지 않는 부분 수정
   */
  static fixGlobalFooter = () => {
    if (!window.orgUpdateQueryString && window.updateQueryString) {
      window.orgUpdateQueryString = window.updateQueryString
      window.updateQueryString = (uri, key, value) =>
        window.orgUpdateQueryString(
          uri ? uri.replace(/#.+$/, '') : uri,
          key,
          value,
        )
    }
  }

  /**
   * @param method {'scene'|'close'|'buttonAction'}
   * @param index {number|undefined}
   * @param target {'layer'|undefined}
   */
  static oneClickSmileClubLayerFunc = (method, index, target) => {
    if (window.EBAY && window.EBAY.UI) {
      try {
        if (method === 'scene') {
          window.EBAY.UI.CART.oneclickSmileclubFunc.layerSceneFunc(
            index,
            target,
          )
        } else if (method === 'close') {
          window.EBAY.UI.CART.oneclickSmileclubFunc.layerclosedFunc()
        } else if (method === 'buttonAction') {
          window.EBAY.UI.CART.oneclickSmileclubFunc.buttonAction()
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  static onCartTabChanged = () => {
    if (window.EBAY && window.EBAY.UI) {
      try {
        EBAY.UI.CART.scrollTabChange()
      } catch (e) {
        console.error(e)
      }
    }
  }
  /**
   * @param isOpen {boolean}
   */
  static setFoldingCouponBoxOpen = (isOpen) => {
    try {
      if (window.EBAY && window.EBAY.UI && window.EBAY.UI.CART) {
        if (isOpen) {
          if (EBAY.UI.CART.showCouponBox) {
            EBAY.UI.CART.showCouponBox()
          }
        } else {
          if (EBAY.UI.CART.hideCouponBox) {
            EBAY.UI.CART.hideCouponBox()
          }
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
  /**
   * @param prevValue {number}
   * @param nextValue {number}
   */
  static moveGaugeBar = (prevValue, nextValue) => {
    try {
      if (
        window.EBAY &&
        window.EBAY.UI &&
        window.EBAY.UI.CART &&
        EBAY.UI.CART.cartGaugeLayer.moveGaugeBar
      ) {
        EBAY.UI.CART.cartGaugeLayer.moveGaugeBar(prevValue, nextValue)
      }
    } catch (e) {
      console.error(e)
    }
  }

  static onRecommendedItemAddedCart = () => {
    try {
      if (window.lottieAni && window.lottieAni.motionPlayer) {
        window.lottieAni.motionPlayer()
      }
    } catch (e) {
      console.error(e)
    }
  }
  /**
   * @param prevValue {number}
   * @param nextValue {number}
   */
  static moveSmileFreshGaugeBar = (prevValue, nextValue) => {
    try {
      if (
        window.EBAY &&
        window.EBAY.UI &&
        window.EBAY.UI.CART &&
        EBAY.UI.CART.cartGaugeLayer.smilefreshGaugeBar
      ) {
        EBAY.UI.CART.cartGaugeLayer.smilefreshGaugeBar(prevValue, nextValue)
      }
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * 레이어 내에서만 포커싱 이동 처리 되도록 (app.js 참조)
   * @param layerId
   */
  static layerFocus = (layerId) => {
    try {
      var target = $('#' + layerId)
      target.on('keydown', trapTabKey)

      var focusElString =
        'a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], input[type="radio"]'
      var focusEl = target.find(focusElString)

      var firstTab = focusEl[0]
      var lastTab = focusEl[focusEl.length - 1]
      var activeEl

      firstTab.focus()

      function trapTabKey(e) {
        activeEl = document.activeElement
        if (e.keyCode === 9) {
          if (e.shiftKey) {
            if (activeEl === firstTab) {
              e.preventDefault()
              lastTab.focus()
            }
          } else {
            if (activeEl === lastTab) {
              e.preventDefault()
              firstTab.focus()
            }
          }
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * BCI-102117 G마켓 쿠폰함 접근성 개선
   * shift+tab 키 적용 시 포커싱 유지 (app.js 참조)
   * @param isOpen
   */
  static forA11yInertSet = (isOpen) => {
    try {
      if (isOpen) {
        document
          .querySelector('#skip-navigation')
          .setAttribute('inert', 'inert')
        document.querySelector('#header').setAttribute('inert', 'inert')
        document.querySelector('#container').setAttribute('inert', 'inert')
        document.querySelector('#footer').setAttribute('inert', 'inert')
      } else {
        document.querySelector('#skip-navigation').removeAttribute('inert')
        document.querySelector('#header').removeAttribute('inert')
        document.querySelector('#container').removeAttribute('inert')
        document.querySelector('#footer').removeAttribute('inert')
      }
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * BCI-102115 수량 변경 시 접근성 개선
   * improveAccessibility.js 참조
   * @param quantity
   */
  static announceForAccessibility = (quantity) => {
    try {
      if (quantity && window.announceForAccessibility) {
        window.announceForAccessibility('현재 상품 수' + quantity)
      }
    } catch (e) {
      console.error(e)
    }
  }

  static openSlideLayer = (element) => {
    try {
      window.slideLayer.open(element)
    } catch (e) {
      console.error(e)
    }
  }

  static closeSlideLayer = () => {
    try {
      window.slideLayer.closed()
    } catch (e) {
      console.error(e)
    }
  }

  static mobileScrollTop = (domId) => {
    try {
      const dom = document.querySelector(domId)
      if (dom) {
        const boundingClientRect = dom.getBoundingClientRect()
        if (boundingClientRect.top !== 0) {
          window.scrollTo({
            top: boundingClientRect.top - 98,
            behavior: 'smooth',
          })
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export default UXEHelper
