import React, { useCallback } from 'react'
import CouponBoxSummary from '~/cart/gmarket/gl/common/components/CouponBox/CouponBoxSummary'
import CouponBoxTable from '~/cart/gmarket/gl/common/components/CouponBox/CouponBoxTable'
import useUnitCouponBox from '~/cart/hooks/useUnitCouponBox'
import { CouponBoxTableItemViewData } from '~/cart/modules/types'
import domains from '~/data/domains'
import CustomAnchor from '~/lib/components/CustomAnchor'
import { formatString } from '~/lib/formatter'
import { __ } from '~/lib/i18n'
import logRender from '~/lib/log-render'

type CouponBoxProps = {
  layerId: string
  cartUnitId: number
  onCloseLayer: () => void
}

const UnitCouponBoxContainer = ({
  layerId,
  cartUnitId,
  onCloseLayer,
}: CouponBoxProps): JSX.Element => {
  logRender()

  const preOnChangeCoupon = useCallback(
    (coupon: CouponBoxTableItemViewData): boolean => {
      if (coupon.isAppliedOnAnother) {
        return window.confirm(
          __(
            'REWARD_COUPON_ALERT_12',
            '다른 상품에 이미 적용되어있는 쿠폰입니다. 취소 후 이 상품에 적용하시겠습니까?',
          ),
        )
      }
      if (coupon.paymentCouponName) {
        window.alert(
          formatString(
            __(
              'ESCROW_BASKET_TEXT_153',
              '{0} 결제수단만 사용가능한 쿠폰입니다. 주문결제시 결제제한이 적용됩니다.',
            ),
            coupon.paymentCouponName,
          ),
        )
      }

      return true
    },
    [],
  )

  const {
    summary,
    selectedCoupon,
    couponBoxViewData,
    onChangeCoupon,
    onCancelCoupon,
    onApplyCoupon,
  } = useUnitCouponBox(cartUnitId)

  const sellerCouponBoxViewData = couponBoxViewData.find(
    (x) =>
      x.coupons.filter((x) => !x.isNotUseCoupon).length > 0 &&
      x.type === 'Seller',
  )
  const bundleCouponBoxViewData = couponBoxViewData.find(
    (x) =>
      x.coupons.filter((x) => !x.isNotUseCoupon).length > 0 &&
      x.type === 'Bundle',
  )

  return (
    <div className="nv-layer" id={layerId} style={{ display: 'block' }}>
      <div className="nv-layer-bg" onClick={onCloseLayer} />
      <div className="nv-layer-cont">
        <div className="item__coupon">
          <button className="button-close sp_coupon" onClick={onCloseLayer}>
            <span className="for-a11y">내쿠폰함 닫기</span>
          </button>

          <div className="coupon__header">
            <strong className="coupon_title sp_coupon">
              {__('ESCROW_BASKET_TEXT_218', '내쿠폰함')}
            </strong>
            <CustomAnchor
              href={domains.MY_COUPON}
              target="_blank"
              title="MyG로 새창 이동"
              className="link_list"
            >
              {__('REWARD_COUPON_TEXT_1', '내쿠폰 전체목록')}
            </CustomAnchor>
          </div>

          <div className="coupon__notice">{__('REWARD_COUPON_TEXT_8')}</div>

          <div className="coupon__body">
            {couponBoxViewData.some(
              (x) =>
                x.coupons.filter((x) => !x.isNotUseCoupon).length > 0 &&
                (x.type === 'Buyer' || x.type === 'Super'),
            ) ? (
              couponBoxViewData.map((viewData) => {
                const key = viewData.type
                return (
                  <CouponBoxTable
                    key={key}
                    viewData={viewData}
                    selectedCouponIssueNo={selectedCoupon[viewData.type]}
                    onChangeCoupon={onChangeCoupon(preOnChangeCoupon)}
                    onCancelCoupon={onCancelCoupon}
                  />
                )
              })
            ) : sellerCouponBoxViewData || bundleCouponBoxViewData ? (
              <>
                {sellerCouponBoxViewData && (
                  <CouponBoxTable
                    viewData={sellerCouponBoxViewData}
                    selectedCouponIssueNo={selectedCoupon['Seller']}
                    onChangeCoupon={onChangeCoupon(preOnChangeCoupon)}
                    onCancelCoupon={onCancelCoupon}
                  />
                )}
                {bundleCouponBoxViewData && (
                  <CouponBoxTable
                    viewData={bundleCouponBoxViewData}
                    selectedCouponIssueNo={selectedCoupon['Bundle']}
                    onChangeCoupon={onChangeCoupon(preOnChangeCoupon)}
                    onCancelCoupon={onCancelCoupon}
                  />
                )}
                <div className="coupon-group coupon-empty">
                  <i className="icon icon_question" />{' '}
                  {__(
                    'REWARD_COUPON_TEXT_4',
                    '보유하신 쿠폰 중 조건에 만족하는 쿠폰이 없습니다.',
                  )}
                </div>
              </>
            ) : (
              <div className="coupon-group coupon-empty">
                <i className="icon icon_question" />{' '}
                {__('REWARD_COUPON_TEXT_4', '현재 적용가능한 쿠폰이 없습니다.')}
              </div>
            )}
          </div>

          <div className="coupon__footer">
            <CouponBoxSummary summary={summary} />

            <div className="coupon_button">
              <button
                className="button-submit"
                onClick={onApplyCoupon(onCloseLayer)}
              >
                {__('REWARD_COUPON_TEXT_14', '쿠폰적용')}
              </button>
              <button className="button-cancel" onClick={onCloseLayer}>
                {__('REWARD_COUPON_TEXT_13', '취소')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnitCouponBoxContainer
