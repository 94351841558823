import React, { useEffect, useState } from 'react'
import { fetchGetShippingCompanyLimit } from '~/apis/oversea'
import { ShippingCountry } from '~/cart/modules/shipping/types'
import domains from '~/data/domains'
import CustomAnchor from '~/lib/components/CustomAnchor'
import HtmlParser from '~/lib/components/HtmlParser'
import { formatString } from '~/lib/formatter'
import { __ } from '~/lib/i18n'

const SFExpressNotice = ({
  shippingCountry,
  onClickClose,
}: {
  shippingCountry: ShippingCountry
  onClickClose: React.MouseEventHandler<HTMLElement>
}): JSX.Element => {
  const [limitedCategoryPolicyList, setLimitedCategoryPolicyList] = useState<
    string[]
  >([])

  useEffect(() => {
    fetchGetShippingCompanyLimit({
      internationalShippingCompany: 'SFExpress',
      countryType: shippingCountry.countryType,
      shippingLimitType: 'Category',
    })
      .then((res) => {
        setLimitedCategoryPolicyList(
          res.flatMap((limit) => {
            let category = limit.itemLargeCategoryName
            if (limit.itemMediumCategoryName) {
              category += ` > ${limit.itemMediumCategoryName}`
              if (limit.itemSmallCategoryName) {
                category += ` > ${limit.itemSmallCategoryName}`
              }
            }
            if (category) {
              return [category]
            } else {
              return []
            }
          }),
        )
      })
      .catch(() => {
        setLimitedCategoryPolicyList([])
      })
  }, [shippingCountry.countryType])

  return (
    <div className="ly_wrap" style={{ display: 'block' }}>
      <div
        id="sf_express"
        className="ly_layer layer_type2 sf_layer active"
        style={{ display: 'block' }}
      >
        <div className="title">
          <h2 className="payment_lay_title sf_title">
            <strong className="type2_title13">
              <img
                src={`${domains.PICS}/pc/gl/pay/fr_img/sf_logo.png`}
                alt=""
              />
              {__('ESCROW_BASKETLAYER_TEXT_24', 'SF Express 이용안내')}
            </strong>
          </h2>
        </div>
        <div className="pop_cont">
          <div className="cont">
            <HtmlParser rootTag="h3" className="tit_bl">
              {__(
                'ESCROW_BASKETLAYER_TEXT_25',
                '서비스 국가 :&nbsp;<strong>중국/홍콩/마카오/대만/싱가폴</strong>',
              )}
            </HtmlParser>
          </div>
          <div className="cont">
            <HtmlParser rootTag="h3" className="tit_bl">
              {formatString(
                __(
                  'ESCROW_BASKETLAYER_TEXT_26',
                  '<em>{0}</em>&nbsp;배송 SF Express 이용불가 카테고리 안내',
                ),
                shippingCountry.countryName,
              )}
            </HtmlParser>
            <ul className="not_delivery_list">
              {limitedCategoryPolicyList.map((policy, idx) => (
                <li key={idx}>{`- ${policy}`}</li>
              ))}
            </ul>
          </div>
          <div className="cont">
            <HtmlParser rootTag="h3" className="tit_bl">
              {__(
                'ESCROW_BASKETLAYER_TEXT_27',
                '<em>SF Express</em> 이용제한정책 안내',
              )}
            </HtmlParser>
            <p>
              {__(
                'ESCROW_BASKETLAYER_TEXT_28',
                '해외수입 규정에 따라, 중국과 대만으로 배송되는 G마켓 해외 주문건은 아래와 같은 이용제한조건이 있습니다.',
              )}
            </p>

            <HtmlParser rootTag="div" className="txt_box">
              {`${__(
                'ESCROW_BASKETLAYER_TEXT_29',
                `<strong>중국배송건</strong>
              <ul class="mb10">
                <li>- 최대 구매가능 수량 : 최대 <strong class="txt_blue2">6</strong> 개 (장바구니기준)</li>
                <li>- 화장품&amp;바디/헤어&nbsp;카테고리&nbsp;상품은&nbsp;<strong class="txt_blue2">18,000</strong> 원 이하</li>
                <li>- 최대 구매가능 금액 (배송비 제외, 상기 카테고리 포함) :&nbsp;<strong class="txt_blue2">44,000</strong> 원 이하</li>
                <li>- SF 중국배송 건은 다른 주문과 묶음배송 신청불가능</li>
                <li>- 현지 세관통관 시 수취인의 ID카드 이미지 필수제공</li>
                <li>- G마켓 해외물류센터 상품검수 시 위 제한사항이 발생되는 경우, 세관통관정책에 의해&nbsp;<strong>EMS배송사</strong>로 변경되어 발송처리. (추가배송비
                  발생가능)
                </li>
              </ul>`,
              )}${__(
                'ESCROW_BASKETLAYER_TEXT_30',
                `<strong>대만배송건</strong>
              <ul class="mb10">
                <li>- 현지 세관통관 시 수취인 ID카드번호 요청</li>
              </ul>
              <p><span class="txt_bl">(단, 배송비를 제외한 KRW 100,000원 이상 결제 배송상품에 한함)</span></p>`,
              )}`}
            </HtmlParser>
          </div>
          <div className="cont">
            <h3 className="tit_bl">
              {__(
                'ESCROW_BASKETLAYER_TEXT_31',
                '중국 및 대만 세관통관을 위한 사전 ID카드정보 등록안내 (선택사항)',
              )}
            </h3>
            <p>
              -&nbsp;
              {__(
                'ESCROW_BASKETLAYER_TEXT_32',
                '원활한 세관통관을 위해, G마켓에 최초 1회 수취인의 ID카드정보를 사전등록하시면, 현지 통관 시 자동으로 세관에 해당정보를 전달하게 되며, 배송기간을 절약하실 수 있습니다.',
              )}
            </p>
          </div>
          <h3 className="tit_bl mb10">
            {__(
              'ESCROW_BASKETLAYER_TEXT_33',
              'ID카드정보 등록은 아래 페이지에서 가능합니다.',
            )}
          </h3>

          <HtmlParser>
            {__(
              'ESCROW_BASKETLAYER_TEXT_34',
              `<ul>
              <li>- 나의쇼핑정보&gt;주문내역&gt;주문상세보기 (SF이용 주문건에 노출)</li>
              <li>- 나의쇼핑정보&gt;나의설정&gt;주문/결제관련설정</li>
            </ul>`,
            )}
          </HtmlParser>
        </div>
        <CustomAnchor className="l_icon ly_close" onClick={onClickClose}>
          닫기
        </CustomAnchor>
      </div>
    </div>
  )
}

export default SFExpressNotice
