import React from 'react'
import domains from '~/data/domains'
import { __ } from '~/lib/i18n'
import logRender from '~/lib/log-render'

const CartError = (): JSX.Element => {
  logRender()

  return (
    <div id="cart_null">
      <div className="inner_cont">
        <div className="none_item">
          <strong className="msg">
            <span>
              {__(
                'ESCROW_BASKET_TEXT_229',
                '일시적인 오류로 장바구니의 상품을 호출 할 수 없습니다.',
              )}
            </span>
          </strong>
          <a href={domains.HOME} className="btn_action">
            <span className="btn_text">
              {__('ESCROW_BASKET_TEXT_108', '홈으로 이동')}
            </span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default CartError
