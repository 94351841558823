import React from 'react'
import isEqual from 'react-fast-compare'
import FormatPrice from '~/cart/gmarket/gl/common/components/FormatPrice'
import { ShippingPolicyEx } from '~/cart/modules/shipping/types'
import { currencyAmount, formatString } from '~/lib/formatter'
import { __ } from '~/lib/i18n'

const CartShippingGroupSummary = ({
  shippingPolicy: {
    isQuantityShippingFee,
    isConditionalFree,
    conditionalFreeBasisCost,
    shippingFee,
    overseaShippingInfo,
  },
}: {
  shippingPolicy: ShippingPolicyEx
}): JSX.Element => {
  return (
    <div className="shipping--info">
      <div className="delivery">
        <span className="text">{__('ESCROW_BASKET_TEXT_61', '배송비')}</span>
        {isQuantityShippingFee && (
          <span className="delivery_tip">
            {__('ESCROW_BASKET_TEXT_62', '수량별 배송비')}
          </span>
        )}
        {isConditionalFree && (
          <span className="delivery_tip">
            {formatString(
              __('ESCROW_BASKET_TEXT_63', '{0}원 이상 구매 시 무료'),
              currencyAmount(conditionalFreeBasisCost),
            )}
          </span>
        )}
        {shippingFee > 0 && (
          <FormatPrice krwAmount={shippingFee} withoutExchange={true} />
        )}
        {shippingFee === 0 && (
          <span className="delivery_price free">
            {__('ESCROW_BASKET_TEXT_21', '무료배송')}
          </span>
        )}
        {overseaShippingInfo &&
          overseaShippingInfo.shippingFee > 0 &&
          overseaShippingInfo.shippingWeight > 0 && (
            <span className="delivery-oversea">
              <span className="text">
                {formatString(
                  __('ESCROW_BASKET_TEXT_251', '해외배송비 ({0}kg)'),
                  overseaShippingInfo.shippingWeight,
                )}
              </span>
              <FormatPrice krwAmount={overseaShippingInfo.shippingFee} />
            </span>
          )}
      </div>
    </div>
  )
}

export default React.memo(CartShippingGroupSummary, isEqual)
