import React from 'react'
import ExchangeAmount from '~/lib/components/ExchangeAmount'
import { currencyAmount } from '~/lib/formatter'

const FormatPrice = ({
  krwAmount,
  rootClass,
  withoutExchange,
  showSign,
}: {
  krwAmount: number
  rootClass?: string
  withoutExchange?: boolean
  showSign?: boolean
}): JSX.Element => {
  const signComponent =
    (showSign && krwAmount !== 0) || krwAmount < 0 ? (
      <span className="text__sign">{krwAmount > 0 ? '+' : '-'}</span>
    ) : undefined
  const viewAmount = Math.abs(krwAmount)
  if (withoutExchange) {
    return (
      <span className={rootClass || 'format-price'}>
        <span className="box__format-amount">
          {signComponent}
          <span className="text__unit">￦</span>
          <strong className="text__value">{currencyAmount(viewAmount)}</strong>
        </span>
      </span>
    )
  } else {
    return (
      <span className={rootClass || 'format-price'}>
        <span className="box__format-amount">
          {signComponent}
          <span className="text__unit">￦</span>
          <strong className="text__value">{currencyAmount(viewAmount)}</strong>
        </span>
        <span className="box__format-amount box__format-amount--exchange">
          {signComponent}
          <span className="text__unit">
            <ExchangeAmount krwAmount={viewAmount} format="{0}" />
          </span>
          <strong className="text__value">
            <ExchangeAmount krwAmount={viewAmount} format="{1}" />
          </strong>
        </span>
      </span>
    )
  }
}

export default FormatPrice
