import React from 'react'
import isEqual from 'react-fast-compare'
import { useSelector } from 'react-redux'
import EmptyCart from '~/cart/gmarket/gl/common/components/EmptyCart'
import CartSellerGroupContainer from '~/cart/gmarket/gl/common/containers/CartItemList/CartSellerGroupContainer'
import CartToolbarContainer from '~/cart/gmarket/gl/common/containers/CartToolbarContainer'
import OrderSummaryContainer from '~/cart/gmarket/gl/common/containers/OrderSummary/OrderSummaryContainer'
import {
  getCurrentCartTree,
  getCurrentCartUnitList,
} from '~/cart/modules/cart/reducer'
import { RootState } from '~/cart/modules/reducers'
import { __ } from '~/lib/i18n'
import logRender from '~/lib/log-render'

const CartBodyContainer = (): JSX.Element => {
  logRender()

  const isMobile = useSelector((state: RootState) => state.view.isMobile)

  const currentCartCount = useSelector(
    (state: RootState) => getCurrentCartUnitList(state.cart).length,
  )

  const cartTree = useSelector(
    (state: RootState) => getCurrentCartTree(state.cart),
    isEqual,
  )

  if (currentCartCount === 0) {
    return <EmptyCart />
  } else {
    return (
      <div id="cart_body">
        <div className="inner_cont">
          {isMobile && <CartToolbarContainer />}
          <div id="cart_list">
            <h3 className="for_a11y">
              {__('ESCROW_BASKET_TEXT_81', '나의 장바구니 목록')}
            </h3>
            <ol className="basket_list_group">
              {cartTree.sellerGroups.map((sellerGroup) => (
                <CartSellerGroupContainer
                  key={sellerGroup.key}
                  sellerGroup={sellerGroup}
                />
              ))}
            </ol>
            <div className="fake_cursorpoint" />
          </div>
          <OrderSummaryContainer />
        </div>
      </div>
    )
  }
}

export default CartBodyContainer
