import moment from 'moment'
import React from 'react'
import isEqual from 'react-fast-compare'
import {
  CouponBoxTableItemViewData,
  CouponBoxTableType,
} from '~/cart/modules/types'
import { currencyAmount, formatString } from '~/lib/formatter'
import { __ } from '~/lib/i18n'

type CouponBoxTableItemProps = {
  tableType: CouponBoxTableType
  id: string
  viewData: CouponBoxTableItemViewData
  isSelected: boolean
  onChangeCoupon: (
    key: CouponBoxTableType,
    couponIssueNo: number,
  ) => React.ChangeEventHandler<HTMLInputElement>
  onCancelCoupon: (
    key: CouponBoxTableType,
  ) => React.MouseEventHandler<HTMLButtonElement>
}

const FoldingCouponBoxTableItem = ({
  tableType,
  id,
  viewData,
  isSelected,
  onChangeCoupon,
  onCancelCoupon,
}: CouponBoxTableItemProps): JSX.Element => {
  if (viewData.isNotUseCoupon) return <></>

  if (!viewData.isFakeCoupon) {
    return (
      <li className="coupon-unit">
        <span className="input_custom">
          <input
            type="radio"
            id={id}
            checked={isSelected}
            className="input__radio sprite__cart"
            onChange={onChangeCoupon(tableType, viewData.issueNo)}
          />
          <label htmlFor={id}>
            <span className="format-price">
              <span className="box__format-amount">
                {viewData.isDeliveryCoupon ? (
                  <strong className="text__value">
                    {__('ESCROW_BASKET_TEXT_61', '배송비')}
                  </strong>
                ) : (
                  <>
                    <span className="text__unit">￦</span>
                    <strong className="text__value">
                      {currencyAmount(viewData.price)}
                    </strong>
                  </>
                )}
              </span>
            </span>
            {viewData.description ? (
              <span className="label">
                {viewData.isSmileclubCoupon && (
                  <span className="sprite__cart box__icon smileclub">
                    <span className="for-a11y">스마일클럽</span>
                  </span>
                )}
                {viewData.isAppCoupon && (
                  <span className="sprite__cart coupon-target only-app">
                    <span className="for-a11y">
                      {__('ESCROW_M_BASKET_TEXT_156', '앱전용')}
                    </span>
                  </span>
                )}
                {viewData.isMobileCoupon && (
                  <span className="sprite__cart coupon-target only-mobile">
                    <span className="for-a11y">
                      {__('ESCROW_M_BASKET_TEXT_157', '모바일 전용')}
                    </span>
                  </span>
                )}
                {viewData.description}
              </span>
            ) : (
              <span className="label">
                {__('REWARD_COUPON_TEXT_5', 'G마켓 추가 할인')}
              </span>
            )}
          </label>
        </span>
        {!viewData.isDiscount && viewData.expiredDate && (
          <span className="text__expire-day">
            {viewData.possiblePrice && viewData.possiblePrice > 0
              ? `(${formatString(
                  __('REWARD_COUPON_TEXT_22', '{0}원 이상 구매시'),
                  currencyAmount(viewData.possiblePrice, false),
                )}, ~${moment(viewData.expiredDate).format('MM/DD')})`
              : `(~${moment(viewData.expiredDate).format('MM/DD')})`}
          </span>
        )}
        {viewData.isAppliedOnAnother && !isSelected && (
          <button className="button-used" disabled={true}>
            <i className="icon icon_used" />
            {__('REWARD_COUPON_TEXT_6', '사용중')}
          </button>
        )}
        {isSelected && viewData.issueNo !== 0 && (
          <button className="button-cancel" onClick={onCancelCoupon(tableType)}>
            <i className="icon icon_cancel" />
            {__('REWARD_COUPON_TEXT_13', '취소')}
          </button>
        )}
      </li>
    )
  } else {
    return (
      <li className="coupon-unit coupon-oneclick-smileclub">
        <span className="input_custom">
          <input
            type="radio"
            id={id}
            checked={false}
            onChange={onChangeCoupon(tableType, viewData.issueNo)}
            className="input__radio sprite__cart"
            data-montelena-acode={viewData.areaCode}
          />
          <label htmlFor={id}>
            <span className="format-price">
              <span className="box__format-amount">
                <span className="text__sign" />
                <strong className="text__value">{viewData.text}</strong>
                {viewData.unit && (
                  <span className="text__unit">{viewData.unit}</span>
                )}
              </span>
            </span>
            <span className="label">
              {viewData.description}
              <br />
              {viewData.subDescription && `(${viewData.subDescription})`}
            </span>
          </label>
        </span>
      </li>
    )
  }
}

export default React.memo(FoldingCouponBoxTableItem, isEqual)
