import React from 'react'
import isEqual from 'react-fast-compare'
import CouponBoxTableItem from '~/cart/gmarket/gl/common/components/CouponBox/CouponBoxTableItem'
import FormatPrice from '~/cart/gmarket/gl/common/components/FormatPrice'
import {
  CouponBoxTableType,
  CouponBoxTableViewData,
} from '~/cart/modules/types'
import { formatAsDom } from '~/lib/formatter'
import { __ } from '~/lib/i18n'

type CouponBoxTableProps = {
  viewData: CouponBoxTableViewData
  selectedCouponIssueNo: number
  onChangeCoupon: (
    key: CouponBoxTableType,
    couponIssueNo: number,
    isAppliedOnAnother?: boolean,
  ) => React.ChangeEventHandler<HTMLInputElement>
  onCancelCoupon: (
    key: CouponBoxTableType,
  ) => React.MouseEventHandler<HTMLButtonElement>
}

const getCouponInfoTableAlias = (type: CouponBoxTableType): string => {
  switch (type) {
    case CouponBoxTableType.Buyer:
      return __('REWARD_COUPON_TEXT_10', 'G마켓할인')
    case CouponBoxTableType.Seller:
      return __('REWARD_COUPON_TEXT_9', '판매자할인')
    case CouponBoxTableType.Super:
      return __('REWARD_COUPON_TEXT_11', '중복할인')
    case CouponBoxTableType.Bundle:
      return __('REWARD_COUPON_TEXT_24', '더 사면 할인')
    default:
      return ''
  }
}

const getNoticeText = (type: CouponBoxTableType): string => {
  switch (type) {
    case 'Seller':
      return __('REWARD_COUPON_TEXT_15', '적용된 할인이 없습니다.')
    case 'Super':
      return __('REWARD_COUPON_TEXT_3', '적용 가능한 쿠폰이 없습니다.')
    default:
      return __('REWARD_COUPON_TEXT_2', '적용 가능한 쿠폰이 없습니다.')
  }
}

const CouponBoxTable = ({
  viewData,
  selectedCouponIssueNo,
  onChangeCoupon,
  onCancelCoupon,
}: CouponBoxTableProps): JSX.Element => {
  const availableCoupons = viewData.coupons.filter(
    (coupon) => !coupon.isNotUseCoupon,
  )
  if (
    availableCoupons.length === 0 &&
    viewData.type === CouponBoxTableType.Bundle
  ) {
    return <></>
  }

  if (viewData.isIncludeCoupon) {
    return (
      <div className="coupon-group">
        <strong className="coupon-title">
          {formatAsDom(getCouponInfoTableAlias(viewData.type))}
        </strong>
        <ul className="coupon-list block__height-type1">
          {availableCoupons.length > 0 ? (
            viewData.coupons.map((coupon, idx) => {
              return (
                <CouponBoxTableItem
                  tableType={viewData.type}
                  key={idx}
                  id={coupon.issueNo.toString()}
                  viewData={coupon}
                  isSelected={selectedCouponIssueNo === coupon.issueNo}
                  onChangeCoupon={onChangeCoupon}
                  onCancelCoupon={onCancelCoupon}
                />
              )
            })
          ) : (
            <li className="coupon-unit coupon-empty">
              {getNoticeText(viewData.type)}
            </li>
          )}
        </ul>
      </div>
    )
  } else {
    return (
      <div className="coupon-group">
        <strong className="coupon-title">
          {formatAsDom(getCouponInfoTableAlias(viewData.type))}
        </strong>
        <ul className="coupon-list">
          {viewData.coupons.length > 0 ? (
            viewData.coupons.map((couponView) => {
              return (
                <li className="coupon-unit active" key={couponView.issueNo}>
                  <FormatPrice
                    withoutExchange={true}
                    krwAmount={couponView.price}
                  />
                </li>
              )
            })
          ) : (
            <li className="coupon-unit coupon-empty">
              {getNoticeText(viewData.type)}
            </li>
          )}
        </ul>
      </div>
    )
  }
}

export default React.memo(CouponBoxTable, isEqual)
