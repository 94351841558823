import React from 'react'
import CartSelector from '~/cart/gmarket/gl/common/containers/CartSelector'
import areaCodes from '~/data/areaCodes'
import { __ } from '~/lib/i18n'

const CartToolbar = ({
  onClickDeleteSelectedCartUnit,
}: {
  onClickDeleteSelectedCartUnit: React.MouseEventHandler<HTMLElement>
}): JSX.Element => {
  return (
    <div className="cart_option" id="cart_option">
      <div className="inner_cont">
        <div className="cart_option_area">
          <ul className="cart_option_box">
            <li className="select_all">
              <CartSelector
                data-montelena-acode={areaCodes.SELECT_ALL}
                id="item_all_select"
                title={__('ESCROW_BASKET_TEXT_67', '장바구니 상품 전체')}
                showingTitle={true}
              >
                {__('ESCROW_BASKET_TEXT_67', '전체선택')}
              </CartSelector>
            </li>
            <li>
              <button
                data-montelena-acode={areaCodes.DELETE_SELECT}
                className="btn_del"
                onClick={onClickDeleteSelectedCartUnit}
              >
                <span>{__('ESCROW_BASKET_TEXT_7', '선택삭제')}</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default CartToolbar
