import React from 'react'
import isEqual from 'react-fast-compare'
import { shallowEqual, useSelector } from 'react-redux'
import CartShippingGroupSummary from '~/cart/gmarket/gl/common/components/CartItemList/CartShippingGroupSummary'
import CartItemGroupContainer from '~/cart/gmarket/gl/common/containers/CartItemList/CartItemGroupContainer'
import { ShippingGroupView } from '~/cart/modules/cart/types'
import { getShippingPolicyEx, RootState } from '~/cart/modules/reducers'
import logRender from '~/lib/log-render'

const CartShippingGroupContainer = ({
  shippingGroup,
}: {
  shippingGroup: ShippingGroupView
}): JSX.Element => {
  logRender()

  const shippingPolicy = useSelector(
    (state: RootState) => getShippingPolicyEx(state, shippingGroup.key),
    shallowEqual,
  )

  return (
    <div
      className={`shipping--${
        shippingGroup.cartUnitIdList.length > 1 ? '' : 'no--'
      }group`}
    >
      <ul className="order--list">
        {shippingGroup.itemGroups.map((itemGroup) => (
          <CartItemGroupContainer key={itemGroup.key} itemGroup={itemGroup} />
        ))}
      </ul>
      {shippingPolicy && shippingPolicy.isDisplayShippingFee && (
        <CartShippingGroupSummary shippingPolicy={shippingPolicy} />
      )}
    </div>
  )
}
export default React.memo(CartShippingGroupContainer, isEqual)
