import { createStandardAction } from 'typesafe-actions'
import { CouponDownloadMode, CouponDownloadState } from '~/cart/modules/types'
import {
  AvailableUnitCoupon,
  DownloadableUnitCoupon,
  DownloadableUnitCouponFailedLifeCycle,
  DownloadableUnitCouponFailedReason,
  IssuedUnitCouponMap,
  RecommendedUnitCouponMap,
  UnitExtraDiscountCandidate,
} from './types'

export const UPDATE_AVAILABLE_UNIT_COUPON =
  'unitCoupon/UPDATE_AVAILABLE_UNIT_COUPON'
export const UPDATE_AVAILABLE_UNIT_COUPON_MULTI =
  'unitCoupon/UPDATE_AVAILABLE_UNIT_COUPON_MULTI'
export const APPLY_UNIT_COUPONS = 'unitCoupon/APPLY_UNIT_COUPONS'
export const CLEAR_ALL_UNIT_COUPONS = 'unitCoupon/CLEAR_ALL_UNIT_COUPONS'
export const SET_TOTAL_UNIT_COUPON_COUNT =
  'unitCoupon/SET_TOTAL_UNIT_COUPON_COUNT'
export const SET_UNIT_COUPON_BOX_OPENED =
  'unitCoupon/SET_UNIT_COUPON_BOX_OPENED'
export const SET_UNIT_COUPON_LOADED = 'unitCoupon/SET_UNIT_COUPON_LOADED'
export const SET_COUPON_BOX_OPENED_CART_UNIT_ID =
  'view/SET_COUPON_BOX_OPENED_CART_UNIT_ID'
export const UPDATE_RECOMMENDED_UNIT_COUPONS =
  'unitCoupon/UPDATE_RECOMMENDED_UNIT_COUPONS'
export const UPDATE_ISSUED_UNIT_COUPONS =
  'unitCoupon/UPDATE_ISSUED_UNIT_COUPONS'
export const CLEAR_RECOMMENDED_UNIT_COUPONS =
  'unitCoupon/CLEAR_RECOMMENDED_UNIT_COUPONS'
export const SET_DOWNLOADED_UNIT_COUPON_ISSUE_NO =
  'unitCoupon/SET_DOWNLOADED_UNIT_COUPON_ISSUE_NO'
export const SET_UNIT_COUPON_DOWNLOADED =
  'unitCoupon/SET_UNIT_COUPON_DOWNLOADED'
export const UPDATE_DOWNLOADABLE_UNIT_COUPONS =
  'unitCoupon/UPDATE_DOWNLOADABLE_UNIT_COUPONS'
export const SET_DOWNLOADABLE_UNIT_COUPON_STATE =
  'unitCoupon/SET_DOWNLOADABLE_UNIT_COUPON_STATE'
export const UPDATE_UNAPPLY_UNIT_COUPON =
  'unitCoupon/UPDATE_UNAPPLY_UNIT_COUPON'
export const UPDATE_PAYMENT_UNIT_COUPON =
  'unitCoupon/UPDATE_PAYMENT_UNIT_COUPON'

export const SET_EXTRA_DISCOUNT_CANDIDATES =
  'unitCoupon/SET_EXTRA_DISCOUNT_CANDIDATES'

export const updateAvailableUnitCoupon = createStandardAction(
  UPDATE_AVAILABLE_UNIT_COUPON,
)<AvailableUnitCoupon>()

export const updateAvailableUnitCouponMulti = createStandardAction(
  UPDATE_AVAILABLE_UNIT_COUPON_MULTI,
)<AvailableUnitCoupon[]>()

export const applyUnitCoupons = createStandardAction(APPLY_UNIT_COUPONS)<
  {
    couponIssueNo: number
    cartUnitId?: number
  }[]
>()

export const clearAllUnitCoupons = createStandardAction(
  CLEAR_ALL_UNIT_COUPONS,
)<{
  cartUnitId: number
}>()

export const setTotalUnitCouponCount = createStandardAction(
  SET_TOTAL_UNIT_COUPON_COUNT,
)<number>()

export const setUnitCouponBoxOpened = createStandardAction(
  SET_UNIT_COUPON_BOX_OPENED,
)<number | undefined>()

export const setUnitCouponLoaded = createStandardAction(
  SET_UNIT_COUPON_LOADED,
)<{
  cartUnitId: number
  isLoaded: boolean
}>()

export const setCouponBoxOpenedCartUnitId = createStandardAction(
  SET_COUPON_BOX_OPENED_CART_UNIT_ID,
)<number | undefined>()

export const updateRecommendedUnitCoupons = createStandardAction(
  UPDATE_RECOMMENDED_UNIT_COUPONS,
)<RecommendedUnitCouponMap>()

export const updateIssuedUnitCoupons = createStandardAction(
  UPDATE_ISSUED_UNIT_COUPONS,
)<IssuedUnitCouponMap>()

export const clearRecommendedUnitCoupons = createStandardAction(
  CLEAR_RECOMMENDED_UNIT_COUPONS,
)()

export const setDownloadedUnitCouponIssueNo = createStandardAction(
  SET_DOWNLOADED_UNIT_COUPON_ISSUE_NO,
)<{
  cartUnitId: number
  couponIssueNo: number
  couponPolicyNo: number
  couponDownloadMode: CouponDownloadMode
}>()

export const setUnitCouponDownloaded = createStandardAction(
  SET_UNIT_COUPON_DOWNLOADED,
)<{
  cartUnitId: number
  couponPolicyNo: number
  isDownloading: boolean
}>()

export const updateDownloadableUnitCoupons = createStandardAction(
  UPDATE_DOWNLOADABLE_UNIT_COUPONS,
)<DownloadableUnitCoupon[]>()

export const setDownloadableUnitCouponState = createStandardAction(
  SET_DOWNLOADABLE_UNIT_COUPON_STATE,
)<{
  couponPolicyNo: number
  downloadState: CouponDownloadState
  failedReason?: DownloadableUnitCouponFailedReason
  failedLifeCycle?: DownloadableUnitCouponFailedLifeCycle
}>()

export const updateUnapplyUnitCoupon = createStandardAction(
  UPDATE_UNAPPLY_UNIT_COUPON,
)<{ cartUnitId: number; couponIssueNo: number }>()

export const updatePaymentUnitCoupon = createStandardAction(
  UPDATE_PAYMENT_UNIT_COUPON,
)<{ cartUnitId: number; couponIssueNo: number }>()

export const setExtraDiscountCandidates = createStandardAction(
  SET_EXTRA_DISCOUNT_CANDIDATES,
)<UnitExtraDiscountCandidate>()
