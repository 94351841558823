import React from 'react'
import CartUnitAddition from '~/cart/gmarket/gl/common/components/CartItemList/CartUnitAddition'
import CartUnitOption from '~/cart/gmarket/gl/common/components/CartItemList/CartUnitOption'
import FormatPrice from '~/cart/gmarket/gl/common/components/FormatPrice'
import CartUnitQtyControlContainer from '~/cart/gmarket/gl/common/containers/CartItemList/CartUnitQtyControlContainer'
import { CartUnit, CartUnitPriceType } from '~/cart/modules/cart/types'
import { ShippingPolicy } from '~/cart/modules/shipping/types'
import { BuyUnavailableInfo } from '~/cart/modules/types'
import areaCodes from '~/data/areaCodes'
import tenantConstants from '~/data/checkout-constants'
import { ElementIds } from '~/data/consts'
import domains from '~/data/domains'
import CustomAnchor from '~/lib/components/CustomAnchor'
import HtmlParser from '~/lib/components/HtmlParser'
import { formatAsDom, formatString } from '~/lib/formatter'
import { __ } from '~/lib/i18n'
import { DeliveryCouponErrorType } from '~/types/enums'

const CartUnitComponent = ({
  cartUnit,
  cartUnitPrice,
  shippingPolicy,
  isMobile,
  isShippingUnavailable,
  deliveryCouponErrorType,
  isCouponApplied,
  buyUnavailableInfo,
  onClickRemoveButton,
  onClickOpenCouponBox,
  isFirstDuplicatesCartUnit,
  isCurrentShippingUnavailable,
}: {
  cartUnit: CartUnit
  idx: number
  cartUnitPrice: CartUnitPriceType
  shippingPolicy?: ShippingPolicy & {
    isSpecialShipping: boolean
    shippingMethodText?: string
  }
  isMobile: boolean
  isShippingUnavailable: boolean
  deliveryCouponErrorType?: DeliveryCouponErrorType
  isCouponApplied: boolean
  buyUnavailableInfo?: BuyUnavailableInfo
  onClickRemoveButton: React.MouseEventHandler<HTMLElement>
  onClickOpenCouponBox: React.MouseEventHandler<HTMLElement>
  isFirstDuplicatesCartUnit: boolean
  isCurrentShippingUnavailable: boolean
}): JSX.Element => {
  return (
    <dd
      className="unit--item_desc"
      id={ElementIds.CartUnit + cartUnit.cartUnitId}
    >
      <div className="section item_title">
        <CustomAnchor
          href={formatString(domains.VIP, cartUnit.item.itemNo)}
          data-montelena-acode={areaCodes.ITEM_NAME}
          data-montelena-goodscode={cartUnit.item.itemNo}
        >
          <b className="for_a11y">{__('ESCROW_BASKET_TEXT_86', '상품명')}: </b>
          <span className="item_name">{cartUnit.item.itemName}</span>
        </CustomAnchor>
      </div>

      {cartUnit.item.options && cartUnit.item.options.length > 0 && (
        <div className="section item_option">
          <strong className="for_a11y">
            {__('ESCROW_BASKET_TEXT_87', '옵션선택 정보')}
          </strong>
          <ul>
            {cartUnit.item.options.map((option) => {
              return (
                <CartUnitOption
                  key={`cart_unit_option_${cartUnit.cartUnitId}_${option.optionNo}`}
                  option={option}
                />
              )
            })}
          </ul>
        </div>
      )}
      {cartUnit.item.additions && cartUnit.item.additions.length > 0 && (
        <div className="section item_add">
          <strong className="for_a11y">
            {__('ESCROW_BASKET_TEXT_88', '추가구성 정보')}
          </strong>
          <ul>
            {cartUnit.item.additions.map((addition) => {
              return (
                <CartUnitAddition
                  key={`cart_unit_addition_${cartUnit.cartUnitId}_${addition.additionNo}`}
                  addition={addition}
                />
              )
            })}
          </ul>
        </div>
      )}
      {!buyUnavailableInfo &&
        isShippingUnavailable &&
        tenantConstants.IsOverseaShippingAvailable && (
          <div className="section item_notice">
            <strong>
              {__(
                'ESCROW_BASKET_TEXT_18',
                '선택하신 국가로 배송이 불가한 상품입니다.',
              )}
            </strong>
          </div>
        )}
      {deliveryCouponErrorType === 'FREE_SHIPPING' && (
        <div className="section item_notice">
          <strong>
            {__(
              'ESCROW_BASKET_TEXT_243',
              '결제할 묶음배송비가 0원인 경우 무료배송쿠폰은 사용할 수 없습니다.',
            )}
          </strong>
        </div>
      )}
      {deliveryCouponErrorType === 'SPECIAL_SHIPPING' && (
        <div className="section item_notice">
          <strong>
            {__(
              'ESCROW_BASKET_TEXT_243',
              '착불/퀵서비스/방문수령/직접배송 상품인 경우 무료배송쿠폰은 사용할 수 없습니다.',
            )}
          </strong>
        </div>
      )}
      {!buyUnavailableInfo &&
        isShippingUnavailable &&
        !tenantConstants.IsOverseaShippingAvailable && (
          <div className="section item_notice" style={{ marginBottom: '10px' }}>
            <strong
              style={{
                position: 'relative',
                color: '#f83030',
                height: '30px',
                fontSize: '12px',
                lineHeight: '13px',
                margin: '4px 0',
                fontWeight: 'normal',
              }}
            >
              {__('ESCROW_BASKET_TEXT_18', '국내배송불가 상품입니다.')}
            </strong>
          </div>
        )}
      {buyUnavailableInfo && (
        <div className="section item_soldout">
          <strong>{formatAsDom(buyUnavailableInfo.defaultMessage)}</strong>
          <button
            type="button"
            className="btn_del"
            data-montelena-acode={areaCodes.DELETE_UNAVAILABLE_CARTUNIT}
            onClick={onClickRemoveButton}
          >
            {__('ESCROW_BASKET_TEXT_7', '삭제')}
          </button>
        </div>
      )}
      {!buyUnavailableInfo && (
        <CartUnitQtyControlContainer cartUnit={cartUnit} />
      )}

      {!buyUnavailableInfo && (
        <div className="section item_coupon">
          {isMobile ? (
            <button
              id={ElementIds.CouponButton + cartUnit.cartUnitId}
              className={`button__coupon sprite__cart--after ${
                isCouponApplied
                  ? 'button__active'
                  : !cartUnit.isCouponUsable
                  ? 'button__disabled'
                  : ''
              }`}
              onClick={onClickOpenCouponBox}
              data-montelena-acode={areaCodes.COUPON_BOX}
            >
              {__('ESCROW_BASKET_TEXT_93', '쿠폰적용')}
            </button>
          ) : (
            <button
              id={ElementIds.CouponButton + cartUnit.cartUnitId}
              className={`btn_coupoon sp_cart ${
                isCouponApplied
                  ? 'button__active'
                  : !cartUnit.isCouponUsable
                  ? 'button__disabled'
                  : ''
              }`}
              onClick={onClickOpenCouponBox}
              data-montelena-acode={areaCodes.COUPON_BOX}
            >
              <span className="for_a11y">
                {__('ESCROW_BASKET_TEXT_93', '쿠폰적용 레이어팝업 열기')}
              </span>
            </button>
          )}
        </div>
      )}
      {!buyUnavailableInfo && (
        <div className="section item_price">
          <b className="for_a11y">
            {__('ESCROW_BASKET_TEXT_69', '상품 금액')} :{' '}
          </b>
          <FormatPrice krwAmount={cartUnitPrice.cartUnitPrice} />
          {shippingPolicy && (
            <span
              className={`price_desc ${
                shippingPolicy.shippingMethodType === 'QuickService'
                  ? 'quick_delivery'
                  : ''
              }`}
            >
              {shippingPolicy.shippingMethodText}
            </span>
          )}
        </div>
      )}
      <div className="section item_del">
        <button
          className="btn_del sprite__cart"
          onClick={onClickRemoveButton}
          data-montelena-acode={
            !buyUnavailableInfo
              ? areaCodes.DELETE_CARTUNIT
              : areaCodes.DELETE_UNAVAILABLE_CARTUNIT
          }
        >
          <i className="icon sprite__cart btn_cart_item_del">
            <span className="for_a11y">
              {__('ESCROW_BASKET_TEXT_95', '상품 삭제')}
            </span>
          </i>
        </button>
        {isFirstDuplicatesCartUnit && !isCurrentShippingUnavailable && (
          <HtmlParser rootTag="div" className="box__tooltip oversea-delivery">
            {`${__('ESCROW_BASKET_TEXT_252', `<strong>중국배송건</strong>`)}`}
          </HtmlParser>
        )}
      </div>
    </dd>
  )
}

export default CartUnitComponent
