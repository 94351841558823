import React from 'react'
import isEqual from 'react-fast-compare'
import { shallowEqual, useSelector } from 'react-redux'
import CartSellerGroupFooter from '~/cart/gmarket/gl/common/components/CartItemList/CartSellerGroupFooter'
import CartSellerGroupHeader from '~/cart/gmarket/gl/common/components/CartItemList/CartSellerGroupHeader'
import CartShippingGroupContainer from '~/cart/gmarket/gl/common/containers/CartItemList/CartShippingGroupContainer'
import {
  getEncodedSellerKey,
  getIsAllBuyUnavailable,
} from '~/cart/modules/cart/reducer'
import { SellerGroupView } from '~/cart/modules/cart/types'
import {
  getSelectedSellerGroupSummary,
  RootState,
} from '~/cart/modules/reducers'
import logRender from '~/lib/log-render'

const CartSellerGroupContainer = ({
  sellerGroup,
}: {
  sellerGroup: SellerGroupView
}): JSX.Element => {
  logRender()

  const isMobile = useSelector((state: RootState) => state.view.isMobile)

  const isAllBuyUnavailable = useSelector((state: RootState) =>
    getIsAllBuyUnavailable(state.cart, sellerGroup.cartUnitIdList),
  )

  const sellerGroupSummary = useSelector(
    (state: RootState) => getSelectedSellerGroupSummary(state, sellerGroup),
    shallowEqual,
  )

  const encodedSellerKey = useSelector((state: RootState) =>
    getEncodedSellerKey(state.cart, sellerGroup.key),
  )

  // PC는 shopLink 가 별도
  const shopLink = isMobile
    ? sellerGroup.shopLink
    : encodedSellerKey || sellerGroup.shopLink

  const isShowSummary = !isAllBuyUnavailable

  return (
    <li className="cart--basket type_minishop">
      <CartSellerGroupHeader sellerGroup={sellerGroup} shopLink={shopLink} />
      <div className="cart--basket_body">
        {sellerGroup.shippingGroups.map((shippingGroup) => (
          <CartShippingGroupContainer
            key={shippingGroup.key}
            shippingGroup={shippingGroup}
          />
        ))}
      </div>
      {isShowSummary && (
        <CartSellerGroupFooter
          sellerGroup={sellerGroup}
          sellerGroupSummary={sellerGroupSummary}
          isMobile={isMobile}
        />
      )}
    </li>
  )
}

export default React.memo(CartSellerGroupContainer, isEqual)
